// Check if the date is today's date for the "heute" condition
export const isToday = (date) => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

const makePrettyDate = (datetimeString, show = '') => {
  const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]

  // Parse the datetime string to a Date object assuming it is in UTC
  const utcDate = new Date(datetimeString)

  // Format the date and time in Berlin timezone
  const berlinTimeOptions = {
    timeZone: 'Europe/Berlin',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  const berlinDateTime = new Intl.DateTimeFormat(
    'de-DE',
    berlinTimeOptions,
  ).formatToParts(utcDate)

  // Extract date and time parts
  let day, month, year, hours, minutes
  berlinDateTime.forEach(({ type, value }) => {
    if (type === 'day') day = value
    if (type === 'month') month = value
    if (type === 'year') year = value
    if (type === 'hour') hours = value
    if (type === 'minute') minutes = value
  })

  const formattedTime = `${hours}:${minutes}`

  // Construct the date object from parts
  const dateObj = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`)

  if (show === 'DateTime') {
    if (isToday(dateObj)) {
      return `Heute ${formattedTime}`
    }

    return `${day} ${months[parseInt(month, 10) - 1]} ${year} ${formattedTime}`
  }

  // Default to only showing the date
  return `${day} ${months[parseInt(month, 10) - 1]} ${year}`
}

export default makePrettyDate

