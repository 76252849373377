import React from 'react'

const BlackPin = () => {
  return (
    <svg
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8685 5.6878L8.94433 8.62178C9.19371 9.31467 9.29707 10.4751 8.22301 11.903C8.14789 12.0034 8.05198 12.0864 7.94183 12.1464C7.83169 12.2063 7.70991 12.2418 7.5848 12.2503C7.56402 12.2503 7.54379 12.2503 7.52301 12.2503C7.40803 12.2503 7.29417 12.2277 7.18795 12.1837C7.08173 12.1397 6.98522 12.0751 6.90394 11.9938L4.26472 9.35186L1.9334 11.6848C1.8513 11.7669 1.73996 11.813 1.62386 11.813C1.50777 11.813 1.39643 11.7669 1.31433 11.6848C1.23224 11.6027 1.18612 11.4914 1.18612 11.3753C1.18612 11.2592 1.23224 11.1479 1.31433 11.0658L3.6473 8.73444L1.0059 6.09303C0.919074 6.0061 0.851532 5.90186 0.807667 5.7871C0.763802 5.67233 0.744598 5.54961 0.751305 5.42694C0.758011 5.30426 0.790478 5.18437 0.846592 5.07507C0.902706 4.96577 0.981209 4.86951 1.07699 4.79256C2.46715 3.67092 3.79769 3.89131 4.38011 4.07397L7.31136 1.13233C7.39262 1.05106 7.48909 0.986586 7.59526 0.942601C7.70143 0.898616 7.81523 0.875977 7.93015 0.875977C8.04508 0.875977 8.15888 0.898616 8.26505 0.942601C8.37122 0.986586 8.46769 1.05106 8.54894 1.13233L11.8679 4.45077C12.032 4.61477 12.1242 4.8372 12.1243 5.06917C12.1244 5.30113 12.0324 5.52365 11.8685 5.6878Z"
        fill="#010202"
      />
    </svg>
  )
}

export default BlackPin
