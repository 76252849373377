import { generateClient } from 'aws-amplify/api';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

/*
This function does basically nothing more than updating a record of type ToDo
in the main table.

However this excercise is not a trivial case we could cover with updateTableMain, since
in the case of ToDoList, the information to be changed is included in the SK and
this key cannot be changed. Hence we have to insert a new record first and once this
is successful, delete the old record.

*/
async function updateToDoListRecord(originalPK, originalSK, newSK, newStatus) {
    const currentTimestamp = new Date().toISOString();
    const client = generateClient(); 

    console.log('PPPPPPPPPPP originalPK, originalSK, newSK, newStatus', 
    originalPK, originalSK, newSK, newStatus);
    const getParams = {
        PK: originalPK,
        SK: originalSK,
    };

    try {
        // Get the current item
        console.log('PPPPPPPPPPP getParams', getParams);
        const currentDataResponse = await client.graphql({
            query: queries.getMain,
            variables: getParams
        });
        const currentItemData = currentDataResponse.data.getMain;
        console.log('PPPPPPPPPPP currentItemData', currentItemData);

        // Now, prepare the new item data
        const newItemData = {
            PK: currentItemData.PK,
            SK: newSK, // Update the SK with the new value
            ClinicAccess: currentItemData.ClinicAccess,
            Intervention: currentItemData.Intervention,
            PatientName: currentItemData.PatientName,
            DateTime: currentItemData.DateTime,
            Source: currentItemData.Source,
            Action: currentItemData.Action,
            Status: newStatus
        };

        // Perform the create mutation
        const createParams = {
            input: newItemData,
        };
        console.log('PPPPPPPPPPP ToDoList createParams', createParams);
        await client.graphql({
            query: mutations.createMain,
            variables: createParams
        });
        console.log('PPPPPPPPPPP ToDoList Item with new SK created successfully');


        const deleteParams = {
            input: {
                PK: originalPK,
                SK: originalSK,
                ClinicAccess: originalPK,
            }
        };

        // Perform the delete mutation
        console.log('PPPPPPPPPPP ToDoList deleteParams', deleteParams);
        await client.graphql({
            query: mutations.deleteMain,
            variables: deleteParams
        });
        console.log('PPPPPPPPPPP ToDoList Item with old SK deleted successfully');

    } catch (error) {
        console.error('PPPPPPPPPPP ToDoListError updating SK:', error);
        throw error;
    }

}

export default updateToDoListRecord;

