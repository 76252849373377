import { generateClient } from 'aws-amplify/api';
import getCurrentMonthAndQuarter from "./utils/getCurrentMonthAndQuarter";
import extractElementNumber from "./utils/extractElementNumber";
import { createMain as createMainMutation } from "./graphql/mutations";
import updateClearingListRecord from "./updateClearingListRecord";
import { updateMainTable } from './updateMainTable';
import { fetchAppointmentsAndChats } from './components/ClearingEditDataGet2'; 

/* 
This function takes care of the database transactions when a position is confirmed
for a clearing posting.
Background: 
  This posting is launched when the user has confirmed in the ClearingEdit 
  Dialogbox that the displayed case shall go into clearing (Abrechnung abschließen).
  Then each of the items checked for clearing must be posted here.

  Clearing posting can happen on two levels:
    Appointment or
    Chat
  Hence the logic below distinguishes these cases. They are recognized by the text 
  in the field ClearingStatus.

Beside the central task to insert a new record for clearing into the database, the
function takes care also about the content changes in the processed appointment / chat
records in respect to the ClearingStatus and ClearingID change. The unique id of the 
posting for the new inserted clearing record is written as a reference into the 
ClearingID field of the relevant record. These changes are realized with 
updateTableMain.

This function has an error throwing mechanism installed. In case that any of the 
posting fails, it throws the error upwards and it will be displayed to the user.

*/


export async function postClearing(patientMasterData, activeCase) {
  console.log(`POPOPOPOPOPO postClearing start pk, 
    activeCase: `, patientMasterData, activeCase);
    var caseRecords = [];
    try {
    
    const getCaseRecords = async () => {
      console.log(`POPOPOPOPOPO pk, activeCase for fetchAppointmentsAndChats: `, patientMasterData.PK, activeCase);
      return await fetchAppointmentsAndChats(patientMasterData.PK, activeCase);
    };

    caseRecords = await getCaseRecords();
    console.log(`POPOPOPOPOPO caseRecords: `, caseRecords);
  } catch (error) {
    console.error(
      "POPOPOPOPOPO  in createClearing / Error fetchAppointmentsAndChats:",
      error
    );
    throw error;
  }


  let count = 0;
  for (const record of caseRecords) {
    let uniqueIdentifier = Date.now().toString();
    count++
    uniqueIdentifier += count;

    switch (record.ClearingStatus) {
      case "to-be-cleared for appointment":
        console.log(`POPOPOPOPOPO we post clearing for appointment ${record.SK} with ID ${uniqueIdentifier}`);
        try {
          const myClearingID = await createClearing(activeCase, record, uniqueIdentifier);
          console.log(`POPOPOPOPOPO clearing created with ID: ${myClearingID}`);

          await updateMainTable(
            record.PK,
            record.SK,
            "ClearingID",
            myClearingID
          );
          await updateMainTable(
            record.PK,
            record.SK,
            "ClearingStatus",
            "cleared for appointment"
          );
          console.log('POPOPOPOPOPO in postClearing for appointment record/ Main table updated');

        } catch (error) {
          // This block will catch any errors thrown by createClearing
          console.error("POPOPOPOPOPO Error in postClearing:", error);
          throw error;
        }
        break;

      case "to-be-cleared for chat":
        console.log(`POPOPOPOPOPO we post clearing for chat ${record.SK} with ID ${uniqueIdentifier}`);
        try {
          const myClearingID = await createClearing(activeCase, record, uniqueIdentifier);
          console.log(`POPOPOPOPOPO clearing created with ID: ${myClearingID}`);

          await updateMainTable(
            record.PK,
            record.SK,
            "ClearingID",
            myClearingID
          );
          await updateMainTable(
            record.PK,
            record.SK,
            "ClearingStatus",
            "cleared for chat"
          );
          console.log('POPOPOPOPOPO in postClearing for chat record/ Main table updated');

        } catch (error) {
          // This block will catch any errors thrown by createClearing
          console.error("POPOPOPOPOPO Error in postClearing:", error);
          throw error;
        }
        break;

      default:
        // No action required for default case
        break;
    }
  }

  /**
  When this function is called, the user has decided to close the case in respect to clearing.
  Hence we have to upate the relevant ClearingList record from "gestarted" to "abgeschlossen". 
  (This status value is even part of the SK)
  */

  
  const originalPK = patientMasterData.ClinicAccess;
  const originalSK = `TYP#ClearingList#STS#gestartet#CASE#${activeCase}#PAT#${patientMasterData.PK}##`
  const newSK = `TYP#ClearingList#STS#abgeschlossen#CASE#${activeCase}#PAT#${patientMasterData.PK}##`
  const newStatus = "abgeschlossen"

  try {
    await updateClearingListRecord(originalPK, originalSK, newSK, newStatus);
    console.log('POPOPOPOPOPO ClearingList record updated successfully with status:', newStatus);
  } catch (error) {
    console.error('POPOPOPOPOPO Error for ClearingList record update error:', error);
    const enhancedErrorMessage = `Error for ClearingList record update: ${error?.errors?.[0]?.message}. The ClearingList record, intended for update has likely a different ClearingStatus than expected.`;
    console.error(enhancedErrorMessage);
    throw new Error(enhancedErrorMessage);
  }

  /*
  Finally, we can set the ClearingStatus of the case to "posted" and the Status to "closed" 
  */

  const myCasePK = caseRecords[0].PK
  const myCaseSK = `CASE#${activeCase}##`

    try {

      await updateMainTable(
        myCasePK,
        myCaseSK,
        "ClearingStatus",
        "posted"
      );
      await updateMainTable(
        myCasePK,
        myCaseSK,
        "Status",
        "closed"
      );
    } catch (error) {
      console.error('POPOPOPOPOPO Error for case record update error:', error);
      const enhancedErrorMessage = `Error for Case record update: ${error?.errors?.[0]?.message}. `;
      console.error(enhancedErrorMessage);
      throw new Error(enhancedErrorMessage);
    }
  
}


async function createClearing(activeCase, record, uid) {
  console.log(`POPOPOPOPOPO in createClearing with ID ${uid} `, record);
  const client = generateClient();
  const appNumber = extractElementNumber("APP", record.SK)
  const chatNumber = extractElementNumber("CHAT", record.SK)
  let clearingSK = chatNumber
    ? `CLR#${uid}#PAT#${record.PK}#CASE#${activeCase}#APP#${appNumber}#CHAT${chatNumber}##`
    : `CLR#${uid}#PAT#${record.PK}#CASE#${activeCase}#APP#${appNumber}##`;

  const { formattedMonth, formattedQuarter } = getCurrentMonthAndQuarter();

  const clearingData = {
    PK: record.ClinicAccess,
    SK: clearingSK,
    ClinicAccess: record.ClinicAccess,
    Charge: "not applicable",
    Code: "not applicable",
    IncrementFactor: "not applicable",
    Status: "pending",
    Month: formattedMonth,
    Quarter: formattedQuarter,
  };

  console.log(`POPOPOPOPOPO in createClearing clearingData: `, clearingData);

  try {
    const response = await client.graphql({
      query: createMainMutation,
      variables: { input: clearingData },
    });
    console.log(
      "POPOPOPOPOPO  in createClearing / clearing transaction added:",
      response.data.createMain
    );
    return uid;
  } catch (error) {
    console.error(
      "POPOPOPOPOPO  in createClearing / Error adding clearing transaction message:",
      error
    );
    throw error;
  }
}
