// ChatInput.js
import React from "react";
import { Box, TextField, IconButton, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";

/* 
Purose of this component is to display the input line for new chat messages.

*/

const ChatInput = (props) => {
  const handleInputChange = (event) => {
    props.setChatMessage(event.target.value);
  };

  const handleSendClick = () => {
    if (props.chatMessage.trim()) {
      props.onSendChatMessage(props.chatMessage);
      props.setChatMessage(""); // Clear the input field after sending the message
    }
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <TextField
        fullWidth
        placeholder="Nachricht hier eingeben..."
        value={props.chatMessage}
        onChange={handleInputChange}
        variant="outlined"
        onKeyPress={(e) => e.key === "Enter" && handleSendClick()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.chatMessage && (
                <IconButton onClick={handleSendClick} edge="end">
                  <SendIcon />
                </IconButton>
              )}
              <IconButton edge="end">
                <AttachFileIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ChatInput;
