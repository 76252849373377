import { generateClient } from 'aws-amplify/api';
import { listMains as listMainsQuery } from "./graphql/queries";

const checkExistsInMainTable = async (pk, sk) => {
    console.log(`SSSSSSSSSS checkExistsInMainTable with pk: ${pk}, sk: ${sk}`);

    const client = generateClient();
    let response;

    const variables = { pk, sk }; // Define the variables for your query
    variables.filter = { PK: { eq: pk }, SK: { eq: sk } } ;

    console.log("SSSSSSSSSS checkExistsInMainTable variables:", variables);
    try {
        response = await client.graphql({
            query: listMainsQuery,
            variables: variables,
        });
        
    } catch (error) {
        console.error('SSSSSSSSSSSSS in checkExistsInMainTable / Error:', error);
        throw error;
    }


    // Check the response to determine if the record exists
    // This part depends on the structure of your response
    console.log("SSSSSSSSSS checkExistsInMainTable response:", response);
    if (response && response.data && response.data.listMains && response.data.listMains.items.length > 0) {
      console.log("SSSSSSSSSS checkExistsInMainTable response:", response); 
      return true;
    } else {
        return false;
    }
}

export default checkExistsInMainTable;
