/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVersionCounter2 = /* GraphQL */ `
  mutation CreateVersionCounter2(
    $input: CreateVersionCounter2Input!
    $condition: ModelVersionCounter2ConditionInput
  ) {
    createVersionCounter2(input: $input, condition: $condition) {
      PK
      LatestCounter
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVersionCounter2 = /* GraphQL */ `
  mutation UpdateVersionCounter2(
    $input: UpdateVersionCounter2Input!
    $condition: ModelVersionCounter2ConditionInput
  ) {
    updateVersionCounter2(input: $input, condition: $condition) {
      PK
      LatestCounter
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVersionCounter2 = /* GraphQL */ `
  mutation DeleteVersionCounter2(
    $input: DeleteVersionCounter2Input!
    $condition: ModelVersionCounter2ConditionInput
  ) {
    deleteVersionCounter2(input: $input, condition: $condition) {
      PK
      LatestCounter
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppLaunchCodes = /* GraphQL */ `
  mutation CreateAppLaunchCodes(
    $input: CreateAppLaunchCodesInput!
    $condition: ModelAppLaunchCodesConditionInput
  ) {
    createAppLaunchCodes(input: $input, condition: $condition) {
      PK
      SK
      PatientID
      PatientName
      Salutation
      ValidUntil
      Utilized
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppLaunchCodes = /* GraphQL */ `
  mutation UpdateAppLaunchCodes(
    $input: UpdateAppLaunchCodesInput!
    $condition: ModelAppLaunchCodesConditionInput
  ) {
    updateAppLaunchCodes(input: $input, condition: $condition) {
      PK
      SK
      PatientID
      PatientName
      Salutation
      ValidUntil
      Utilized
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppLaunchCodes = /* GraphQL */ `
  mutation DeleteAppLaunchCodes(
    $input: DeleteAppLaunchCodesInput!
    $condition: ModelAppLaunchCodesConditionInput
  ) {
    deleteAppLaunchCodes(input: $input, condition: $condition) {
      PK
      SK
      PatientID
      PatientName
      Salutation
      ValidUntil
      Utilized
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDentiProControl = /* GraphQL */ `
  mutation CreateDentiProControl(
    $input: CreateDentiProControlInput!
    $condition: ModelDentiProControlConditionInput
  ) {
    createDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDentiProControl = /* GraphQL */ `
  mutation UpdateDentiProControl(
    $input: UpdateDentiProControlInput!
    $condition: ModelDentiProControlConditionInput
  ) {
    updateDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDentiProControl = /* GraphQL */ `
  mutation DeleteDentiProControl(
    $input: DeleteDentiProControlInput!
    $condition: ModelDentiProControlConditionInput
  ) {
    deleteDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHistDentiProControl = /* GraphQL */ `
  mutation CreateHistDentiProControl(
    $input: CreateHistDentiProControlInput!
    $condition: ModelHistDentiProControlConditionInput
  ) {
    createHistDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHistDentiProControl = /* GraphQL */ `
  mutation UpdateHistDentiProControl(
    $input: UpdateHistDentiProControlInput!
    $condition: ModelHistDentiProControlConditionInput
  ) {
    updateHistDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHistDentiProControl = /* GraphQL */ `
  mutation DeleteHistDentiProControl(
    $input: DeleteHistDentiProControlInput!
    $condition: ModelHistDentiProControlConditionInput
  ) {
    deleteHistDentiProControl(input: $input, condition: $condition) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMain = /* GraphQL */ `
  mutation CreateMain(
    $input: CreateMainInput!
    $condition: ModelMainConditionInput
  ) {
    createMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMain = /* GraphQL */ `
  mutation UpdateMain(
    $input: UpdateMainInput!
    $condition: ModelMainConditionInput
  ) {
    updateMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMain = /* GraphQL */ `
  mutation DeleteMain(
    $input: DeleteMainInput!
    $condition: ModelMainConditionInput
  ) {
    deleteMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHistMain = /* GraphQL */ `
  mutation CreateHistMain(
    $input: CreateHistMainInput!
    $condition: ModelHistMainConditionInput
  ) {
    createHistMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHistMain = /* GraphQL */ `
  mutation UpdateHistMain(
    $input: UpdateHistMainInput!
    $condition: ModelHistMainConditionInput
  ) {
    updateHistMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHistMain = /* GraphQL */ `
  mutation DeleteHistMain(
    $input: DeleteHistMainInput!
    $condition: ModelHistMainConditionInput
  ) {
    deleteHistMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
