import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactNode } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type RegistrationSuccessDialogProps = {
  open: boolean;
  onContinue: () => void;
  title: ReactNode;
  text: ReactNode;
};

const RegistrationSuccessDialog = ({
  open,
  title,
  text,
  onContinue,
}: RegistrationSuccessDialogProps) => {
  return (
    <Dialog
      sx={{ width: "50%", maxWidth: "1000px", margin: "0 auto" }}
      open={open}
      onClose={onContinue}
      aria-labelledby="success-title"
      aria-describedby="success-description"
    >
      <DialogTitle textAlign={"center"} id="success-title">
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <LazyLoadImage
          src={window.origin + "/password_success.webp"}
          alt="password successful changed"
          loading="lazy"
          style={{
            height: 220,
            objectFit: "cover",
          }}
        />
        <DialogContentText
          id="registration-success-description"
          style={{ textAlign: "center" }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px" }}
      >
        <Button onClick={onContinue} variant="contained" sx={{ width: "40%" }}>
          Weiter
        </Button>
      </div>
    </Dialog>
  );
};

export default RegistrationSuccessDialog;
