// convertToDateOrUseDefault.js

interface RawAppointment {
  Date?: string | null
  Name: string
  PK: string
  SK: string
}

interface Appointment {
  Date: string
  Name: string
  PK: string
  SK: string
}

const sortAndRenameAppointmentsWithKeys = (
  appointments: RawAppointment[],
): Appointment[] => {
  // Filter out invalid appointments
  const validAppointments = appointments.filter(
    (appointment): appointment is Appointment =>
      appointment.Date != null &&
      appointment.Date !== undefined &&
      appointment.Date !== '',
  )

  // Sort the appointments by date
  const sortedAppointments = validAppointments.sort(
    (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime(),
  )

  // Assign new names based on the sorted sequence
  sortedAppointments.forEach((appointment, index) => {
    appointment.Name = `Termin ${index + 1}`
  })

  return sortedAppointments
}
export default sortAndRenameAppointmentsWithKeys

