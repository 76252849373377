export const styles = {
  button: {
    color: 'black',
    fontSize: '14px',
    lineHeight: '21px',
    height: '29px',
    alignContent: 'end',
    textTransform: 'none',
  },
  pin: {
    position: 'absolute',
    top: '0',
    right: '40px',
  },
}
