import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import { awsDateFormat, dateFormat } from "../constants/date";
import { CustomFormLabel } from "../pages/PatientRegistrationPage";

type FormDatePickerProps<T> = {
  name: string;
  label: string;
  control: T;
};

const FormDatePicker = <T,>({
  name,
  label,
  control,
}: FormDatePickerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control as Control<FieldValues>}
      defaultValue={dayjs().format(awsDateFormat)}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <CustomFormLabel
            id="additionalTreatment-label"
            sx={{ marginBottom: "10px" }}
          >
            {label}
          </CustomFormLabel>
          <DatePicker
            maxDate={dayjs()}
            format={dateFormat}
            value={dayjs(field.value)}
            onChange={(value) =>
              value && field.onChange(value.format(awsDateFormat))
            }
          />
          {!!error && (
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          )}
          {field.value && dayjs(field.value).isAfter(dayjs()) && (
            <FormHelperText
              error={field.value && dayjs(field.value).isAfter(dayjs())}
            >
              Eingriff Datum muss in der Vergangenheit liegen
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormDatePicker;
