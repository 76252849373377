import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import SendIcon from '@mui/icons-material/Send'
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { generateClient } from 'aws-amplify/api'
import React, { useContext, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { AppContext } from '../App'
import FormAppointmentsList from '../components/FormAppointmentsList'
import FormDatePicker from '../components/FormDatePicker'
import FormNotes from '../components/FormNotes'
import FormRadioGroup from '../components/FormRadioGroup'
import FormSelect from '../components/FormSelect'
import Frame from '../components/Frame'
import RegistrationSuccessDialog from '../components/RegistrationSuccessDialog'
import { dentiColors } from '../constants/colors'
import { pageRoutes } from '../constants/routes'
import { getCaseNames, getUsers } from '../graphql-queries/customQueriesGraphQL'
import {
  GraphQlDentiControlResponseList,
  GraphQlResponseList,
  UserInUserList,
} from '../graphql-queries/types'
import { postPatient } from '../postPatient'
import extractElementNumber from '../utils/extractElementNumber'
import sortAndRenameAppointments from '../utils/sortAndRenameAppointments'

export const CustomFormLabel = styled(FormLabel)(() => ({
  color: dentiColors.baseDark,
}))

export const CustomFormControl = styled(FormControl)(() => ({
  color: dentiColors.baseDark,
  padding: '10px 0',
}))

const fetchDoctors = async (pk: string): Promise<UserInUserList[]> => {
  const client = generateClient()
  const response = await client.graphql({
    query: getUsers,
    variables: {
      limit: 1000000,
      filter: {
        PK: { eq: pk },
        SK: { beginsWith: 'USER#' },
        Description: { eq: 'Arzt' },
      },
    },
  })
  return (response as GraphQlResponseList<UserInUserList>).data.listMains.items
}

const fetchCaseTypes = async () => {
  const client = generateClient()
  const response = await client.graphql({
    query: getCaseNames,
    variables: {
      filter: { PK: { eq: 'LOV' }, SK: { beginsWith: 'CAT#casetype#' } },
    },
  })

  return (response as GraphQlDentiControlResponseList<{ Name: string }>).data
    .listDentiProControls.items
}

export const PatientRegistrationForm = styled('form')(() => ({
  flex: 'grow',
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
  alignItems: 'strech',
  justifyContent: 'center',
  gap: '10px',
  width: '50%',
  maxWidth: '500px',
  margin: '0 auto',
}))

const patientRegistrationSchema = yup
  .object({
    email: yup
      .string()
      .email('Bitte gültige Email Adresse angeben')
      .transform((v) => v.replaceAll(' ', '')),
    mobileNumber: yup
      .string()
      .transform((v) => v.replaceAll(' ', ''))
      .test(
        'len',
        'Eine Mobile Nummer muss zwischen 10 und 15 Zeichen haben!',
        (value) => {
          if (value === undefined || value.trim().length === 0) {
            return true
          }
          if (value.length < 10 || value.length > 15) {
            return false
          }
          return true
        },
      )
      .test(
        'len',
        'Muss mit + starten (z.b.: +49) und darf sonst nur Nummern enthalten',
        (value) => {
          const regex = /^\+\d+$/
          if (value && !regex.test(value.trim())) {
            return false
          }
          return true
        },
      ),
    salutation: yup
      .string()
      .oneOf(['Herr', 'Frau', 'Divers'], 'Bitte auswählen!')
      .default('Herr'),
    name: yup.string().required('Bitte Vornamen angeben!'),
    surName: yup.string().required('Bitte Nachname angeben!'),
    intervention: yup.string().required('Bitte ein Eingriffsart auswählen'),
    additionalTreatment: yup
      .string()
      .oneOf(['Ja', 'Nein'], 'Bitte auswählen!')
      .required('Bitte Ja oder Nein auswählen!'),
    interventionDate: yup.string().required('Bitte einen Datum auswählen!'),
    doctor: yup.string().required('Bitte einen behandelnden Arzt auswählen!'),
    notes: yup.object().shape({
      text: yup.string(),
      type: yup
        .string()
        .oneOf(['patientrelevant', 'caserelevant'], 'Bitte auswählen!')
        .default('caserelevant'),
    }),

    appointments: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        date: yup.string().nullable(),
        instructionID: yup.string().nullable(),
      }),
    ),
  })
  .required()

const PatientRegistrationPage = () => {
  const { user, instructionID } = useContext(AppContext)
  const navigate = useNavigate()

  const [openSuccess, setOpenSuccess] = useState(false)

  const { setPageTitle } = useContext(AppContext)
  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle)
  }

  React.useEffect(() => {
    handleTitleChange('')
  }, [])

  const {
    data: doctors,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useQuery({
    queryKey: ['doctors'],
    queryFn: () => fetchDoctors(user!.Clinic),
    enabled: !!user,
  })

  const {
    data: settings,
    isLoading: settingsIsLoading,
    isError: settingsIsError,
  } = useQuery({
    queryKey: ['settings'],
    queryFn: () => fetchCaseTypes(),
  })

  const {
    mutate,
    error: postPatientError,
    isPending,
  } = useMutation({
    mutationFn: postPatient,
    onSuccess: () => {
      setOpenSuccess(true)
    },
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(patientRegistrationSchema),
    mode: 'onBlur',
    defaultValues: {
      appointments: [
        { date: null, name: 'Termin 1' },
        { date: null, name: 'Termin 2' },
        { date: null, name: 'Termin 3' },
      ],
    },
  })

  const interventionValue = useWatch({ control, name: 'intervention' })
  const emailValue = useWatch({ control, name: 'email' })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'appointments',
  })

  const { ref: emailRef, ...emailProps } = register('email')
  const { ref: salutationRef, ...salutationProps } = register('salutation')
  const { ref: nameRef, ...nameProps } = register('name')

  const { ref: surNameRef, ...surNameProps } = register('surName')
  const { ref: mobileNumberRef, ...mobileNumberProps } =
    register('mobileNumber')
  const { ref: interventionRef, ...interventionProps } =
    register('intervention')
  const { ref: doctorRef, ...doctorProps } = register('doctor')

  return (
    <Frame
      isLoading={userIsLoading || settingsIsLoading}
      isError={settingsIsError || userIsError}
      entity="Einstellungen oder behandelnde Ärzte"
    >
      <RegistrationSuccessDialog
        open={openSuccess}
        onContinue={() => navigate(pageRoutes.patients)}
        text={`Der Patient erhält per ${
          emailValue ? 'Email' : 'SMS'
        } einen Zugangscode für die App zur Ferndiagnose!`}
        title="Patient registriert!"
      />
      <Typography
        textAlign={'center'}
        variant="h5"
      >
        Patienten Registrieren
      </Typography>
      <PatientRegistrationForm
        noValidate
        onSubmit={handleSubmit((values) => {
          if (!values.email && !values.mobileNumber) {
            setError('mobileNumber', {
              type: 'manual',
              message: 'Bitte Mobile Nummer oder Email angeben!',
            })
            setError('email', {
              type: 'manual',
              message: 'Bitte Mobile Nummer oder Email angeben!',
            })
            setFocus('mobileNumber')
            return
          }
          if (!user) {
            throw new Error('Missing user!')
          }
          const myAppointments = values.appointments ?? []
          const appointmentsWithInstructionID = myAppointments.map(
            (appointment) => ({
              ...appointment,
              instructionID, // Add instructionID from context
            }),
          )
          const sortedAppointments = sortAndRenameAppointments(
            appointmentsWithInstructionID,
          )
          const relevantClinic = user?.Clinic
          const userIdDefined = user?.PK
          const patientData = {
            relevantClinic,
            patientName: `${values.name} ${values.surName}`,
            salutation: values.salutation,
            name: values.name,
            surName: values.surName,
            userIdDefined,
            userIdSurgeon: extractElementNumber('USER', values.doctor),
            email: values.email?.replaceAll(' ', ''),
            phoneNumber: values.mobileNumber?.replaceAll(' ', ''),
            intervention: values.intervention,
            interventionDate: values.interventionDate,
            additionalTreatment: values.additionalTreatment,
            notes: values.notes.text,
            noteType: values.notes.type,
            appointments: sortedAppointments,
          }
          console.log('$$$$$$$$$$ patientData', patientData)
          mutate(patientData)
        })}
      >
        <CustomFormLabel>Name</CustomFormLabel>
        <FormSelect
          data={[{ value: 'Herr' }, { value: 'Frau' }, { value: 'Divers' }]}
          itemValue={'value'}
          itemLabel={'value'}
          placeholder="Anrede"
          inputRef={salutationRef}
          errorMessage={errors.salutation?.message}
          inputProps={salutationProps}
          isError={!!errors.salutation}
          label="Anrede"
        />
        <TextField
          inputRef={nameRef}
          {...nameProps}
          label="Vorname"
          type="text"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          inputRef={surNameRef}
          {...surNameProps}
          label="Nachname"
          type="text"
          error={!!errors.surName}
          helperText={errors.surName?.message}
        />
        <CustomFormLabel>
          Bitte Email oder Mobilenummer angeben!
        </CustomFormLabel>
        <TextField
          inputRef={emailRef}
          {...emailProps}
          label="Email"
          type="text"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          inputRef={mobileNumberRef}
          {...mobileNumberProps}
          label="Mobile Nummer"
          type="text"
          error={!!errors.mobileNumber}
          helperText={
            errors.mobileNumber
              ? errors.mobileNumber.message
              : 'Bitte mit Ländervorwahl angeben (z.B : +49...)'
          }
        />
        {settings && (
          <FormSelect
            data={settings}
            itemValue={'Name'}
            itemLabel={'Name'}
            placeholder="Eingriff"
            inputRef={interventionRef}
            errorMessage={errors.intervention?.message}
            inputProps={interventionProps}
            isError={!!errors.intervention}
            label="Bitte Eingriff auswählen"
          />
        )}
        <FormDatePicker
          control={control}
          name="interventionDate"
          label="Wann wurde der Eingriff vorgenommen?"
        />
        {doctors && (
          <FormSelect
            data={doctors}
            itemValue={'SK'}
            itemLabel={'UserName'}
            placeholder="Arzt"
            inputRef={doctorRef}
            errorMessage={errors.doctor?.message}
            inputProps={doctorProps}
            isError={!!errors.doctor}
            label="Wählen Sie den behandelnden Arzt"
          />
        )}
        <FormRadioGroup
          control={control}
          items={[
            { value: 'Ja', label: 'Ja' },
            { value: 'Nein', label: 'Nein' },
          ]}
          label=" Wurden weitere Behandlungen durchgeführt?"
          name="additionalTreatment"
        />
        <FormNotes
          control={control}
          withRadioGroup
        />
        <FormAppointmentsList
          control={control}
          interventionName={interventionValue}
          fields={fields}
          append={append}
          remove={remove}
        />
        <Button
          disabled={isPending}
          startIcon={<SendIcon />}
          type="submit"
          variant="contained"
        >
          Absenden
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPending}
          title="Daten werden gespeichert ..."
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {postPatientError && (
          <FormHelperText error={!!postPatientError}>
            {'Fehler beim speichern der Patienten Daten:' +
              postPatientError?.message}
          </FormHelperText>
        )}
      </PatientRegistrationForm>
    </Frame>
  )
}

export default PatientRegistrationPage

