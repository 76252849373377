import { Box, TextField } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";

import { dentiColors } from "../constants/colors";
import { CustomFormLabel } from "../pages/PatientRegistrationPage";
import FormRadioGroup from "./FormRadioGroup";

type FormRadioGroupProps<T> = {
  control: T;
  withRadioGroup?: boolean;
};

const FormNotes = <T,>({
  control,
  withRadioGroup = false,
}: FormRadioGroupProps<T>) => {
  return (
    <Box
      sx={{
        padding: "10px 20px",
        border: `solid 1px ${dentiColors.blueMedium}`,
        borderRadius: "8px",
      }}
    >
      <CustomFormLabel id="additionalTreatment-label">
        Notizen (optional)
      </CustomFormLabel>
      <Controller
        name="notes.text"
        control={control as Control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            fullWidth
            label="Notizen ..."
            margin="normal"
            value={field.value}
            onChange={field.onChange}
            multiline
            rows={4}
          />
        )}
      />
      {withRadioGroup && (
        <FormRadioGroup
          control={control as Control}
          label=""
          name="notes.type"
          items={[
            { label: "Relevant für Patient", value: "patientrelevant" },
            { label: "Relevant für Fall", value: "caserelevant" },
          ]}
        />
      )}
    </Box>
  );
};

export default FormNotes;
