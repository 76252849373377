
import { Box } from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridCellParams , GridColDef , MuiEvent } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import React, { useContext,useEffect, useState  } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../App";
import Frame from "../components/Frame";
import NoData from "../components/NoData";
import { getToDoList } from "../graphql-queries/customQueriesGraphQL";
import { GraphQlResponseList,ToDoListItem } from "../graphql-queries/types";
import extractElementNumber from "../utils/extractElementNumber";
import makePrettyDate from "../utils/makePrettyDate";
import updateToDoListRecord from "../updateToDoListRecord";


const columns: GridColDef<ToDoListItem>[] = [
  {
    field: "PatientName",
    headerName: "Name",
    minWidth: 250,
  },
  {
    field: "Intervention",
    headerName: "Fall",
    minWidth: 250,
  },
  {
    field: "Source",
    headerName: "Nachricht",
    minWidth: 250,
  },
  {
    field: "DateTime",
    headerName: "Letzte Nachricht vom",
    minWidth: 200,
    valueFormatter: (params) => {
      return makePrettyDate(params.value);
    },
    sortComparator: (v2, v1) => new Date(v2).getTime() - new Date(v1).getTime(), 
  },
];

export const sxSettings = {
  ".MuiDataGrid-root": {
    border: 0,
  },
  ".MuiDataGrid-cell": {
    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
  },
  // disable cell selection style
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  // pointer cursor on ALL rows
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
};

const fetchToDoList = async (userKey: string) => {
  const client = generateClient();
  const beginsWithFilter = `TYP#ToDo#USER#${userKey}`;
  const response = await client.graphql({
    query: getToDoList,
    variables: {
      limit: 1000000,
      filter: {
        SK: { beginsWith: beginsWithFilter }
      }
    },
  });
  return (response as GraphQlResponseList<ToDoListItem>).data.listMains.items;
};

const ToDoListPage = () => {
  const [selectedIds, setSelectedIds] = useState(new Set());
  const { setPageTitle } = useContext(AppContext);
  const { user, setUser } = useContext(AppContext);
  

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle);
  };

  React.useEffect(() => {
    handleTitleChange("To-Do's");
  }, []);

  const checkboxColumn: GridColDef = {
    field: 'checkbox',
    headerName: '',
    renderCell: (params) => (
      <Checkbox
        checked={selectedIds.has(params.id)}
        onChange={(event) => {
          // Only allow changing the selection if the status is not "Closed"
          if (params.row.Status !== "Closed") {
            const newSelectedIds = new Set(selectedIds);
            if (event.target.checked) {
              newSelectedIds.add(params.id);
            } else {
              newSelectedIds.delete(params.id);
            }
            setSelectedIds(newSelectedIds);
          }
        }}
        disabled={params.row.Status === "Closed"}
      />
    ),
    width: 50,
  };

  // Add the custom checkbox column to your columns array
  const columnsWithCheckbox = [checkboxColumn, ...columns];


  const handleCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    const activePatient = params.row ? 
    extractElementNumber("PAT", params.row.SK) : null;
    if (params.field !== "checkbox") {
      console.log("TTTTTTT in handleCellClick params.field is not checkbox:", params.field)
      if (activePatient) {
        navigate(`/patients/${activePatient}/cases`);
      }
    } else {
      // postings here later after app has delievered ToDos
      console.log("TTTTTTT in handleCellClick params.field IS checkbox:", params.field)
      console.log("TTTTTTT in handleCellClick activePatient:", activePatient)
      console.log("TTTTTTT in handleCellClick params:", params)
      const originalPK = params.row.PK;
      const originalSK = params.row.SK
      const newSK = originalSK.replace("Open", "Closed");
      const newStatus = "Closed"

      updateToDoListRecord(originalPK, originalSK, newSK, newStatus)
      .then(() => {
        console.log('TTTTTTT ToDoList record updated successfully with status:', newStatus);
        refetch();  // Call refetch to refresh the data
      })
      .catch(error => console.error('TTTTTTT Error for ToDoList record update error:', error));
     
    }
  };


  const {
    data: toDoListItems,
    isLoading,
    isError,
    refetch,  // Extract the refetch function
  } = useQuery({
    queryKey: ["toDoListItems", user?.PK], 
    queryFn: () => fetchToDoList(user?.PK!),
  });



  const navigate = useNavigate();

  const [rowsWithIds, setRowsWithIds] = useState<readonly ToDoListItem[]>([]);

  useEffect(() => {
    if (toDoListItems) {
      const updatedRowsWithIds = toDoListItems.map((item) => ({ ...item, id: item.SK }));
      setRowsWithIds(updatedRowsWithIds);
      console.log("DDDDDDDDDDD updatedRowsWithIds: ", updatedRowsWithIds)

      const closedItemIds = updatedRowsWithIds
        .filter((item) => item.Status === "Closed")
        .map((item) => item.id); 
      setSelectedIds(new Set(closedItemIds));
      console.log("DDDDDDDDDDD closedItemIds: ", closedItemIds)
      console.log("DDDDDDDDDDD selectedIds: ", selectedIds)
    }
  }, [toDoListItems]);


  return (
    <Box sx={{ width: '100%' }}>
    <Frame isError={isError} isLoading={isLoading} entity="ToDoList">
      {toDoListItems && (
        <DataGrid
          rows={rowsWithIds}
          onCellClick={handleCellClick}
          getRowId={(row) => row.SK}
          slots={{
            noRowsOverlay: NoData,
          }}
          sx={sxSettings}
          getRowClassName={() => `custom-row`}
          sortModel={[
            {
              field: 'DateTime',
              sort: 'desc', // for descending order
            },
          ]}
          columns={columnsWithCheckbox}

        />
      )}
    </Frame>
    </Box>
  );
};

export default ToDoListPage;
