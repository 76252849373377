import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined'
import { getVideoUrl } from '../../utils/getVideoUrl'

type VideoUrlsType = {
  [key: string]: string
}

interface IVideoDisplay {
  imageLocation: string
  handleVideoClick: (videoUrl: string) => void
}

const VideoDisplay: React.FC<IVideoDisplay> = ({
  imageLocation,
  handleVideoClick,
}) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [videoUrls, setVideoUrls] = useState<VideoUrlsType>({})
  const [thumbnailUrl, setThumbnailUrl] = useState('')

  const videoRef = useRef(null)
  const canvasRef = useRef(null)

  const captureThumbnail = () => {
    const video: any = videoRef.current
    const canvas: any = canvasRef.current

    if (video && canvas) {
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

      const thumbnailDataUrl = canvas.toDataURL()
      setThumbnailUrl(thumbnailDataUrl)
    }
  }

  const fetchVideoUrl = async (imageLocation: string) => {
    // First check if we already have the URL
    if (!videoUrls[imageLocation]) {
      try {
        // Call your API to get the video URL
        const signedUrlData = await getVideoUrl(imageLocation)

        // Make sure that signedUrlData is an object and has the 'url' property
        //      if (signedUrlData) {
        if (typeof signedUrlData === 'object' && 'url' in signedUrlData) {
          const signedUrl = signedUrlData.url.href
          // Update state with the new URL
          setVideoUrls((prevUrls) => ({
            ...prevUrls,
            [imageLocation]: signedUrl,
          }))
          console.log("before return 1 signedUrl: ", signedUrl)
          return signedUrl
        } else {
          const signedUrl = signedUrlData
          setVideoUrls((prevUrls) => ({
            ...prevUrls,
            [imageLocation]: signedUrl,
          }))
          return signedUrl
        }
        //    throw new Error("Invalid URL data");
      } catch (error) {
        console.error('Error fetching video URL', error)
        return ''
      }
    } else {
      // If we already have the URL, return it from the state
      return videoUrls[imageLocation]
    }
  }

  useEffect(() => {
    if (imageLocation) {
      setIsLoading(true)
      fetchVideoUrl(imageLocation)
        .then((url) => {
          setVideoUrl(url)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching video URL:', error)
          setIsLoading(false)
        })
    }
  }, [imageLocation])

  if (isLoading) {
    return <div>Loading video...</div> // Replace with your actual loading placeholder
  }

  if (videoUrl) {
    return (
      // Your video player JSX using videoUrl
      <Box
        sx={{
          maxWidth: '70%',
          backgroundColor: '#e3ecf8',
          borderRadius: '10px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '10px',
          padding: '10px',
          margin: '10px',
          marginBottom: '5px',
          marginLeft: undefined,
          marginRight: 'auto',
          textAlign: 'left',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={() => handleVideoClick(videoUrl)} // use fetched videoUrl here
      >
        {/* Video element */}
        <video
          controls={false}
          onLoadedMetadata={captureThumbnail}
          style={{ maxWidth: '100%', borderRadius: '10px' }}
          src={videoUrl}
        />
        {/* Play icon overlay */}
        <PlayCircleFilledOutlinedIcon
          sx={{
            position: 'absolute',
            width: '45px',
            height: '45px',
            left: '45%',
            top: '45%',
            color: 'white',
          }}
        />
      </Box>
    )
  }
  return null
}

export default VideoDisplay
