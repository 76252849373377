import { LinearProgress } from "@mui/material";
import React, { ReactNode } from "react";

type FrameProps = {
  isLoading: boolean;
  isError: boolean;
  entity: string;
  noData?: boolean;
  children: ReactNode;
};

const Frame = ({
  isError,
  isLoading,
  entity,
  noData,
  children,
}: FrameProps) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {isLoading && (
        <>
          <h3> {entity} Daten werden geladen ...</h3>
          <LinearProgress />
        </>
      )}
      {isError && <h3>Fehler beim Laden der {entity} Daten</h3>}
      {!isLoading && !isError && noData && <p>Keine {entity} vorhanden</p>}
      {children}
    </div>
  );
};

export default Frame;
