import { generateClient } from "aws-amplify/api";
import { createMain as createMainMutation } from "./graphql/mutations";
import { generateSortKey2 } from "./utils/sortKeyHelpers";
import { updateMainTable } from "./updateMainTable";
import { postAppointments } from "./postAppointments";
import { postNote } from "./postNote";

/* 
This function gets data for a set with :
  case
  notes
  appointments

On this level, it will execute the posting of the  intervention data and 
then call 
  postNote (if notes were made)
  postAppointments and postAppointments will call postSingleAppointment

In this way, we can use these modules independently.

As the patient's PK, we use a uuid
*/

export async function postCase(caseData) {
  console.log(`PiPiPiPiPiPi postCase start caseData: `, caseData);

  const myCaseNumber = caseData.counterCase + 1;
  const caseSK = generateSortKey2("CASE", String(myCaseNumber));
  const myCounterNote = caseData.counterNote;

  let insertData;
  try {
    const client = generateClient();
    insertData = {
      PK: caseData.PK,
      SK: caseSK,
      PatientName: caseData.patientName,
      ClinicAccess: caseData.relevantClinic,
      AdditionalTreatments: caseData.additionalTreatment,
      Intervention: caseData.intervention,
      UserIdDefined: caseData.userIdDefined,
      UserIdSurgeon: caseData.userIdSurgeon,
      Date: caseData.interventionDate,
    };
    console.log("PiPiPiPiPiPi  insert fields insertData:", insertData);

    const response = await client.graphql({
      query: createMainMutation,
      variables: { input: insertData },
    });
    console.log(
      "PiPiPiPiPiPi in createMainTable inserted:",
      response.data.createMain
    );

/*
We update the patient master record with the counterCase
*/
    try {
      await updateMainTable(caseData.PK, "PAT#", "CounterCase", myCaseNumber);
    } catch (error) {
      console.error(
        "There was an error updating the patient master record",
        error
      );
      throw error;
    }

    /*
If notes were entered, we register these
*/
    if (caseData.notes) {
      console.log(`PiPiPiPiPiPi notes found: `, caseData.notes);
      const notesData = {
        PK: caseData.PK,
        clinicAccess: caseData.relevantClinic,
        counterNote: myCounterNote + 1,
        counterCase: myCaseNumber,
        notes: caseData.notes,
        noteType: caseData.noteType,
        userIdDefined: caseData.userIdDefined,
      };
      console.log(`PiPiPiPiPiPi notesData: `, notesData);
      try {
        await postNote(notesData);
        // Handle successful submission, e.g., clearing the form, showing a message, etc.
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error("There was an error posting the notes data", error);
        throw error;
      }
    }

    /*
Now we can post the appointments. For this purpose we prepare the structure for the 
parameter handover. It is a sub-set of caseData
*/
    const appointmentData = {
      pk: caseData.PK,
      activeCase: myCaseNumber,
      counterAppointment: caseData.counterAppointment,
      relevantClinic: caseData.relevantClinic,
      appointments: caseData.appointments,
      userIdDefined: caseData.userIdDefined
    };
    console.log("PiPiPiPiPiPi appointmentData:", appointmentData);

    try {
      await postAppointments(appointmentData);
      // Handle successful submission, e.g., clearing the form, showing a message, etc.
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("There was an error posting the appointments", error);
      throw error;
    }
  } catch (error) {
    console.error(
      "PiPiPiPiPiPi in createMainTable / Error inserting Case:",
      error
    );

    // Assuming 'error' is an object that contains an 'errors' array
    if (
      error.errors &&
      error.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException"
    ) {
      throw new Error("Case already exists");
    }
    throw error; // Rethrow the original error if it's not the conditional check error
  }
}
