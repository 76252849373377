// convertToDateOrUseDefault.js

interface RawAppointment {
  date?: string | null
  name: string
}

interface Appointment {
  date: string
  name: string
}

const sortAndRenameAppointments = (
  appointments: RawAppointment[],
): Appointment[] => {
  // Filter out invalid appointments
  const validAppointments = appointments.filter(
    (appointment): appointment is Appointment =>
      appointment.date != null &&
      appointment.date !== undefined &&
      appointment.date !== '',
  )

  // Sort the appointments by date
  const sortedAppointments = validAppointments.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  )

  // Assign new names based on the sorted sequence
  sortedAppointments.forEach((appointment, index) => {
    appointment.name = `Termin ${index + 1}`
  })

  return sortedAppointments
}
export default sortAndRenameAppointments
