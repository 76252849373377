import React from "react";

import SvgContainer from "./SvgContainer";
import { Icon } from "./type";

const Logo: Icon = (props) => {
  return (
    <SvgContainer {...props}>
      <g clipPath="url(#clip0_4389_64311)">
        <path
          d="M11.0986 17.356H2.52378C1.15546 17.356 0 16.2676 0 14.9143C0 13.5905 1.12506 12.4727 2.52378 12.4727H11.0986C12.4669 12.4727 13.6224 13.5611 13.6224 14.9143C13.6224 16.2676 12.4973 17.356 11.0986 17.356Z"
          fill="#C4D2F4"
        />
        <path
          d="M17.4538 19.1215V27.4174C17.4538 28.7412 16.3287 29.8591 14.93 29.8591C13.5617 29.8591 12.4062 28.7706 12.4062 27.4174V19.1215C12.4062 17.7976 13.5312 16.6797 14.93 16.6797C16.2983 16.6797 17.4538 17.7682 17.4538 19.1215Z"
          fill="#C4D2F4"
        />
        <path
          d="M18.7582 12.5039H27.333C28.7013 12.5039 29.8568 13.5924 29.8568 14.9456C29.8568 16.2694 28.7317 17.3874 27.333 17.3874H18.7582C17.3898 17.3874 16.2344 16.2988 16.2344 14.9456C16.2344 13.5924 17.3595 12.5039 18.7582 12.5039Z"
          fill="#1045F6"
        />
        <path
          d="M12.4062 10.7377V2.44172C12.4062 1.1179 13.5312 0 14.93 0C16.2983 0 17.4538 1.08848 17.4538 2.44172V10.7377C17.4538 12.0615 16.3287 13.1794 14.93 13.1794C13.5617 13.1499 12.4062 12.0615 12.4062 10.7377Z"
          fill="#1045F6"
        />
        <path
          d="M21.5001 10.4122C22.7039 10.4122 23.6798 9.4363 23.6798 8.23245C23.6798 7.02863 22.7039 6.05273 21.5001 6.05273C20.2962 6.05273 19.3203 7.02863 19.3203 8.23245C19.3203 9.4363 20.2962 10.4122 21.5001 10.4122Z"
          fill="#C4D2F4"
        />
        <path
          d="M7.88286 23.9376C9.0867 23.9376 10.0625 22.9617 10.0625 21.7579C10.0625 20.554 9.0867 19.5781 7.88286 19.5781C6.67902 19.5781 5.70312 20.554 5.70312 21.7579C5.70312 22.9617 6.67902 23.9376 7.88286 23.9376Z"
          fill="#1045F6"
        />
      </g>
      <defs>
        <clipPath id="clip0_4389_64311">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </SvgContainer>
  );
};

export default Logo;
