export const dentiColors = {
  accent: "#1045F6",
  baseLight: "#FFFFFF",
  baseDark: "#010202",
  greyLow: "#BCBCBC",
  greyMedium: "#5F6572",
  greyHigh: "#454545",
  blueLow: "#F5F7FA",
  blueMedium: "#EAEDF1",
  blueHigh: "#E8ECFC",
  blueUltra: "#8E9EB4",
  lightSuccess: "#B5EFB3",
  success: "#128400",
  danger: "#E10000",
  warning: "#E18700",
} as const;
