// NewAppointmentDialog.tsx
import { CloseOutlined, SaveOutlined } from '@mui/icons-material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'

import { dentiColors } from '../constants/colors'
import { awsDateFormat, dateFormat } from '../constants/date'

interface NewAppointmentDialogProps {
  open: boolean
  onClose: () => void
  handleSubmit: (date: string, appointmentName: string) => void
  nextAppointmentName?: string
}

const NewAppointmentDialog: React.FC<NewAppointmentDialogProps> = ({
  open,
  onClose,
  handleSubmit,
  nextAppointmentName,
}) => {
  const today = dayjs()
  const [date, setDate] = useState<Dayjs>(today)

  const handleConfirm = () => {
    handleSubmit(
      date.format(awsDateFormat),
      nextAppointmentName || 'Default Appointment Name',
    )
    onClose() // Close dialog
    setDate(today)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '500px', gap: '10px' }, // or another appropriate width
      }}
    >
      <DialogTitle sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
        <CalendarTodayOutlinedIcon />
        Neuen Termin hinzufügen
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          {nextAppointmentName}
        </Typography>
        <DatePicker
          minDate={dayjs()}
          sx={{ width: '100%' }}
          format={dateFormat}
          value={date}
          onChange={(value) => value && setDate(value)}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', margin: '0 18px' }}>
        <Button
          startIcon={<SaveOutlined />}
          variant="contained"
          sx={{ backgroundColor: dentiColors.baseDark }}
          onClick={handleConfirm}
          color="primary"
        >
          Speichern
        </Button>
        <Button
          startIcon={<CloseOutlined />}
          variant="contained"
          sx={{ backgroundColor: dentiColors.baseDark }}
          onClick={() => {
            onClose()
            setDate(today)
          }}
        >
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewAppointmentDialog

