import styled from '@emotion/styled'
import { SaveOutlined } from '@mui/icons-material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { generateClient, GraphQLQuery } from 'aws-amplify/api'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { updateMainTable } from '../updateMainTable'
import renameAppointments from '../renameAppointments'
import { dentiColors } from '../constants/colors'
import { awsDateFormat, dateFormat } from '../constants/date'
import { deleteMain, updateMain } from '../graphql/mutations'
import { Appointment } from '../graphql-queries/types'
import { getPatientsAppointments } from '../graphql-queries/customQueriesGraphQL'
import sortAndRenameAppointmentsWithKeys from '../utils/sortAndRenameAppointmentsWithKeys'

interface ListMainsResponse {
  data: {
    listMains: {
      items: Array<{
        Date: string
        Name: string
        PK: string
        SK: string
      }>
    }
  }
}

const stripAppNumberFromSK = (SK: string): string => {
  const match = SK.match(/^(CASE#\d+#APP#)/)
  return match ? match[1] : SK // Return the matched part or the original SK if no match
}

const deleteAppoinment = async (PK: string, SK: string) => {
  const client = generateClient()
  const strippedSK = stripAppNumberFromSK(SK)

  await client.graphql<GraphQLQuery<any>>({
    query: deleteMain,
    variables: {
      input: {
        PK,
        SK,
      },
    },
  })

  // after an appointment deletion, the names of the remaining appointments have
  // to be adjusted
  await renameAppointments(PK, strippedSK)
}

const updateAppoinment = async (PK: string, SK: string, newDate: string) => {
  const client = generateClient()
  const strippedSK = stripAppNumberFromSK(SK)

  await client.graphql({
    query: updateMain,
    variables: {
      input: {
        PK,
        SK,
        Date: newDate,
      },
    },
  })
  // after an appointment date change, the names of the remaining appointments have
  // to be adjusted since the modified appointment might now be in a differente
  // sequence position in the appointment list
  await renameAppointments(PK, strippedSK)
}

type EditAppointmentModalProps = {
  open: boolean
  onClose: () => void
  appointment: Appointment
  handleDelete: () => void
}

function EditAppointmentModal({
  open,
  onClose,
  appointment,
  handleDelete,
}: EditAppointmentModalProps) {
  const [date, setDate] = React.useState<Dayjs>(dayjs(appointment.Date))
  const { partitionKey, sortKey } = useParams()
  const queryClient = useQueryClient()

  const { mutate: updateDate } = useMutation({
    mutationFn: () =>
      updateAppoinment(
        appointment.PK,
        appointment.SK,
        date.format(awsDateFormat),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['patients', partitionKey, 'cases', sortKey, 'appointments'],
      })
      onClose()
    },
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '400px', gap: '10px' },
      }}
    >
      <DialogTitle sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
        <EditOutlinedIcon /> {appointment.Name} - bearbeiten
      </DialogTitle>
      <DialogContent>
        <DatePicker
          sx={{ width: '100%' }}
          minDate={dayjs()}
          value={date}
          onChange={(value) => value && setDate(value)}
          format={dateFormat}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', margin: '0 18px' }}>
        <Button
          startIcon={<SaveOutlined />}
          variant="contained"
          sx={{ backgroundColor: dentiColors.baseDark }}
          onClick={() => updateDate()}
          disabled={appointment.Date === date?.format(awsDateFormat)}
        >
          Speichern
        </Button>
        <Button
          startIcon={<DeleteOutlineIcon />}
          variant="contained"
          sx={{
            backgroundColor: dentiColors.danger,
            '&:hover': {
              backgroundColor: dentiColors.danger,
            },
          }}
          onClick={handleDelete}
        >
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getAppointmentDateText = (date: string) => {
  const appointmentDate = dayjs(date)
  const now = dayjs()
  if (appointmentDate.isBefore(now, 'day')) {
    return (
      <span
        style={{
          display: 'flex ',
          alignItems: 'center',
          gap: '5px',
          color: dentiColors.success,
        }}
      >
        <CircleIcon style={{ fontSize: '8px' }} />
        <span>Bereits durchgeführt</span>
      </span>
    )
  }
  if (appointmentDate.isAfter(now, 'day')) {
    return (
      <span
        style={{
          display: 'flex ',
          alignItems: 'center',
          gap: '5px',
          color: dentiColors.warning,
        }}
      >
        <CircleIcon style={{ fontSize: '8px' }} />
        <span>Bevorstehend</span>
      </span>
    )
  }
  if (appointmentDate.isSame(now, 'day')) {
    return (
      <span
        style={{
          display: 'flex ',
          alignItems: 'center',
          gap: '5px',
          color: dentiColors.accent,
        }}
      >
        <CircleIcon style={{ fontSize: '8px' }} />
        <span>Heute</span>
      </span>
    )
  }
  return dayjs(date).format(dateFormat)
}

const AppoitmentContainer = styled('div')(() => ({
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  border: `${dentiColors.blueMedium} solid 1px`,
}))
type AppointmentCardProps = {
  appointment: Appointment
  number: number
}

const AppointmentCard = ({ appointment, number }: AppointmentCardProps) => {
  const [open, setOpen] = useState(false)
  const { partitionKey, sortKey } = useParams()
  const queryClient = useQueryClient()

  const { mutate: doDelete } = useMutation({
    mutationFn: () => deleteAppoinment(appointment.PK, appointment.SK),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['patients', partitionKey, 'cases', sortKey, 'appointments'],
      })
      setOpen(false)
    },
  })

  return (
    <AppoitmentContainer>
      <EditAppointmentModal
        handleDelete={doDelete}
        open={open}
        onClose={() => setOpen(false)}
        appointment={appointment}
      />
      <Grid
        container
        height={'100%'}
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
          }}
        >
          <span style={{ fontSize: '16px', fontWeight: 600 }}>
            {appointment.Name}
          </span>
          {!dayjs(appointment.Date).isBefore(new Date(), 'day') && (
            <IconButton onClick={() => setOpen(true)}>
              <EditOutlinedIcon />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Divider
            style={{
              margin: '10px 0 10px 0',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              gap: '3px',
            }}
          >
            <CalendarTodayOutlinedIcon sx={{ fill: dentiColors.greyLow }} />
            <span style={{ marginTop: '3px', color: dentiColors.greyLow }}>
              {dayjs(appointment.Date).format('DD MMMM')}
            </span>
          </div>
          {getAppointmentDateText(appointment.Date)}
        </Grid>
      </Grid>
    </AppoitmentContainer>
  )
}

export default AppointmentCard

