/*
export type PasswordResetSections =
  | "enter-code"
  | "change-password"
  | "success";

export type SignUpSections =
  | "name-email"
  | "enter-code"
  | "password"
  | "customer-account"
  | "two-factor-auth"
  | "two-factor-auth-mobile-number"
  | "success";
*/

export const pageRoutes = {
  patients: "/patients",
  patientRegister: "/register-patient",

  patientsCasesByPK: (partitionKey: string) =>
    `/patients/${partitionKey}/cases`,
} as const;

/*
export const authFlowRoutes = [
  pageRoutes.login,
  pageRoutes.password,
  pageRoutes.signUp,
] as string[];
*/
