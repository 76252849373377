
import { DataGrid, GridColDef , GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import React, { useContext,useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../App";
import ClearingEdit from "../components/ClearingEdit";
import Frame from "../components/Frame";
import NoData from "../components/NoData";
import { getClearingList } from "../graphql-queries/customQueriesGraphQL";
import { ClearingListItem, GraphQlResponseList } from "../graphql-queries/types";
import extractElementNumber from "../utils/extractElementNumber";

const columns: GridColDef<ClearingListItem>[] = [
  {
    field: "Intervention",
    headerName: "Fall",
    minWidth: 200,
  },
  {
    field: "PatientName",
    headerName: "Name",
    minWidth: 400,
  },
  {
    field: "ClearingStatus",
    headerName: "Abrechnungsstatus",
    minWidth: 400,
  },
  {
    field: "InsuranceType",
    headerName: "Versicherungsstatus",
    minWidth: 200,
  },
];

export const sxSettings = {
  ".MuiDataGrid-root": {
    border: 0,
  },
  ".MuiDataGrid-cell": {
    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
  },
  // disable cell selection style
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  // pointer cursor on ALL rows
  "& .MuiDataGrid-row:hover": {
    cursor: "pointer",
  },
};

const fetchClearingList = async () => {
  const client = generateClient();
  const response = await client.graphql({
    query: getClearingList,
    variables: { limit: 1000000, filter: { SK: { beginsWith: "TYP#ClearingList#" } } },
  });
  return (response as GraphQlResponseList<ClearingListItem>).data.listMains.items;
};

const ClearingListPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ClearingListItem>();
  const { setPageTitle } = useContext(AppContext);

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle);
  };

  React.useEffect(() => {
    handleTitleChange('Abrechnung');
  }, []);

  // Function to handle row click
  const handleRowClick = (params: GridRowParams) => {
    console.log("XXXXXXXXX in handleRowClick params.row",params.row )
    setSelectedRow(params.row);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  //  setSelectedRow();
  };


  const {
    data: clearingListItems,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["clearingListItems"],
    queryFn: fetchClearingList,
  });

  const navigate = useNavigate();
  const activeCase = selectedRow ? 
  extractElementNumber("CASE", selectedRow.SK) : null;

  return (
    <Frame isError={isError} isLoading={isLoading} entity="ClearingList">
      {clearingListItems && (
        <DataGrid
          rows={clearingListItems}
          onRowClick={handleRowClick}
          columns={columns}
          getRowId={(row) => row.PK + row.SK}
          slots={{
            noRowsOverlay: NoData,
          }}
          sx={sxSettings}
          getRowClassName={() => `custom-row`}
        />
      )}
      {isModalOpen && selectedRow && (
        <ClearingEdit
          activeCase={activeCase}
          isOpen={isModalOpen}
          onClose={closeModal}
          rowData={selectedRow}
        />
      )}
    </Frame>
  );
};

export default ClearingListPage;
