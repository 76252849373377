import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import SendIcon from '@mui/icons-material/Send'
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { generateClient } from 'aws-amplify/api'
import React, { useContext, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

import { AppContext } from '../App'
import FormAppointmentsList from '../components/FormAppointmentsList'
import FormDatePicker from '../components/FormDatePicker'
import FormNotes from '../components/FormNotes'
import FormRadioGroup from '../components/FormRadioGroup'
import FormSelect from '../components/FormSelect'
import Frame from '../components/Frame'
import { fetchPatientsDetails } from '../components/PatientPanel'
import RegistrationSuccessDialog from '../components/RegistrationSuccessDialog'
import { dentiColors } from '../constants/colors'
import { pageRoutes } from '../constants/routes'
import { getCaseNames, getUsers } from '../graphql-queries/customQueriesGraphQL'
import {
  GraphQlDentiControlResponseList,
  GraphQlResponseList,
  UserInUserList,
} from '../graphql-queries/types'
import { postCase } from '../postCase'
import extractElementNumber from '../utils/extractElementNumber'
import sortAndRenameAppointments from '../utils/sortAndRenameAppointments'

export const CustomFormLabel = styled(FormLabel)(() => ({
  color: dentiColors.baseDark,
}))

export const CustomFormControl = styled(FormControl)(() => ({
  color: dentiColors.baseDark,
  padding: '10px 0',
}))

const fetchDoctors = async (pk: string): Promise<UserInUserList[]> => {
  const client = generateClient()
  const response = await client.graphql({
    query: getUsers,
    variables: {
      limit: 1000000,
      filter: {
        PK: { eq: pk },
        SK: { beginsWith: 'USER#' },
        Description: { eq: 'Arzt' },
      },
    },
  })
  return (response as GraphQlResponseList<UserInUserList>).data.listMains.items
}

const fetchCaseTypes = async () => {
  const client = generateClient()
  const response = await client.graphql({
    query: getCaseNames,
    variables: {
      limit: 1000000,
      filter: { PK: { eq: 'LOV' }, SK: { beginsWith: 'CAT#casetype#' } },
    },
  })

  return (response as GraphQlDentiControlResponseList<{ Name: string }>).data
    .listDentiProControls.items
}

export const CaseRegistrationForm = styled('form')(() => ({
  flex: 'grow',
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
  alignItems: 'strech',
  justifyContent: 'center',
  gap: '10px',
  width: '50%',
  maxWidth: '500px',
  margin: '0 auto',
}))

const caseRegistrationSchema = yup
  .object({
    intervention: yup.string().required('Bitte ein Eingriffsart auswählen'),
    additionalTreatment: yup
      .string()
      .oneOf(['Ja', 'Nein'], 'Bitte auswählen!')
      .required('Bitte Ja oder Nein auswählen!'),
    interventionDate: yup.string().required('Bitte einen Datum auswählen!'),
    doctor: yup.string().required('Bitte einen behandelnden Arzt auswählen!'),
    notes: yup.object().shape({
      text: yup.string(),
      type: yup.string().default('caserelevant'),
    }),
    appointments: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        date: yup.string().nullable(),
        instructionID: yup.string().nullable(),
      }),
    ),
  })
  .required()

const AddCasePage = () => {
  const { user, instructionID } = useContext(AppContext)
  const navigate = useNavigate()

  const [openSuccess, setOpenSuccess] = useState(false)
  const { setPageTitle, patientName } = useContext(AppContext)

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle)
  }

  const { partitionKey } = useParams()

  if (!partitionKey) {
    throw new Error('PartitionKey fehlt in der URL!')
  }

  React.useEffect(() => {
    handleTitleChange(`Patienten >> ${patientName}`)
  }, [])

  const {
    data: doctors,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useQuery({
    queryKey: ['doctors'],
    queryFn: () => fetchDoctors(user!.Clinic),
    enabled: !!user,
  })

  const {
    data: settings,
    isLoading: settingsIsLoading,
    isError: settingsIsError,
  } = useQuery({
    queryKey: ['settings'],
    queryFn: () => fetchCaseTypes(),
  })

  const {
    data: patientInfo,
    isLoading: patientIsLoading,
    isError: patientIsError,
  } = useQuery({
    queryKey: ['patients', partitionKey, 'details'],
    queryFn: () =>
      fetchPatientsDetails(partitionKey ?? 'missing partition key'),
  })

  const {
    mutate,
    error: postPatientError,
    isPending,
  } = useMutation({
    mutationFn: postCase,
    onSuccess: () => {
      setOpenSuccess(true)
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(caseRegistrationSchema),
    mode: 'onBlur',
    defaultValues: {
      appointments: [
        { date: null, name: 'Termin 1' },
        { date: null, name: 'Termin 2' },
        { date: null, name: 'Termin 3' },
      ],
    },
  })

  const interventionValue = useWatch({ control, name: 'intervention' })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'appointments',
  })

  const { ref: interventionRef, ...interventionProps } =
    register('intervention')
  const { ref: doctorRef, ...doctorProps } = register('doctor')

  return (
    <Frame
      isLoading={userIsLoading || settingsIsLoading || patientIsLoading}
      isError={settingsIsError || userIsError || patientIsError}
      entity="Einstellungen, Ärzte, PatientenInfo"
    >
      <RegistrationSuccessDialog
        open={openSuccess}
        onContinue={() => navigate(pageRoutes.patientsCasesByPK(partitionKey))}
        text={
          <>
            Der neue Fall wurde dem Patienten{' '}
            <strong>{patientInfo?.PatientName}</strong> erfolgreich hinzugefügt.
          </>
        }
        title={
          <>
            Neuer Fall <strong>{interventionValue}</strong> gespeichert!{' '}
          </>
        }
      />
      <Typography
        textAlign={'center'}
        variant="h5"
      >
        Fall hinzufügen
      </Typography>
      <CaseRegistrationForm
        noValidate
        onSubmit={handleSubmit((values) => {
          const userIdDefined = user?.PK
          const myAppointments = values.appointments ?? []
          const appointmentsWithInstructionID = myAppointments.map(
            (appointment) => ({
              ...appointment,
              instructionID, // Add instructionID from context
            }),
          )
          // we need to sort the entered appointments since the user might not have
          // entered them in the sequence of their dates:″
          const sortedAppointments = sortAndRenameAppointments(
            appointmentsWithInstructionID,
          )
          const caseData = {
            PK: patientInfo?.PK,
            counterCase: Number(patientInfo?.CounterCase),
            counterAppointment: Number(patientInfo?.CounterAppointment),
            counterNote: Number(patientInfo?.CounterNote),
            relevantClinic: patientInfo?.ClinicAccess,
            patientName: patientInfo?.PatientName,
            userIdDefined,
            userIdSurgeon: extractElementNumber('USER', values.doctor),
            intervention: values.intervention,
            interventionDate: values.interventionDate,
            notes: values.notes.text,
            noteType: values.notes.type,
            additionalTreatment: values.additionalTreatment,
            appointments: sortedAppointments,
          }
          mutate(caseData)
        })}
      >
        {settings && (
          <FormSelect
            data={settings}
            itemValue={'Name'}
            itemLabel={'Name'}
            placeholder="Eingriff"
            inputRef={interventionRef}
            errorMessage={errors.intervention?.message}
            inputProps={interventionProps}
            isError={!!errors.intervention}
            label="Bitte Eingriff auswählen"
          />
        )}
        <FormDatePicker
          control={control}
          name="interventionDate"
          label="Wann wurde der Eingriff vorgenommen?"
        />
        {doctors && (
          <FormSelect
            data={doctors}
            itemValue={'SK'}
            itemLabel={'UserName'}
            placeholder="Arzt"
            inputRef={doctorRef}
            errorMessage={errors.doctor?.message}
            inputProps={doctorProps}
            isError={!!errors.doctor}
            label="Wählen Sie den behandelnden Arzt"
          />
        )}
        <FormRadioGroup
          control={control}
          items={[
            { value: 'Ja', label: 'Ja' },
            { value: 'Nein', label: 'Nein' },
          ]}
          label=" Wurden weitere Behandlungen durchgeführt?"
          name="additionalTreatment"
        />
        <FormNotes control={control} />
        <FormAppointmentsList
          control={control}
          interventionName={interventionValue}
          fields={fields}
          append={append}
          remove={remove}
        />
        <Button
          disabled={isPending}
          startIcon={<SendIcon />}
          type="submit"
          variant="contained"
        >
          Absenden
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPending}
          title="Daten werden gespeichert ..."
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {postPatientError && (
          <FormHelperText error={!!postPatientError}>
            {'Fehler beim speichern der Patienten Daten:' +
              postPatientError?.message}
          </FormHelperText>
        )}
      </CaseRegistrationForm>
    </Frame>
  )
}

export default AddCasePage

