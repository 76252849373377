import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import VideoDisplay from '../components/VideoDisplay/VideoDisplay'

const handlePatientMessage = (
  message: string,
  imageLocation: string,
  handleVideoClick: (videoUrl: string) => void,
): any[] => {
  const messages = message.split(' || ')
  const resultString: any[] = [] // Moved out to encompass both conditions

  if (messages.length > 1) {
    messages.forEach((QaA) => {
      const QaAArray = QaA.split('/')
      if (QaAArray.length > 1) {
        resultString.push(
          <Box
            sx={{
              margin: 1,
              marginLeft: 0,
              padding: 1,
              backgroundColor: 'white',
              overflow: 'hidden',
              borderLeft: '5px solid #6082f0',
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px', // Typo fixed from "Botton" to "Bottom"
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: '1rem', fontWeight: 'bold' }}
            >
              {QaAArray[0]}
            </Typography>
            <Divider sx={{ marginTop: '5px', marginBottom: '5px' }} />
            <Typography
              variant="body2"
              sx={{ fontSize: '0.75rem' }}
            >
              {QaAArray[1]}
            </Typography>
          </Box>,
        )
      }
    })

    // Check imageLocation after processing all Q&A pairs
    if (imageLocation !== '') {
      resultString.push(
        <VideoDisplay
          handleVideoClick={handleVideoClick}
          imageLocation={imageLocation}
        />,
      )
    }
  } else {
    resultString.push(
      <Typography
        variant="body2"
        sx={{ fontSize: '0.75rem' }}
      >
        {messages}
      </Typography>,
    )
  }

  return resultString
}

export default handlePatientMessage
