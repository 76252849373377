import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, Button, IconButton, SvgIcon, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { generateClient, GraphQLQuery } from 'aws-amplify/api'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppContext } from '../App'
import { createMain, updateMain } from '../graphql/mutations'
import {
  createChatMessage,
  createTemplateQuery,
  deleteTemplateQuery,
  getCaseDetails,
  getPatientsChatMessages,
  getTemplates,
  updateTemplateQuery,
} from '../graphql-queries/customQueriesGraphQL'
import {
  ChatMessage,
  CreateChatMessage,
  CreateClearingList,
  GraphQlResponse,
  GraphQlResponseList,
  templateType,
} from '../graphql-queries/types'
import { updateMainTable } from '../updateMainTable'
import extractElementNumber from '../utils/extractElementNumber'
import { generateSortKey, generateSortKey2 } from '../utils/sortKeyHelpers'
import ChatInput from './ChatInput'
import Frame from './Frame'
import { fetchPatientsDetails } from './PatientPanel'
import { TemplateSettings } from './TemplateSettings/TemplateSettings'
import VideoModalDialog from './VideoModalDialog/VideoModalDialog'
import ChatMessagesList from './ChatMessagesList/ChatMessagesList'
import sendEventToEventBridge from '../utils/sendEventToEventBridge'
import closeToDoListRecordsByPatient from '../closeToDoListRecordsByPatient'

const postClearingList = async (
  partitionKey: string,
  sortKey: string,
  input: CreateClearingList,
) => {
  const client = generateClient()

  await client.graphql<GraphQLQuery<any>>({
    query: createMain,
    variables: {
      input,
    },
  })
}

const updateLastCommunicationFieldEverywhere = async (
  partitionKey: string,
  sortKey: string,
  appointmentKey: string,
  lastCommunication: string,
) => {
  const client = generateClient()

  const SK = generateSortKey('CASE', sortKey)

  // update LastCommunication for Patient
  await client.graphql({
    query: updateMain,
    variables: {
      input: {
        PK: partitionKey,
        SK: 'PAT#',
        LastCommunication: lastCommunication,
      },
    },
  })

  // update LastCommunication for Case
  await client.graphql({
    query: updateMain,
    variables: {
      input: {
        PK: partitionKey,
        SK,
        LastCommunication: lastCommunication,
      },
    },
  })

  // update LastCommunication for Appointment
  const activeCase = extractElementNumber('CASE', SK)
  const appointmentSK = generateSortKey2(
    'CASE',
    activeCase,
    'APP',
    appointmentKey,
  )
  await client.graphql({
    query: updateMain,
    variables: {
      input: {
        PK: partitionKey,
        SK: appointmentSK,
        LastCommunication: lastCommunication,
      },
    },
  })
}

const createTemplate = async (input: templateType) => {
  const client = generateClient()
  await client.graphql({
    query: createTemplateQuery,
    variables: { input },
  })
}

const updateTemplate = async (input: templateType) => {
  const client = generateClient()
  await client.graphql({
    query: updateTemplateQuery,
    variables: { input },
  })
}

const deleteTemplate = async (input: templateType) => {
  const client = generateClient()
  await client.graphql({
    query: deleteTemplateQuery,
    variables: { input },
  })
}

export const fetchPatientsChatMessages = async (
  partitionKey: string,
  sortKey: string,
) => {
  const client = generateClient()
  const response = await client.graphql({
    query: getPatientsChatMessages,
    variables: {
      limit: 1000000,
      filter: {
        and: [
          { PK: { eq: partitionKey } },
          { SK: { beginsWith: generateSortKey('CASE', sortKey, 'APP') } },
          { SK: { contains: 'CHAT' } },
        ],
      },
    },
  })
  return (response as GraphQlResponseList<ChatMessage>).data.listMains.items
}

export const fetchCase = async (partitionKey: string, sortKey: string) => {
  const SK = generateSortKey('CASE', sortKey)
  const client = generateClient()
  const response = await client.graphql({
    query: getCaseDetails,
    variables: {
      PK: partitionKey,
      SK,
    },
  })

  return (
    response as GraphQlResponse<{
      Intervention: string
      LastCommunication: string
    }>
  ).data.getMain
}

const fetchTemplates = async (
  partitionKey: string | undefined,
  user: string | undefined,
) => {
  if (!partitionKey || !user) {
    throw new Error('User is absent')
  }
  const client = generateClient()
  const response = await client.graphql({
    query: getTemplates,
    variables: {
      limit: 1000000,
      filter: {
        PK: { eq: partitionKey },
        SK: { beginsWith: 'TYP#Template#', notContains: 'USER' },
      },
    },
  })
  return (
    response as GraphQlResponseList<{
      PK: string
      SK: string
      UserIdDefined: string
      ClinicAccess: string
      Text: string
    }>
  ).data.listMains.items
}

const fetchFrequentTemplates = async (
  partitionKey: string | undefined,
  user: string | undefined,
) => {
  if (!partitionKey || !user) {
    throw new Error('User is absent')
  }
  const client = generateClient()
  const response = await client.graphql({
    query: getTemplates,
    variables: {
      limit: 1000000,
      filter: {
        PK: { eq: partitionKey },
        SK: { beginsWith: `TYP#Template#USER#${user}` },
      },
    },
  })
  return (
    response as GraphQlResponseList<{
      PK: string
      SK: string
      UserIdDefined: string
      ClinicAccess: string
      Text: string
    }>
  ).data.listMains.items
}

type ChatMessagesProps = {
  latestAppointment: string
}

export type DisplaiyngTemplateType = {
  template: templateType
  favorit: templateType | undefined
  display: boolean
}

const ChatMessages = ({ latestAppointment }: ChatMessagesProps) => {
  // State for controlling the video dialog visibility and current video URL
  const { partitionKey, sortKey } = useParams()
  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(0)
  const [currentVideoUrl, setCurrentVideoUrl] = useState('')
  const [buttonTemplate, setButtonTemplate] = useState<
    'Vorlagen' | 'KI' | undefined
  >(undefined)
  const [chatMessage, setChatMessage] = useState('')
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false)
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const { user } = useContext(AppContext)
  const [displayingTemplates, setDisplayingTemplates] = useState<
    DisplaiyngTemplateType[]
  >([])
  const [start, setStart] = useState(true)

  // Now use this type to set the initial state

  const handleDisplayingTemplates = () => {
    const newDisplayingTemplates: DisplaiyngTemplateType[] = []
    if (templates?.length !== 0 && templates && user) {
      for (let i = 0; i < templates?.length; i++) {
        newDisplayingTemplates.push({
          template: templates[i],
          favorit: undefined,
          display: true,
        })
      }
      if (frequentTemplates) {
        for (let i = 0; i < frequentTemplates?.length; i++) {
          const templateId = frequentTemplates[i].SK.split('#CODE#')[1]
          let added = false
          for (let j = 0; j < newDisplayingTemplates?.length; j++) {
            if (newDisplayingTemplates[j].template.SK.includes(templateId)) {
              added = true
              newDisplayingTemplates[j].favorit = frequentTemplates[i]
              break
            }
          }
          if (!added) {
            newDisplayingTemplates.push({
              template: frequentTemplates[i],
              favorit: frequentTemplates[i],
              display: true,
            })
          }
        }
      }
    }
    setDisplayingTemplates(newDisplayingTemplates)
  }

  if (!sortKey) {
    throw new Error('Sort Key is missing in URL!')
  }

  if (!partitionKey) {
    throw new Error('Partition Key is missing in URL!')
  }

  const {
    data: chatMessages,
    isLoading,
    isError,
    isSuccess,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['patients', partitionKey, 'cases', sortKey, 'chatMessages'],
    queryFn: () => fetchPatientsChatMessages(partitionKey, sortKey),
  })

  const { data: templates, refetch: refetchTemplates } = useQuery({
    queryKey: ['clinics', user?.Clinic],
    queryFn: () => fetchTemplates(user?.Clinic, user?.PK),
  })

  const [templatesTexts, setTemplatesTexts] = useState<string[]>([])

  const { data: frequentTemplates, refetch: refetchFrequentTemplates } =
    useQuery({
      queryKey: ['clinics', user?.Clinic, 'frequent'],
      queryFn: () => fetchFrequentTemplates(user?.Clinic, user?.PK),
    })

  if (templates?.length !== 0 && frequentTemplates && start) {
    setStart(false)
    const texts: string[] = []
    for (let i = 0; i < frequentTemplates?.length; i++) {
      texts.push(frequentTemplates[i].Text)
    }
    setTemplatesTexts(texts)
  }

  const { data: caseDetails } = useQuery({
    queryKey: ['caseDetails', partitionKey, 'cases', sortKey],
    queryFn: () => fetchCase(partitionKey, sortKey),
  })

  const { data: patientInfo } = useQuery({
    queryKey: ['patients', partitionKey, 'details'],
    queryFn: () =>
      fetchPatientsDetails(partitionKey ?? 'missing partition key'),
  })
  const counterChatValue = patientInfo?.CounterChat

  const handleSendPushNotification = () => {
    const patientID = partitionKey
    const messageText = 'This is the message text'
//    const deviceToken = patientInfo?.DeviceToken ?? 'no deviceToken - no push notification'

      sendEventToEventBridge("case.chat.updated", "case", patientID, "", messageText)
      .then((response) => {
        console.log('$$$$$$$$$$ in someFunction / sendEventToEventBridge / Success:', response);
      })
      .catch((error) => {
        console.error('€€€€€€€€€€ in someFunction / sendEventToEventBridge / Send event error:', error);
      });
  }

  const postChatMessage = async (input: CreateChatMessage) => {
    const client = generateClient()

    await client.graphql({
      query: createChatMessage,
      variables: { input },
    })
    console.log('$$$$$$$$$$ before call of closeToDoListRecordsByPatient');
    closeToDoListRecordsByPatient(user?.Clinic!,partitionKey,user?.PK!)
    console.log('$$$$$$$$$$ after call of closeToDoListRecordsByPatient');
    handleSendPushNotification()
  }

  const { mutate } = useMutation({
    mutationFn: (data: CreateChatMessage) => postChatMessage(data),
    onSuccess: async (data, variables, context) => {
      const appointmentKey = extractElementNumber('APP', variables.SK)
      if (
        caseDetails?.LastCommunication === 'not defined' ||
        caseDetails?.LastCommunication === null
      ) {
        await postClearingList(partitionKey, sortKey, {
          PK: user?.Clinic!,
          SK: `TYP#ClearingList#STS#offen#CASE#${sortKey}#PAT#${partitionKey}##`,
          ClearingStatus: 'offen',
          ClinicAccess: user?.Clinic!,
          InsuranceType: patientInfo?.InsuranceType!,
          Intervention: caseDetails?.Intervention!,
          PatientName: patientInfo?.PatientName!,
        })
      }

      await updateMainTable(
        partitionKey,
        'PAT#',
        'CounterChat',
        String(Number(counterChatValue) + count),
      )
      await refetch()
      await updateLastCommunicationFieldEverywhere(
        partitionKey,
        sortKey,
        appointmentKey,
        new Date().toISOString(),
      )
    },
  })

  const { mutate: mutateFavorit } = useMutation({
    mutationFn: (data: any) => createTemplate(data),
    // onSuccess: async (data, variables, context) => {
    //   await refetchFrequentTemplates()
    //   await refetchTemplates()
    // }
  })

  const { mutate: mutateUpdateFavorit } = useMutation({
    mutationFn: (data: any) => updateTemplate(data),
    // onSuccess: async (data, variables, context) => {
    //   await refetchFrequentTemplates()
    //   await refetchTemplates()
    // }
  })

  const { mutate: mutateDeleteFavorit } = useMutation({
    mutationFn: (data: any) => deleteTemplate(data),
    onSuccess: async (data, variables, context) => {
      if (frequentTemplates) {
        if (frequentTemplates.length === 0) {
          templatesTexts.splice(0, templatesTexts.length)
        }
      }

      // await refetchFrequentTemplates()
      // await refetchTemplates()
    },
  })

  const handleVideoIconClick = (videoUrl: string) => {
    setCurrentVideoUrl(videoUrl)
    setOpen(true)
  }

  // Handler for closing the video dialog
  const handleCloseVideoDialog = () => {
    setOpen(false)
  }

  const createNewChatMessage = (newChatMessage: string) => {
    setCount(count + 1)
    mutate({
      PK: partitionKey,
      SK: `CASE#${sortKey}#APP#${extractElementNumber(
        'APP',
        latestAppointment,
      )}#CHAT#${String(Number(counterChatValue) + count)}##`,
      ClearingID: 'not applicable',
      ClearingStatus: 'not applicable',
      Date: dayjs().format('YYYY-MM-DD'),
      DateTime: new Date().toISOString(),
      Message: newChatMessage,
      UserName: user?.UserName!,
      Name: user?.Title!,
      PatientName: 'not applicable',
      Sender: 'Arzt',
      Intervention: 'Intervention',
      ClinicAccess: user?.Clinic!,
    })
  }

  useEffect(() => {
    // This will manually set the scrollTop to the bottom of the chat container
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight
      chatContainerRef.current.scrollTop = scrollHeight
    }
  }, [isSuccess, isRefetching])

  const sortedChatMessages = chatMessages?.sort((a, b) =>
    a.DateTime.localeCompare(b.DateTime),
  )

  const handleVorlage = (message: ChatMessage) => {
    if (user && !templatesTexts.includes(message.Message) && templates) {
      const template: templateType = {
        PK: user.Clinic,
        SK: `TYP#Template#CODE#${crypto.randomUUID()}#`,
        Text: message.Message,
        UserIdDefined: user.PK,
        ClinicAccess: user.Clinic,
      }
      templates.push(template)
      const favoritTemplate: templateType = {
        PK: user.Clinic,
        SK: template.SK.replace('Template#', `Template#USER#${user.PK}#`),
        Text: message.Message,
        UserIdDefined: user.PK,
        ClinicAccess: user.Clinic,
      }
      if (frequentTemplates) frequentTemplates.push(favoritTemplate)
      mutateFavorit(template)
      mutateFavorit(favoritTemplate)
      templatesTexts.push(message.Message)
    } else if (templatesTexts.includes(message.Message) && templates && user) {
      for (let i = 0; i < templates?.length; i++) {
        if (templates[i].Text === message.Message) {
          if (frequentTemplates) {
            for (let j = 0; j < frequentTemplates.length; j++) {
              if (
                frequentTemplates[j].SK.includes(
                  templates[i].SK.split('#CODE#')[1],
                ) &&
                frequentTemplates[j].Text === message.Message
              ) {
                mutateDeleteFavorit({
                  PK: frequentTemplates[j].PK,
                  SK: frequentTemplates[j].SK,
                })
                frequentTemplates.splice(j, 1)
              }
            }
          }
          if (templates[i].UserIdDefined === user.PK) {
            mutateDeleteFavorit({ PK: templates[i].PK, SK: templates[i].SK })
            templates.splice(i, 1)
            const index = templatesTexts.indexOf(message.Message)
            templatesTexts.splice(index, 1)
          }
        }
      }
    }
  }

  const createTemplateDirectly = (
    template: string,
    frequent: 'HAVE' | 'NO',
  ) => {
    if (frequent === 'NO' && templates && user) {
      const newTemplate: templateType = {
        PK: user.Clinic,
        SK: `TYP#Template#CODE#${crypto.randomUUID()}#`,
        Text: template,
        UserIdDefined: user.PK,
        ClinicAccess: user.Clinic,
      }
      templates.push(newTemplate)
      mutateFavorit(newTemplate)
      displayingTemplates.push({
        template: newTemplate,
        favorit: undefined,
        display: true,
      })
    } else if (templates && frequentTemplates && user && frequent === 'HAVE') {
      const id = crypto.randomUUID()
      const newTemplate: templateType = {
        PK: user.Clinic,
        SK: `TYP#Template#CODE#${id}#`,
        Text: template,
        UserIdDefined: user.PK,
        ClinicAccess: user.Clinic,
      }
      const frequentTemplate = {
        PK: user.Clinic,
        SK: `TYP#Template#USER#${user.PK}#CODE#${id}#`,
        Text: template,
        UserIdDefined: user.PK,
        ClinicAccess: user.Clinic,
      }
      templatesTexts.push(frequentTemplate.Text)
      templates.push(newTemplate)
      frequentTemplates.push(frequentTemplate)
      mutateFavorit(newTemplate)
      mutateFavorit(frequentTemplate)
      displayingTemplates.push({
        template: newTemplate,
        favorit: frequentTemplate,
        display: true,
      })
    }
  }

  const pinTemplate = (template: DisplaiyngTemplateType) => {
    if (frequentTemplates && user) {
      const frequentTemplate: templateType = {
        PK: template.template.PK,
        SK: template.template.SK.replace(
          'Template',
          `Template#USER#${user.PK}`,
        ),
        UserIdDefined: template.template.UserIdDefined,
        ClinicAccess: template.template.ClinicAccess,
        Text: template.template.Text,
      }
      mutateFavorit(frequentTemplate)
      templatesTexts.push(frequentTemplate.Text)
      template.favorit = frequentTemplate
      frequentTemplates.push(frequentTemplate)
    }
  }

  const deleteTemplateDirectly = (
    template: templateType,
    haveFavorit: 'IS' | 'HAVE' | 'NO',
  ) => {
    if (haveFavorit === 'IS' && frequentTemplates) {
      for (let i = 0; i < frequentTemplates.length; i++) {
        if (template.SK === frequentTemplates[i].SK) {
          const index = templatesTexts.indexOf(frequentTemplates[i].Text)
          templatesTexts.splice(index, 1)
          mutateDeleteFavorit({ PK: template.PK, SK: template.SK })
          frequentTemplates.splice(i, 1)
        }
      }
      for (let i = 0; i < displayingTemplates.length; i++) {
        if (template.SK === displayingTemplates[i].favorit?.SK) {
          displayingTemplates[i].favorit = undefined
        }
      }
    } else if (
      haveFavorit === 'HAVE' &&
      templates &&
      frequentTemplates &&
      user
    ) {
      const sk = template.SK.replace('Template', `Template#USER#${user.PK}`)
      for (let i = 0; i < frequentTemplates.length; i++) {
        if (sk === frequentTemplates[i].SK) {
          const index = templatesTexts.indexOf(frequentTemplates[i].Text)
          templatesTexts.splice(index, 1)
          mutateDeleteFavorit({
            PK: frequentTemplates[i].PK,
            SK: frequentTemplates[i].SK,
          })
          frequentTemplates.splice(i, 1)
        }
      }
      for (let i = 0; i < templates.length; i++) {
        if (template.SK === templates[i].SK) {
          mutateDeleteFavorit({ PK: template.PK, SK: template.SK })
          templates.splice(i, 1)
        }
      }
      for (let i = 0; i < displayingTemplates.length; i++) {
        if (template.SK === displayingTemplates[i].template.SK) {
          displayingTemplates.splice(i, 1)
        }
      }
    } else if (haveFavorit === 'NO' && templates) {
      for (let i = 0; i < templates.length; i++) {
        if (template.SK === templates[i].SK) {
          templates.splice(i, 1)
          mutateDeleteFavorit({ PK: template.PK, SK: template.SK })
        }
      }
      for (let i = 0; i < displayingTemplates.length; i++) {
        if (template.SK === displayingTemplates[i].template.SK) {
          displayingTemplates.splice(i, 1)
        }
      }
    }
  }

  const updateTemplateDirectly = (
    template: DisplaiyngTemplateType,
    newText: string,
  ) => {
    if (template.favorit && frequentTemplates) {
      for (let i = 0; i < frequentTemplates.length; i++) {
        if (frequentTemplates[i].SK === template.favorit.SK) {
          frequentTemplates[i].Text = newText
          mutateUpdateFavorit(frequentTemplates[i])
        }
      }
    }
    if (templates) {
      for (let i = 0; i < templates.length; i++) {
        if (templates[i].SK === template.template.SK) {
          templates[i].SK = newText
          mutateUpdateFavorit(templates[i])
        }
      }
    }
  }

  const handleOpenTemplateDialog = (): void => {
    handleDisplayingTemplates()
    setOpenTemplateDialog(true)
  }

  const TemplateSectionAboveChatInputLine = () => {
    return (
      <Box sx={{ maxWidth: '100%', marginRight: '24px', display: 'flex' }}>
        <Button
          sx={{
            fontSize: '14px',
            textTransform: 'none',
            color:
              buttonTemplate === 'Vorlagen' ? 'text.primary' : 'text.secondary',
            borderBottom:
              buttonTemplate === 'Vorlagen'
                ? '2px solid black'
                : '2px solid #EAEDF1',
            borderRadius: '0px',
          }}
          onClick={() => {
            if (buttonTemplate !== 'Vorlagen') setButtonTemplate('Vorlagen')
            else setButtonTemplate(undefined)
          }}
        >
          <SvgIcon
            sx={{
              display: 'flex',
              width: '16px',
              height: '16px',
              marginRight: '5px',
            }}
            strokeWidth={1}
            stroke="currentColor"
          >
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.3538 3.14625L8.85375 0.64625C8.80728 0.599833 8.75212 0.563026 8.69143 0.537932C8.63073 0.512838 8.56568 0.499949 8.5 0.5H3.5C3.23478 0.5 2.98043 0.605357 2.79289 0.792893C2.60536 0.98043 2.5 1.23478 2.5 1.5V2.5H1.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H8.5C8.76522 13.5 9.01957 13.3946 9.20711 13.2071C9.39464 13.0196 9.5 12.7652 9.5 12.5V11.5H10.5C10.7652 11.5 11.0196 11.3946 11.2071 11.2071C11.3946 11.0196 11.5 10.7652 11.5 10.5V3.5C11.5001 3.43432 11.4872 3.36927 11.4621 3.30858C11.437 3.24788 11.4002 3.19272 11.3538 3.14625ZM8.5 12.5H1.5V3.5H6.29313L8.5 5.70687V10.99C8.5 10.9937 8.5 10.9969 8.5 11C8.5 11.0031 8.5 11.0063 8.5 11.01V12.5ZM10.5 10.5H9.5V5.5C9.50005 5.43432 9.48716 5.36927 9.46207 5.30858C9.43697 5.24788 9.40017 5.19272 9.35375 5.14625L6.85375 2.64625C6.80728 2.59983 6.75212 2.56303 6.69143 2.53793C6.63073 2.51284 6.56568 2.49995 6.5 2.5H3.5V1.5H8.29313L10.5 3.70688V10.5ZM7 8.5C7 8.63261 6.94732 8.75979 6.85355 8.85355C6.75979 8.94732 6.63261 9 6.5 9H3.5C3.36739 9 3.24021 8.94732 3.14645 8.85355C3.05268 8.75979 3 8.63261 3 8.5C3 8.36739 3.05268 8.24021 3.14645 8.14645C3.24021 8.05268 3.36739 8 3.5 8H6.5C6.63261 8 6.75979 8.05268 6.85355 8.14645C6.94732 8.24021 7 8.36739 7 8.5ZM7 10.5C7 10.6326 6.94732 10.7598 6.85355 10.8536C6.75979 10.9473 6.63261 11 6.5 11H3.5C3.36739 11 3.24021 10.9473 3.14645 10.8536C3.05268 10.7598 3 10.6326 3 10.5C3 10.3674 3.05268 10.2402 3.14645 10.1464C3.24021 10.0527 3.36739 10 3.5 10H6.5C6.63261 10 6.75979 10.0527 6.85355 10.1464C6.94732 10.2402 7 10.3674 7 10.5Z" />
            </svg>
          </SvgIcon>
          Vorlagen
        </Button>
        <Box
          sx={{
            borderBottom: '2px solid #EAEDF1',
            width: '100%',
            height: '39.5px',
          }}
        ></Box>
        <Box
          sx={{
            borderBottom: '2px solid #EAEDF1',
            height: '39.5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{
              background: 'black',
              height: '24px',
              width: '24px',
              ':hover': {
                color: 'black',
                background: 'white',
                border: '2px solid black',
              },
            }}
            onClick={handleOpenTemplateDialog}
          >
            <FilterListIcon
              sx={{
                color: 'white',
                height: '12px',
                width: '12px',
                margin: '12px',
                ':hover': { color: 'black', background: 'white' },
              }}
              stroke="currentColor"
            />
          </IconButton>
        </Box>
      </Box>
    )
  }

  const TemplatesDisplay = () => {
    return (
      <Box
        sx={{
          maxWidth: '100%',
          maxHeight: '100px',
          padding: '10px',
          overflow: 'auto',
          overflowX: 'hidden',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {frequentTemplates?.map((template) => {
            return (
              <Box
                key={template.SK}
                sx={{
                  color: '#1045f6',
                  background: '#e8ecfc',
                  borderRadius: '15px',
                  cursor: 'pointer',
                  display:
                    buttonTemplate === 'Vorlagen' &&
                    template.SK.includes(`${user?.PK}`)
                      ? 'flex'
                      : 'none',
                  alignContent: 'center',
                  margin: '3px',
                  width: 'auto',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  align="left"
                  sx={{
                    padding: '10px',
                    lineHeight: '21px',
                    fontSize: '14px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    gap: '7px',
                  }}
                  onClick={() => {
                    setChatMessage(template.Text)
                  }}
                  key={template.SK}
                >
                  {template.Text}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }

  return (
    <Frame
      isError={isError}
      isLoading={isLoading}
      entity="Chat Nachrichten"
      noData={chatMessages?.length === 0}
    >
      <TemplateSettings
        pinFunc={pinTemplate}
        templatesText={templatesTexts}
        open={openTemplateDialog}
        openState={setOpenTemplateDialog}
        displayingTemplates={displayingTemplates}
        createMutation={createTemplateDirectly}
        deleteMutation={deleteTemplateDirectly}
        user={user}
        updateMutation={updateTemplateDirectly}
      />

      <Box sx={{ width: '100%' }}>
        <ChatMessagesList
          handleVorlage={handleVorlage}
          messages={sortedChatMessages}
          chatRef={chatContainerRef}
          templatesTexts={templatesTexts}
          handleVideoClick={handleVideoIconClick}
        />

        <TemplateSectionAboveChatInputLine />
        <TemplatesDisplay />

        <ChatInput
          onSendChatMessage={createNewChatMessage}
          chatMessage={chatMessage}
          setChatMessage={setChatMessage}
        />
      </Box>

      <VideoModalDialog
        currentVideoUrl={currentVideoUrl}
        handleClose={handleCloseVideoDialog}
        open={open}
      />
    </Frame>
  )
}

export default ChatMessages
