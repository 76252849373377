// convertToDateOrUseDefault.js

const convertToDateOrUseDefault = (dateString) => {
  const defaultDate = new Date("2011-11-11T11:11:00.000Z");
  if (!dateString) return defaultDate;

  const date = new Date(dateString);
  return isNaN(date.getTime()) ? defaultDate : date;
  };
  
  export default convertToDateOrUseDefault;
  