import { signOut } from "aws-amplify/auth";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import { AppContext } from "../App";
import AppLayout from "./AppLayout";

const ProtectedRoute: React.FC = () => {
  const { user } = useContext(AppContext);

  if (!user) {
    console.log("$$$$$$$$$  in ProtectedRoute / before signout");
    signOut()
    return;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default ProtectedRoute;
