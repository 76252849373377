import React from 'react'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { ChatMessage } from '../../graphql-queries/types'

type ChatMessageItemProps = {
  message: ChatMessage
}

interface ITemplateSectionUnderEachChatPanel extends ChatMessageItemProps {
  templatesTexts: string[]
  handleVorlage: (message: ChatMessage) => void
}

const TemplateSectionUnderEachChatPanel: React.FC<
  ITemplateSectionUnderEachChatPanel
> = ({ message, templatesTexts, handleVorlage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        width: '100%',
        paddingRight: '10px',
      }}
    >
      <Button
        sx={{
          fontSize: '12px',
          color: templatesTexts.includes(message.Message) ? 'black' : '#5F6572',
          display: message.Sender === 'Patient' ? 'none' : 'flex',
          padding: 0,
          lineHeight: '18px',
          textTransform: 'none',
          alignContent: 'center',
        }}
        onClick={() => handleVorlage(message)}
      >
        <SvgIcon
          sx={{
            display: 'flex',
            width: '14px',
            height: '14px',
            marginRight: '3px',
          }}
        >
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1}
            stroke="currentColor"
          >
            <path
              d="M11.3538 3.14625L8.85375 0.64625C8.80728 0.599833 8.75212 0.563026 8.69143 0.537932C8.63073 0.512838 8.56568 0.499949 8.5 0.5H3.5C3.23478 0.5 2.98043 0.605357 2.79289 0.792893C2.60536 0.98043 2.5 1.23478 2.5 1.5V2.5H1.5C1.23478 2.5 0.98043 2.60536 0.792893 2.79289C0.605357 2.98043 0.5 3.23478 0.5 3.5V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H8.5C8.76522 13.5 9.01957 13.3946 9.20711 13.2071C9.39464 13.0196 9.5 12.7652 9.5 12.5V11.5H10.5C10.7652 11.5 11.0196 11.3946 11.2071 11.2071C11.3946 11.0196 11.5 10.7652 11.5 10.5V3.5C11.5001 3.43432 11.4872 3.36927 11.4621 3.30858C11.437 3.24788 11.4002 3.19272 11.3538 3.14625ZM8.5 12.5H1.5V3.5H6.29313L8.5 5.70687V10.99C8.5 10.9937 8.5 10.9969 8.5 11C8.5 11.0031 8.5 11.0063 8.5 11.01V12.5ZM10.5 10.5H9.5V5.5C9.50005 5.43432 9.48716 5.36927 9.46207 5.30858C9.43697 5.24788 9.40017 5.19272 9.35375 5.14625L6.85375 2.64625C6.80728 2.59983 6.75212 2.56303 6.69143 2.53793C6.63073 2.51284 6.56568 2.49995 6.5 2.5H3.5V1.5H8.29313L10.5 3.70688V10.5ZM7 8.5C7 8.63261 6.94732 8.75979 6.85355 8.85355C6.75979 8.94732 6.63261 9 6.5 9H3.5C3.36739 9 3.24021 8.94732 3.14645 8.85355C3.05268 8.75979 3 8.63261 3 8.5C3 8.36739 3.05268 8.24021 3.14645 8.14645C3.24021 8.05268 3.36739 8 3.5 8H6.5C6.63261 8 6.75979 8.05268 6.85355 8.14645C6.94732 8.24021 7 8.36739 7 8.5ZM7 10.5C7 10.6326 6.94732 10.7598 6.85355 10.8536C6.75979 10.9473 6.63261 11 6.5 11H3.5C3.36739 11 3.24021 10.9473 3.14645 10.8536C3.05268 10.7598 3 10.6326 3 10.5C3 10.3674 3.05268 10.2402 3.14645 10.1464C3.24021 10.0527 3.36739 10 3.5 10H6.5C6.63261 10 6.75979 10.0527 6.85355 10.1464C6.94732 10.2402 7 10.3674 7 10.5Z"
              fill="#BCBCBC"
            />
          </svg>
        </SvgIcon>
        <Typography
          fontSize={'12px'}
          sx={{ height: '16px' }}
        >
          Als Vorlage hinzufügen
        </Typography>
      </Button>
    </Box>
  )
}

export default TemplateSectionUnderEachChatPanel
