import { createMain as createMainMutation } from "./graphql/mutations";
import { createAppLaunchCodes as createAppLaunchCodesMutation } from "./graphql/mutations";
import { postCase } from "./postCase";
import { generateClient } from "aws-amplify/api";
import { generateRandomString } from "./utils/randomStringGenerator.js";
import sendEventToEventBridge from './utils/sendEventToEventBridge'

/* 
This function gets data for an entire set:
  patient master data
  notes (optionally)
  intervention
  appointments

On this level, it will execute the posting of the patient master data 
as well as the posting into the table AppLaunchCodes and then call 
  postCase by passing the intervention data, notes data and the appointments
postIntervention will then again call
    postAppointments and postAppointments will call postSingleAppointment

In this way, we can use these modules independently.

As the patient's PK, we use a uuid
*/




export async function postPatient(patientData) {
  console.log(`PpPpPpPpPpPp postPatient start patientData: `, patientData);

  const handleSendAccessCodeEmail = (patientID, appLaunchCode) => {
   
    console.log('$$$$$$$$$$$ in postPatient / sendAccessCodeEmail / patientID:', patientID);
    console.log('$$$$$$$$$$$ in postPatient / sendAccessCodeEmail / appLaunchCode:', appLaunchCode);

      sendEventToEventBridge("case.accessCode.prepared", "case", patientID, appLaunchCode, "")
      .then((response) => {
        console.log('$$$$$$$$$$ in someFunction / sendEventToEventBridge / Success:', response);
      })
      .catch((error) => {
        console.error('€€€€€€€€€€ in someFunction / sendEventToEventBridge / Send event error:', error);
      });
  };


  const myCounterCase = 1000;
  const myCounterAppointment = 2000;
  const myCounterChat = 3000;
  const myCounterLog = 4000;
  const myCounterNote = 5000;
  const myAppLaunchCode = generateRandomString(6);
  const myPatientID = crypto.randomUUID();
  let insertData;
  try {
    const client = generateClient();
    insertData = {
      PK: myPatientID,
      SK: "PAT#",
      CounterAppointment: myCounterAppointment,
      CounterCase: myCounterCase,
      CounterChat: myCounterChat,
      CounterLog: myCounterLog,
      CounterNote: myCounterNote,
      ClinicAccess: patientData.relevantClinic,
      EmailAddress: patientData.email,
      Phone: patientData.phoneNumber,
      PatientName: patientData.patientName,
      Name: patientData.name,
      Surname: patientData.surName,
      Salutation: patientData.salutation,
    };

    const response = await client.graphql({
      query: createMainMutation,
      variables: { input: insertData },
    });

    /*
Now we registere the appLaunchCode
*/  
    try {

      let validUntilDate = new Date();
      validUntilDate.setDate(validUntilDate.getDate() + 7);
      let validUntilDateString = validUntilDate.toISOString().split('T')[0];

      const client = generateClient();
      insertData = {
        PK: myAppLaunchCode,
        SK: "CODE#",
        PatientID: myPatientID,
        PatientName: patientData.patientName,
        Salutation: patientData.salutation,
        ValidUntil: validUntilDateString,
        Utilized: ""
      };

      const response = await client.graphql({
        query: createAppLaunchCodesMutation,
        variables: { input: insertData },
      });
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error(
        "There was an error registering the app launch code",
        error
      );
      throw error;
    }

    /*
Now we can post the intervention. For this purpose we prepare the structure for the 
parameter handover. It is a sub-set of patientData
*/
    const caseData = {
      PK: myPatientID,
      counterCase: myCounterCase,
      counterAppointment: myCounterAppointment,
      counterNote: myCounterNote,
      relevantClinic: patientData.relevantClinic,
      userIdDefined: patientData.userIdDefined,
      userIdSurgeon: patientData.userIdSurgeon,
      intervention: patientData.intervention,
      interventionDate: patientData.interventionDate,
      notes: patientData.notes,
      noteType: patientData.noteType,
      additionalTreatment: patientData.additionalTreatment,
      appointments: patientData.appointments,
      patientName: patientData.patientName,
    };
    console.log("PpPpPpPpPpPp patientData:", patientData);
    console.log("PpPpPpPpPpPp caseData:", caseData);
    try {
      await postCase(caseData);
      // Handle successful submission, e.g., clearing the form, showing a message, etc.
      handleSendAccessCodeEmail(myPatientID, myAppLaunchCode);
    
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("There was an error posting the case data", error);
      throw error;
    }
  } catch (error) {
    console.error(
      "PpPpPpPpPpPp in createMainTable / Error inserting Patient:",
      error
    );

    // Assuming 'error' is an object that contains an 'errors' array
    if (
      error.errors &&
      error.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException"
    ) {
      throw new Error("Patient already exists");
    }
    throw error; // Rethrow the original error if it's not the conditional check error
  }




}
