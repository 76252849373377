// extractElementNumber.js

function extractElementNumber(key, str) {
    const parts = str.split('#');
    const keyIndex = parts.indexOf(key);

    const number = keyIndex !== -1 && parts[keyIndex + 1] ? parts[keyIndex + 1] : null;
    return number;
}
export default extractElementNumber;
  