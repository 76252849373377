import { generateClient } from 'aws-amplify/api'
import { createMain as createMainMutation } from './graphql/mutations'
import { generateSortKey2 } from './utils/sortKeyHelpers'
import { updateMainTable } from './updateMainTable'

/* 
This function gets data for a set with :
  appointments

On this level, it will execute the posting of the  appointment data 
In this way, we can use these modules independently.

As the patient's PK, we use a uuid
*/

export async function postSingleAppointment(singleAppointmentData) {
  console.log(
    `PaPaPaPaPaPa postSingleAppointment start asingleAppointmentData: `,
    singleAppointmentData,
  )

  const appointmentSK = generateSortKey2(
    'CASE',
    String(singleAppointmentData.activeCase),
    'APP',
    String(singleAppointmentData.appointmentNumber),
  )
  // "2000"); // for crash test.
  console.log(
    'PaPaPaPaPaPa  postSingleAppointment  appointmentSK:',
    appointmentSK,
  )

  let insertData
  try {
    const client = generateClient()
    insertData = {
      PK: singleAppointmentData.pk,
      SK: appointmentSK,
      ClinicAccess: singleAppointmentData.relevantClinic,
      Date: singleAppointmentData.appointment.date,
      Name: singleAppointmentData.appointment.name,
      InstructionID: singleAppointmentData.appointment.instructionID,
      UserIdDefined: singleAppointmentData.UserIdDefined,
    }
    console.log('PaPaPaPaPaPa  insert fields insertData:', insertData)

    const response = await client.graphql({
      query: createMainMutation,
      variables: { input: insertData },
    })
    console.log(
      'PaPaPaPaPaPa in createMainTable inserted:',
      response.data.createMain,
    )

    /*
We update the patient master record with the counterCase
*/

    try {
      await updateMainTable(
        singleAppointmentData.pk,
        'PAT#',
        'CounterAppointment',
        singleAppointmentData.appointmentNumber,
      )
    } catch (error) {
      console.error(
        'There was an error updating the patient master record',
        error,
      )
      throw error
    }
  } catch (error) {
    console.error(
      'PaPaPaPaPaPa in createMainTable / Error inserting Appointment:',
      error,
    )

    // Assuming 'error' is an object that contains an 'errors' array
    if (
      error.errors &&
      error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
    ) {
      throw new Error('Appointment already exists')
    }
    throw error // Rethrow the original error if it's not the conditional check error
  }
}

