import { generateClient, GraphQLQuery } from 'aws-amplify/api'
import { updateMainTable } from './updateMainTable'
import { getPatientsAppointments } from './graphql-queries/customQueriesGraphQL'
import sortAndRenameAppointmentsWithKeys from './utils/sortAndRenameAppointmentsWithKeys'

interface ListMainsResponse {
  data: {
    listMains: {
      items: Array<{
        Date: string
        Name: string
        PK: string
        SK: string
      }>
    }
  }
}

/*
The purpose of this function is to adjust the names of the existing appointments
based on their date. The function is called after a user has deleted, added or
modified an appointment. Then a resequencing of the Name field is necessary and
this has to be updated in the database.
*/

const renameAppointments = async (PK: string, SK: string) => {
  const client = generateClient()

  const response = (await client.graphql({
    query: getPatientsAppointments,
    variables: {
      limit: 1000000,
      filter: {
        and: [
          { PK: { eq: PK } },
          { SK: { beginsWith: SK } },
          { SK: { notContains: 'CHAT' } },
          { SK: { notContains: 'INST' } },
        ],
      },
    },
  })) as ListMainsResponse

  if (response?.data?.listMains?.items) {
    const sortedAppointments = sortAndRenameAppointmentsWithKeys(
      response.data.listMains.items,
    )

    // Loop through sortedAppointments and call updateMainTable
    for (const appointment of sortedAppointments) {
      await updateMainTable(
        appointment.PK,
        appointment.SK,
        'Name',
        appointment.Name,
      )
    }
  } else {
    console.error('Unexpected response structure', response)
  }
}

export default renameAppointments

