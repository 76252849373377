import styled from '@emotion/styled'
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { generateClient } from 'aws-amplify/api'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../App'
import Frame from '../components/Frame'
import NoData from '../components/NoData'
import { dateFormat } from '../constants/date'
//import { getPatients } from "../graphql-queries/customQueriesGraphQL";
import { mainsByClinicAccessAndSK } from '../graphql/queries'
import {
  GraphQlByClinicAccessAndSKResponseList,
  GraphQlResponseList,
  Patient,
} from '../graphql-queries/types'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: '#2e7d32',
    opacity: 0.7,
  },
}))

const columns: GridColDef<Patient>[] = [
  {
    field: 'Surname',
    headerName: 'Name',
    minWidth: 400,
    renderCell: (params) => <span>{params.row.PatientName}</span>,
  },
  {
    field: 'Custom',
    sortable: false,
    headerName: 'Kontakt',
    minWidth: 400,
    renderCell: (params) => (
      <span>{params.row.EmailAddress || params.row.Phone || '-'}</span>
    ),
  },
  {
    field: 'isActive',
    sortable: false,
    headerName: 'Status',
    minWidth: 100,
    renderCell: (params) => (
      <span>
        {params.row.Platform ? (
          <Chip
            label="aktiv"
            color="success"
            variant="outlined"
          />
        ) : (
          <Chip
            label="inaktiv"
            color="error"
            variant="outlined"
          />
        )}
      </span>
    ),
  },
  {
    field: 'InsuranceType',
    headerName: 'Versicherungsstatus',
    minWidth: 200,
    renderCell: (params) => <span>{params.row.InsuranceType ?? '-'}</span>,
  },
  {
    field: 'LastCommunication',
    headerName: 'Letzte Nachricht',
    minWidth: 200,
    renderCell: (params) => (
      <span>
        {params.row.LastCommunication
          ? dayjs(params.row.LastCommunication).format(dateFormat)
          : '-'}
      </span>
    ),
  },
  {
    field: 'LastCase',
    headerName: 'Letzter Fall',
    minWidth: 300,
  },
]

export const sxSettings = {
  '.MuiDataGrid-root': {
    border: 0,
  },
  '.MuiDataGrid-cell': {
    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
  },
  // disable cell selection style
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  // pointer cursor on ALL rows
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
}

const fetchPatients = async (clinicAccess: string) => {
  try {
    console.log('PPPPPP in PatientsPage clinicAccess:', clinicAccess)
    const client = generateClient()
    const response = await client.graphql({
      query: mainsByClinicAccessAndSK,
      variables: {
        ClinicAccess: clinicAccess, // Used to query the secondary index directly
        SK: { beginsWith: 'PAT#' }, // Additional condition for sort key
      },
    })
    console.log('PPPPPPPP28 patients:', response)

    const items = (response as GraphQlByClinicAccessAndSKResponseList<Patient>)
      .data.mainsByClinicAccessAndSK.items

    // Sorting the patients by LastCommunication descending
    items.sort((a, b) => {
      const dateA = new Date(a.LastCommunication)
      const dateB = new Date(b.LastCommunication)
      return dateB.getTime() - dateA.getTime() // For descending order
    })

    return items
    // return (response as GraphQlByClinicAccessAndSKResponseList<Patient>).data.mainsByClinicAccessAndSK.items;
  } catch (error) {
    console.error('PPPPPPPP  Error fetching patients:', error)
    // Handle the error appropriately
    throw error // Re-throw the error if you want to propagate it
  }
}

const PatientsPage = () => {
  const { user } = useContext(AppContext)
  console.log('UUUUUUUU in PatientsPage user:', user)

  const { setPageTitle } = useContext(AppContext)

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle)
  }

  React.useEffect(() => {
    handleTitleChange('Patienten')
  }, [])

  const {
    data: patients,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['patients', user?.Clinic], // Include ClinicAccess in the query key for cache invalidation on user change
    queryFn: () => fetchPatients(user!.Clinic),
  })

  const [inactive, setInactive] = React.useState(false)

  const navigate = useNavigate()

  const filteredPatients = patients?.filter((patient) =>
    inactive ? patient.Platform === null : patient.Platform ?? ''.length > 0,
  )

  return (
    <Frame
      isError={isError}
      isLoading={isLoading}
      entity="Patienten"
    >
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ paddingBottom: '15px' }}
      >
        <FormControlLabel
          control={
            <>
              <span style={{ paddingLeft: '15px' }}>aktive</span>
              <CustomSwitch
                color="error"
                checked={inactive}
                onChange={(event) => setInactive(event.target.checked)}
              />
            </>
          }
          label="inaktive"
        />
        <FormHelperText>
          inaktive Patienten haben noch nicht den Aktivierungs Code in der App
          angegeben
        </FormHelperText>
      </FormControl>
      {filteredPatients && (
        <DataGrid
          rows={filteredPatients}
          onRowClick={(params) => navigate(`${params.row.PK}/cases`)}
          columns={columns}
          getRowId={(row) => row.PK}
          slots={{
            noRowsOverlay: NoData,
            footer: undefined,
          }}
          sx={sxSettings}
          getRowClassName={() => `custom-row`}
        />
      )}
    </Frame>
  )
}

export default PatientsPage

