import './styles/App.scss'
import '@aws-amplify/ui-react/styles.css'
import 'dayjs/locale/de'

import { withAuthenticator } from '@aws-amplify/ui-react'
import { createTheme, LinearProgress, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth'
import dayjs from 'dayjs'
import React, { createContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import config from './amplifyconfiguration.json'
import awsExports from './aws-exports'
import AuthFlowRoute from './components/AuthFlowRoute'
import ProtectedRoute from './components/ProtectedRoute'
import { dentiColors } from './constants/colors'
import { pageRoutes } from './constants/routes'
import SofiaProRegular from './font/SofiaPro-Regular.otf'
import { getUser } from './graphql-queries/customQueriesGraphQL'
import { getInstructionID } from './graphql-queries/customQueriesGraphQL'
import { GraphQlResponse, InstructionID, User } from './graphql-queries/types'
import AddCasePage from './pages/AddCasePage'
import ClearingListPage from './pages/ClearingListPage'
import PatientRegistrationPage from './pages/PatientRegistrationPage'
import PatientsCasesPage from './pages/PatientsCasesPage'
import PatientsChatPage from './pages/PatientsChatPage'
import PatientsPage from './pages/PatientsPage'
import ToDoListPage from './pages/ToDoListPage'

Amplify.configure(config)
Amplify.configure(awsExports)

const theme = createTheme({
  typography: {
    fontFamily: 'Sofia !important',
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: dentiColors.baseDark,
          '&::placeholder': {
            color: dentiColors.baseDark,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '8px',
          backgroundColor: dentiColors.baseDark,
          fontSize: '1rem !important',
          textTransform: 'none',
          letterSpacing: '.5px',
          '&:hover': {
            backgroundColor: dentiColors.accent,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sofia';
          font-weight: 400;
          font-style: normal;
          src: local('Sofia'), local('Sofia-Regular'), url(${SofiaProRegular}) format('opentype');
          ascent-override: 105%;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

      `,
    },
  },
})

type AuthUser = CognitoUser // Or use CognitoUser directly
type AppContextState = {
  user?: User
  setUser: (value: User | undefined) => void
  pageTitle: string
  setPageTitle: (value: string) => void
  patientName: string
  setPatientName: (value: string) => void
  intervention: string
  setIntervention: (value: string) => void
  instructionID: string
  setInstructionID: (value: string) => void
  // Add more state types as needed
}

async function getGroupOfActiveUser(): Promise<string | string[]> {
  try {
    const session = await fetchAuthSession()
    const accessToken = session?.tokens?.accessToken

    if (!accessToken) {
      console.log('€€€€€€€€€ Access token is undefined')
      throw new Error('€€€€€€€€€ Access token is undefined')
    }
    const groups = accessToken.payload['cognito:groups'] as string[] | undefined
    console.log('$$$$$$$$$ accessToken.payload', accessToken.payload)
    return groups && groups.length > 0 ? groups : ['No group assigned']
  } catch (err) {
    console.error(err)
    return 'No group assigned' // Return default message in case of an error
  }
}

interface AppProps {
  signOut: () => void // Define the correct type for the signOut function
  user: AuthUser // Make sure AuthUser is imported or defined
}

export const fetchUser = async (sub: string) => {
  const client = generateClient()
  console.log('UUUUUUU sub:', sub)
  const response = (await client.graphql({
    query: getUser,
    variables: {
      PK: sub,
      SK: '##',
    },
  })) as GraphQlResponse<User>
  console.log('in fetchUser / response:', response)

  if (
    response &&
    response.data &&
    response.data.getMain &&
    response.data.getMain.PK > ''
  ) {
  } else {
    window.alert(
      'Ihr Profil ist bisher noch nicht in der dentinostic-professional Datenbank angelegt.',
    )
    console.log('$$$$$$$$ in fetchUser / before signout')
    await signOut()
    console.log('$$$$$$$$ in fetchUser / after signout')
    //  setUser(undefined);
    //  navigate("/");
  }
  return (response as GraphQlResponse<User>).data.getMain
}

const fetchInstructionID = async (PK: string) => {
  console.log(`ßßßßßßß In Apps / fetchInstructionID / PK:`, PK)
  const client = generateClient()
  const response = await client.graphql({
    query: getInstructionID,
    variables: {
      PK: PK,
      SK: 'INSTRUCTIONID##',
      limit: 1000000,
    },
  })

  console.log(`ßßßßßßß In Apps / fetchInstructionID / response:`, response)
  try {
    const result = (response as GraphQlResponse<InstructionID>).data.getMain

    if (result.Name && result.Name.length > 0) {
      const instructionID = result.Name
      console.log('ßßßßßßß instructionID found: ', instructionID)
      return instructionID
    } else {
      console.log('ßßßßßßß No matching items found')
      return null
    }
  } catch (error) {
    console.error('ßßßßßßß Error querying DynamoDB:', error)
    return null
  }
}

export const AppContext = createContext<AppContextState>({
  user: undefined,
  setUser: () => {},
  pageTitle: '',
  setPageTitle: () => {},
  patientName: '',
  setPatientName: () => {},
  intervention: '',
  setIntervention: () => {},
  instructionID: '',
  setInstructionID: () => {},
})

const App = () => {
  const [user, setUser] = useState<User>()
  const [pageTitle, setPageTitle] = useState('')
  const [patientName, setPatientName] = useState('')
  const [intervention, setIntervention] = useState('')
  const [instructionID, setInstructionID] = useState('')
  const [userIsLoading, setUserIsLoading] = useState(true)
  console.log('UUUUUUU user:', user)
  const [userGroup, setUserGroup] = useState<string[]>([])
  console.log('AAAAAAAAAAA userGroup', userGroup)

  useEffect(() => {
    dayjs.locale('de')
    setUserIsLoading(true)
    getCurrentUser()
      .then(async ({ userId }) => {
        const dbUser = await fetchUser(userId)
        setUser(dbUser)

        setUserIsLoading(false)
      })
      .catch((e) => console.error(e))
      .finally(() => setUserIsLoading(false))
  }, [])

  useEffect(() => {
    async function fetchUserGroup() {
      const groups = await getGroupOfActiveUser()
      console.log(`In Apps / groups:`, groups)
      setUserGroup(Array.isArray(groups) ? groups : groups ? [groups] : [])

      // Fetch the instructionID using the PK (userId or some other identifier)
      const instructionID = await fetchInstructionID(groups[0])
      if (instructionID) {
        setInstructionID(instructionID) // Update the instructionID in state
      }
    }
    fetchUserGroup()
  }, [])

  if (userIsLoading) {
    return <LinearProgress />
  }
  const contextValue = {
    user,
    setUser,
    pageTitle,
    setPageTitle,
    patientName,
    setPatientName,
    intervention,
    setIntervention,
    instructionID,
    setInstructionID,
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="de"
      >
        <AppContext.Provider value={contextValue}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to="/patients"
                />
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path={pageRoutes.patients}
                element={<PatientsPage />}
              />
              <Route
                path="/toDoList"
                element={<ToDoListPage />}
              />
              <Route
                path="/register-patient"
                element={<PatientRegistrationPage />}
              />
              <Route
                path={`/patients/:partitionKey/cases/register-case`}
                element={<AddCasePage />}
              />
              <Route
                path={`/patients/:partitionKey/cases`}
                element={<PatientsCasesPage />}
              />
              <Route
                path="/patients/:partitionKey/cases/:sortKey"
                element={<PatientsChatPage />}
              />
              <Route
                path="/clearingList"
                element={<ClearingListPage />}
              />
            </Route>
          </Routes>
        </AppContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default withAuthenticator(App)
