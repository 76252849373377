import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import { CustomFormLabel } from "../pages/PatientRegistrationPage";

type FormRadioGroupProps<T> = {
  items: { value: string; label: string }[];
  name: string;
  label: string;
  control: T;
};

const FormRadioGroup = <T,>({
  items,
  name,
  label,
  control,
}: FormRadioGroupProps<T>) => {
  return (
    <Controller
      name={name as string}
      control={control as unknown as Control<FieldValues>}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <CustomFormLabel id={`${name}-label`}>{label}</CustomFormLabel>
          <RadioGroup
            aria-labelledby={`${name}-label`}
            row
            onChange={(_event, value) => field.onChange(value)}
            value={field.value ?? null}
          >
            {items.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
          {!!error && (
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormRadioGroup;
