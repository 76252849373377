import { generateClient } from 'aws-amplify/api'
import { EventBridgeClient, PutEventsCommand } from "@aws-sdk/client-eventbridge";

const REGION = "eu-central-1"; 

// Extract credentials from the captured data
const getCredentialsFromClient = (data: any) => {
  const symbols = Object.getOwnPropertySymbols(data);
  const amplifySymbol = symbols.find(sym => sym.description === 'amplify');
  const myAmplifyData = amplifySymbol ? data[amplifySymbol] : undefined;
  const credentials = myAmplifyData.Auth.authOptions.credentialsProvider._credentialsAndIdentityId.credentials;
  return credentials

};

/*
Currently we use sendEventToEventBridge for 2 purposes: email for access code 
transmission and message for pushNotification about a new chat.
For both purposes, we have the parameters appLauchCode and messageText. The calling
function leaves the not relevant parameter blank. For the Detail JSON structure in
the parameter we transmit, it does not matter when there is an unused field.
*/

const sendEventToEventBridge = async (
  eventType: string, 
  object: string, 
  patientID: string, 
  appLaunchCode: string, 
  messageText: string) => {
  console.log('$$$$$$$$$$ in sendEventToEventBridge / start');
  try {
    const client = generateClient();
    console.log('$$$$$$$$$$ Generated Client:', client);
    const myCredentials = getCredentialsFromClient(client);

    const eventBridgeClient = new EventBridgeClient({
      region: REGION,
      credentials: myCredentials,
    });
    
    const params = {
      Entries: [
        {
          Detail: JSON.stringify({
            eventType: eventType,
            msg: messageText,
            appLaunchCode: appLaunchCode,
            object: object,
            objectID: patientID,
          }),
          DetailType: "case",
          EventBusName: "dentipro",
          Source: "dentipro-ui",
          Time: new Date(),
        },
      ],
    };

    const command = new PutEventsCommand(params);
    const response = await eventBridgeClient.send(command);
    console.log('$$$$$$$$$$ in sendEventToEventBridge / Event sent successfully:', response);
    return response; // Returning the response
  } catch (error) {
    console.error('€€€€€€€€€€ in sendEventToEventBridge / Error sending event:', error);
    throw error; // Rethrowing the error to let the caller handle it
  }
};

export default sendEventToEventBridge;
