// ClearingEdit.js
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import ClearingEditDataGet2 from './ClearingEditDataGet2';
import { updateMainTable } from '../updateMainTable';
import { postClearing } from '../postClearing';
import checkExistsInMainTable from "../checkExistsInMainTable";
import updateClearingListRecord from '../updateClearingListRecord';
import ShowErrorModal from './ShowErrorModal';
import extractElementNumber from '../utils/extractElementNumber';


/*
This component has the logic for the DialogBox, used for the Clearing of a case.
It is started when a row was selected from ClearingList.
*/
const ClearingEdit = ({
  activeCase,
  isOpen,
  onClose,
  rowData }) => {
  console.log("EEEEEEEEEEEEE activeCase: ", activeCase)
  console.log("EEEEEEEEEEEEE content of selected row: ", rowData)
  console.log("EEEEEEEEEEEEE content of selected row SK: ", rowData.SK)
  const [appointments, setAppointments] = useState([]);
  const [patientMasterData, setPatientMasterData] = useState([]);
  const [caseMasterData, setCaseMasterData] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDialogTitle, setErrorDialogTitle] = useState('');
  const [errorInstruction, setErrorInstruction] = useState('');
  const [clearingDecisionsMade, setClearingDecisionsMade] = useState(false);
  const [clearingFinalized, setClearingFinalized] = useState(false);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState({});
 
/*
Based on the ClearingStatus of the selected row, we have to set variables which control
whether checkboxes and buttons will be visible and enabled or not.
*/

  useEffect(() => {
    const fetchClearingEditData = async () => {
      try {
        const { initialAppointmentsData, patientMasterData, caseMasterData } = await ClearingEditDataGet2(rowData.SK);
        setAppointments(initialAppointmentsData);
        setPatientMasterData(patientMasterData);
        setCaseMasterData(caseMasterData);
        console.log('PPPPPPPPPPP fetchClearingEditData', initialAppointmentsData, patientMasterData,caseMasterData);
      } catch (error) {
        console.error('Error fetching appointments data:', error);
      }
    };
      // This checks that rowData exists and has the ClearingStatus property
      if (rowData?.ClearingStatus === "gestartet") {
        setClearingDecisionsMade(true);
      } else {
        if (rowData?.ClearingStatus === "abgeschlossen") {
          setClearingDecisionsMade(false);
          setClearingFinalized(true);
        } else {
        setClearingDecisionsMade(false);
        }
      }
    fetchClearingEditData();
  }, [rowData.SK]); // Assuming rowData.SK is the dependency that triggers this effect
  

/*
showError serves the first rudimentary process of error handling which must be 
further enhanced at a later stage.
*/
  const showError = (error, title = 'Error', errorInstruction) => {
    const errorMessage = error?.message || 'An unknown error occurred';
    setErrorMessage(errorMessage);
    setErrorDialogTitle(title); // Use the passed title or a default value
    setErrorInstruction(errorInstruction); // Use the passed title or a default value
    setErrorModalOpen(true);
  };


  const handleErrorClose = () => {
    setErrorModalOpen(false);
  };

/*
handleClearingButton is fired when the user has used the 
Button "Abrechnung abschließen". It starts postClearing which takes care of all
database transmissions and updates. In the scope of postClearing, clearing records
will be written to the database as the basis for later charging.
*/
  const handleClearingButton = async (patientMasterData, onCloseCallback) => {
    console.log(`EEEEEEEEEEEEECCCCCCCCCCCCCC Button clicked 
    activeCase: `, activeCase);
    console.log(`EEEEEEEEEEEEECCCCCCCCCCCCCC Button clicked 
    patientMasterData: `, patientMasterData);


    try {
      await postClearing(
        patientMasterData,
        activeCase
      );
      console.log('PPPPPPPPPPP in handleClearingButton for postClearing');
/*
Once the process of postClearing is completed, we set clearingFinalize to true in 
order to disable the checkboxes in the Dialogbox. Once a clearing is posted for a
case, no further changes on these decisions are possible. 
*/
      setClearingFinalized(true);

/*
Then we close the dialogbox and set the navigation address, so that the next screen
will be the datagrid loaded with the Clearing List. In this list. The previously
selected case will now show the status "geschlossen" 
*/
      onCloseCallback();
      navigate('/clearingList'); // Adjust the URL as needed
    } catch (error) {
      console.error('POPOPOPOPOPO in handleClearingButton for postClearing Error:', error);
      console.error('POPOPOPOPOPO in handleClearingButton for postClearing Error message:', error.message);
      showError(error, 
        "Fehler bei der Verbuchung der Abrechnungsdaten",
        "Berichten Sie den folgenden Fehlertext des Systems an den Support:");
    }

  }

  /*
handleCheckboxChange is fired when the user has changed a checkbox in the dialogbox.
The checkbox can be related either to an appointment or to a chat.
A checking of a checkbox will change the ClearingStatus field to :
      to-be-cleared for appointment (in case it is done on an appointment record)
      to-be-cleared for chat (in case it is done on an chat record)
When it later comes to database updates, we need to be able to differentiate the 
levels based on these entries. Thats why we have two different status values.
*/
  const handleCheckboxChange = async (pk, sk, clinicAccess, checked) => {
    console.log(`EEEEEEEEEEEEECCCCCCCCCCCCCC Checkbox 
     with pk: ${pk} 
     with sk: ${sk} 
     checked: `, checked);

    setClearingDecisionsMade(true);
    /*
     The precondition for the ability to open a ClearingDialogBox is the existence of the case in the 
     ClearingList. So at this point here, we know that the ClearingList record is existing. This record can 
     have one of two possible values in the ClearingStatus field: "offen" or "gestartet".
     (This status value is even part of the SK)
     In case this record holds still the value of "offen", the current posting would be the first clearing 
     decision for this case and we must change the ClearingList record to "gestartet". In case it is on 
     "gestartet" already, no action is required.
    
     Hence as a first step, we check whether a record with "gestarted" does exist. If not, we delete the
     "offen" record and create an identical one but with "gestartet"
    */
    
    if (await checkExistsInMainTable(
      clinicAccess,
      `TYP#ClearingList#STS#gestartet#CASE#${extractElementNumber("CASE",sk)}#PAT#${pk}##`
    )) {
      console.log("`PPPPPPPPPPP ClearingList record with gestartet existing");
    } else {
    /*
     Since with ClearingList, we have the special case that we want to carry the status also in the SK for the
     merit of faster access, the update requires a two step approach of insertion and deletion. This is 
     encapsulated in updateClearingListRecord which we only have to call with the required original- and
     new SK
    */
      console.log("`PPPPPPPPPPP ClearingList record with gestartet not existing, so we update");
      const originalPK = clinicAccess;
      const originalSK = `TYP#ClearingList#STS#offen#CASE#${extractElementNumber("CASE",sk)}#PAT#${pk}##`
      const newSK = `TYP#ClearingList#STS#gestartet#CASE#${extractElementNumber("CASE",sk)}#PAT#${pk}##`
      const newStatus = "gestartet"

      updateClearingListRecord(originalPK, originalSK, newSK, newStatus)
        .then(() => console.log('PPPPPPPPPPP ClearingList record updated successfully with status:', newStatus))
        .catch(error => console.error('PPPPPPPPPPP Error for ClearingList record update error:', error));
    }

    /*
     Now we can update the ClearingStatus and LastClearingDecision fields in the relevant appointment and/or chat records.
     We do this by defining the relevant PK and SK and call updateMainTable. updateMainTable takes care of the
     update in the database but also in globalPatientData
    */
    try {
      // we update the clearing status of the  record  
      console.log("SSSSSSSSSSSSS in handleCheckboxChange sk", sk)
      let chatSK;
      let appointmentSK;
      let caseSK;

      if (sk.includes("CHAT#")) {
        chatSK = sk;
        appointmentSK = `${sk.split("#CHAT#")[0]}` + "##";
        caseSK = `${sk.split("#APP#")[0]}` + "##";
      } else {
        chatSK = "not set";
        appointmentSK = `${sk.split("#CHAT#")[0]}`;
        caseSK = `${sk.split("#APP#")[0]}` + "##";
      }



      console.log("SSSSSSSSSSSSS in handleCheckboxChange sk, chatSK, appointmentSK, caseSK:", sk, chatSK, appointmentSK, caseSK)
      if (sk.includes("CHAT#")) {
        try {
          await updateMainTable(
            pk,
            chatSK,
            "ClearingStatus",
            checked ? "to-be-cleared for chat" : "not decided",
          );
          console.log('SSSSSSSSSSSSS in handleCheckboxChange for chat record/ Main table updated');
        } catch (error) {
          console.error('SSSSSSSSSSSSS in handleCheckboxChange for chat record/ Error updating Main table with ClearingStatus:', error);
        }

      } else {
        const appointmentSK = `${sk.split("#CHAT#")[0]}`;
        console.log("SSSSSSSSSSSSS in handleCheckboxChange appointmentSK", appointmentSK)
        try {
          await updateMainTable(
            pk,
            appointmentSK,
            "ClearingStatus",
            checked ? "to-be-cleared for appointment" : "not decided",
          );
          console.log('SSSSSSSSSSSSS in handleCheckboxChange / Main table updated');
        } catch (error) {
          console.error('SSSSSSSSSSSSS in handleCheckboxChange / Error updating Main table with ClearingStatus:', error);
        }
      }
      // now we can set the LastClearingDecision on Case and Appointment level
      try {
        console.log('SSSSSSSSSSSSS in handleCheckboxChange / appointmentSK', appointmentSK);
        await updateMainTable(
          pk,
          appointmentSK,
          "LastClearingDecision",
          new Date().toISOString(),
        );
        console.log('SSSSSSSSSSSSS in handleCheckboxChange / Main table updated');
        try {
          await updateMainTable(
            pk,
            caseSK,
            "LastClearingDecision",
            new Date().toISOString(),
          );
          console.log('SSSSSSSSSSSSS in handleCheckboxChange / Main table updated');
        } catch (error) {
          console.error('SSSSSSSSSSSSS in handleCheckboxChange / Error updating Main table with ClearingStatus:', error);
        }
      } catch (error) {
        console.error('SSSSSSSSSSSSS in handleCheckboxChange / Error updating Main table with ClearingStatus:', error);
      }

    } catch (error) {
      console.error('SSSSSSSSSSSSS in handleCheckboxChange / Error updating Main table:', error);
    }

    /*
     As a last step of handleCheckboxChange, we update the appointments array. 
     This is the array which gets initially populated in ClearingEditDataGet. We must
     update it here so that the change (checkbox decisions) get directly reflected
     in the active Dialogbox 
    */
    setAppointments(prevAppointments => {

      // Map over the appointments to create a new array
      return prevAppointments.map(appointment => {
        // Check if this is the appointment that needs updating
        if (appointment.sk === sk) {
          return { ...appointment, addedToBilling: checked }; // Update the appointment
        }
        // If not, check if one of the messages needs updating
        const updatedMessages = appointment.messages.map(message => {
          if (message.sk === sk) {
            return { ...message, addedToBilling: checked }; // Update the message
          }
          return message; // Return the message unchanged
        });
        return { ...appointment, messages: updatedMessages }; // Return the updated appointment
      });
    });
    console.log(`EEEEEEEEEEEEECCCCCCCCCCCCCC Checkbox appointments `, appointments);

  };

/*
handleChangePanel is called when the user foldes or unfoldes the Accordion
*/
  const handleChangePanel = (panelId) => () => {
    setExpanded(prevExpanded => {
      const newExpanded = {
        ...prevExpanded,
        [panelId]: !prevExpanded[panelId]
      };
      console.log("EEEEEEEEEEEEE New expanded state:", newExpanded);
      return newExpanded;
    });

  };

/*
Modifications an appointments shall trigger a re-render
*/
  useEffect(() => {
    // This code will run after the appointments state has been updated
    console.log(`EEEEEEEEEEEEECCCCCCCCCCCCCC useEffect appointments `, appointments);

  }, [appointments]); // This tells React to run the effect when the appointments state changes


  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // This will push the close button to the right
    paddingRight: '48px' // This padding is to ensure the title does not overlap the close button
  };

/*
handleClose is fired when the X in the upper right corner is checked.
*/
  const handleClose = () => {
    console.log("EEEEEEEEEEEEE in handleClose");
    onClose();
    navigate('/clearingList'); // Adjust the URL as needed
  };

/*
This returns the main design of ClearingEdit. It embeds the reference to AppointmentItem
which again embeds the reference to ChatPanel
*/
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Existing DialogTitle code... */}
      <DialogTitle>
        <div style={titleStyle}>
          <Box>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              <Box component="span" mr={2}>{'Abrechnung'}</Box>
              <Box component="span" mr={2}>{caseMasterData.Intervention}</Box>
              <Box component="span">{patientMasterData.PatientName}</Box>
            </Typography>
            <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold' }}>
              {'Terminübersicht'}
            </Typography>
          </Box>
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {appointments.map((appointment, index) => {
          console.log(`EEEEEEEEEEEE Rendering appointment with SK: ${appointment.sk}`);
          console.log(`EEEEEEEEEEEE Rendering appointment ${appointment}`);

          return (
            <AppointmentItem
              key={appointment.sk}
              appointment={appointment}
              panelId={`panel${index}`} // Using panelId as the unique identifier
              expanded={expanded[`panel${index}`]}
              handleChangePanel={handleChangePanel}
              handleCheckboxChange={handleCheckboxChange}
              clearingFinalized={clearingFinalized}
            />
          );
        })}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        {clearingDecisionsMade && (
          <Button
            onClick={() => handleClearingButton(patientMasterData, onClose)}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: '#000',
              color: '#fff',
              padding: '10px 30px',
              borderRadius: '20px',
            }}
          >
            Abrechnung abschließen
          </Button>
        )}
      </DialogActions>


      <ShowErrorModal
        open={errorModalOpen}
        handleClose={handleErrorClose}
        errorMessage={errorMessage}
        dialogTitle={errorDialogTitle}
        errorInstruction={errorInstruction}
      />
    </Dialog>

  );
};


/*
AppointmentItem describes panel of an (up to 10) appointments of the case.
The appointment is alway prone for a clearing decision, hence it is always 
associated with a checkbox.

AppointmentItem embeds ChatPanel as explained below
*/
const AppointmentItem = ({ appointment, panelId, expanded,
  handleChangePanel,
  handleCheckboxChange, clearingFinalized }) => {


  if (panelId === undefined) {
    return null; // or <LoadingIndicator />;
  }

/*
The appointment data are structured in a hierachy which reflects the dialogbox 
functionality:
  we have up to 10 appointmens
      and each can include any number of messages (representing the chat messages)

Thats why we distinguish in the code below two array names:
  appointment and 
  message

Whether a checkbox is displayed as checked or unchecked is based on a a field
  appointment.addedToBilling 
  message.addedToBilling
These are set in ClearingEditDataGet

Also the decision about the fact whether a checkbox is disables is considered here
of course based on the varibles explained above: clearingFinalized

A special case is a Textelement with 'Zusätzliche Abrechnung möglich'. This
element shall appear only under certain conditions. In ClearingEditDataGet, the data 
are analyzed for this purpose and the result of this investigation is expressed in 
field clearingPotential on appointment level. 
Business background:
In case a dentist answers to a patient's chat which has arrived AFTER the last 
decision the dentist has made in this case about clearing, THIS chat answer has the
potential to be selected as an additional item to be charged. To make the dentist 
aware of this constellation, this textelement is displayed.
*/

  const isPanelExpanded = expanded ? true : false;
  console.log("EEEEEEEEEEEEE in AppointmentItem clearingFinalized: ", clearingFinalized)
  return (
    <Paper variant="outlined" square sx={{ mb: 2, overflow: 'hidden' }}>
      <Box sx={{ p: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography variant="subtitle2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              {`${appointment.name}:`}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle2">{appointment.date}</Typography>
          </Grid>
          <Grid item xs>
            {appointment.clearingPotential && !clearingFinalized && (
              <Typography
                variant="body2"
                sx={{
                  bgcolor: 'lightpink',
                  padding: '3px 8px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  ml: 2,
                }}
              >
                {'Zusätzliche Abrechnung möglich'}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleChangePanel(panelId)}
              aria-expanded={expanded?.[panelId] || false}
              aria-label="Show more"
              sx={{ padding: '0' }} // Reduce the padding of the icon button to align it with the text
            >
              <ExpandMoreIcon sx={{ transform: isPanelExpanded ? 'rotate(180deg)' : 'rotate(0)' }} />            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1, mb: -1.5 }}> {/* Adjusted bottom margin to reduce the space */}
          <FormControlLabel
            control={<Checkbox checked={appointment.addedToBilling}
              onChange={(event) => handleCheckboxChange(
                appointment.pk,
                appointment.sk,
                appointment.clinicAccess,
                event.target.checked,
      //          setGlobalPatientData
                )} 
                disabled={clearingFinalized} 
                />}
            label={appointment.addedToBilling ? "zur Abrechnung hinzugefügt" : "zur Abrechnung hinzufügen"}
            sx={{ display: 'block' }}
          />
        </Box>
      </Box>
      <Accordion
        square
        expanded={isPanelExpanded}
        onChange={handleChangePanel(panelId)}
        sx={{
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            padding: 0,
            minHeight: '0 !important', // Override minimum height
            '& .MuiAccordionSummary-content': {
              margin: '0 !important' // Override default margins
            }
          }
        }}
      >
        <AccordionSummary aria-controls={`panel${panelId}bh-content`} id={`panel${panelId}bh-header`}>
          {/* Invisible placeholder, required for correct accordion behavior */}
          <Typography sx={{ visibility: 'hidden', height: 0 }}>Placeholder</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0, pb: 2, px: 2 }}>
          {appointment.messages.map((message) => (
            <ChatPanel message={message}
              handleCheckboxChange={handleCheckboxChange}
    //          setGlobalPatientData={setGlobalPatientData}
              clearingFinalized = {clearingFinalized}/>
          ))}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

/*
ChatPanel describes the part of the dialogbox which appears when the Accordion is
unfolded in order to show the chat messages.
*/
const ChatPanel = ({ message, handleCheckboxChange = { handleCheckboxChange },
   clearingFinalized}) => {
/*
showCheckbox controls whether a checkbox shall be visible in the first place.
Background: Only chats from dentists are prone for a clearing selection. Hence only
these shall own a checkbox. We recognize this condition from the data content (of the
data base but moved into message)
*/
  const showCheckbox = message.type === "isOwn";

  return (
    <Paper variant="outlined" sx={{ my: 1 }}>
      <Box sx={{ bgcolor: 'background.default', p: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2">{message.author || 'Author Placeholder'}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{message.dateTime || 'Time Placeholder'}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{message.text}</Typography>
      </Box>
      {showCheckbox && (
        <Box sx={{ pl: 2, pb: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={message.addedToBilling || false}
              onChange={(event) => handleCheckboxChange(message.pk,
                message.sk,
                message.clinicAccess,
                event.target.checked,
      //          setGlobalPatientData
                )} 
                disabled={clearingFinalized} 
                />}
            label={message.addedToBilling ? "zur Abrechnung hinzugefügt" : "zur Abrechnung hinzufügen"}
          />
        </Box>
      )}
    </Paper>
  );
};

export default ClearingEdit;

