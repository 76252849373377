import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton,List, Typography } from "@mui/material";
import React from "react";

import { dentiColors } from "../constants/colors";
import { Appointment } from "../graphql-queries/types";
import AppointmentCard from "./AppointmentCard";
import Frame from "./Frame";

type AppointmentsSectionProps = {
  appointments: Appointment[];
  isLoading: boolean;
  isError: boolean;
  patientName: string;
  onAddAppointment: () => void;
};

const AppointmentsSection = ({
  appointments,
  isError,
  isLoading,
  patientName,
  onAddAppointment,
}: AppointmentsSectionProps) => {
  const sortedAppointments = appointments.sort((a, b) =>
    b.Date.localeCompare(a.Date)
  );

  return (
    <Frame
      isLoading={isLoading}
      isError={isError}
      entity="Termine"
      noData={appointments?.length === 0}
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2" gutterBottom>
          {patientName}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            Termine
          </Typography>
          <IconButton
            onClick={onAddAppointment}
            size="medium"
            style={{ backgroundColor: dentiColors.baseDark }}
          >
            <AddIcon style={{ color: dentiColors.baseLight }} />
          </IconButton>
        </Box>
      </Box>
      <List style={{ display: "flex", flexFlow: "column", gap: "10px" }}>
        {sortedAppointments?.map((appointment, index) => (
          <AppointmentCard
            key={appointment.PK + appointment.SK}
            appointment={appointment}
            number={sortedAppointments.length - index}
          />
        ))}
      </List>
    </Frame>
  );
};

export default AppointmentsSection;
