import { generateClient } from 'aws-amplify/api';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';

/*
This function does basically nothing more than updating a record of type ClearingList
in the main table.

However this excercise is not a trivial case we could cover with updateTableMain, since
in the case of ClearingList, the information to be changed is included in the SK and
this key cannot be changed. Hence we have to insert a new record first and once this
is successful, delete the old record.

As far as the authorization is concerned, both transactions (create and delete) 
require the ClinicAccess field with the relevant group membership of the executing
user. In its orignal setup, the system was not prepared for accepting ClinicAccess
in a Delete instruction. This had to be tackled by a "input DeleteMainInput..." 
section in schema.graphql
*/
async function updateClearingListRecord(originalPK, originalSK, newSK, newStatus) {
    const client = generateClient(); // This assumes generateClient returns a configured GraphQL client

    console.log('PPPPPPPPPPP originalPK, originalSK, newSK, newStatus', 
    originalPK, originalSK, newSK, newStatus);
    const getParams = {
        PK: originalPK,
        SK: originalSK,
    };

    try {
        // Get the current item
        console.log('PPPPPPPPPPP getParams', getParams);
        const currentDataResponse = await client.graphql({
            query: queries.getMain,
            variables: getParams
        });
        const currentItemData = currentDataResponse.data.getMain;
        console.log('PPPPPPPPPPP currentItemData', currentItemData);

        // Now, prepare the new item data
        const newItemData = {
            PK: currentItemData.PK,
            SK: newSK, // Update the SK with the new value
            ClinicAccess: currentItemData.ClinicAccess,
            Intervention: currentItemData.Intervention,
            InsuranceType: currentItemData.InsuranceType,
            PatientName: currentItemData.PatientName,
            ClearingStatus: newStatus
        };

        // Perform the create mutation
        const createParams = {
            input: newItemData,
        };
        console.log('PPPPPPPPPPP ClearingList createParams', createParams);
        await client.graphql({
            query: mutations.createMain,
            variables: createParams
        });
        console.log('PPPPPPPPPPP ClearingList Item with new SK created successfully');


        const deleteParams = {
            input: {
                PK: originalPK,
                SK: originalSK,
                ClinicAccess: originalPK,
            }
        };

        // Perform the delete mutation
        console.log('PPPPPPPPPPP ClearingList deleteParams', deleteParams);
        await client.graphql({
            query: mutations.deleteMain,
            variables: deleteParams
        });
        console.log('PPPPPPPPPPP ClearingList Item with old SK deleted successfully');

    } catch (error) {
        console.error('PPPPPPPPPPP ClearingListError updating SK:', error);
        throw error;
    }

}

export default updateClearingListRecord;

