import { getUrl } from 'aws-amplify/storage'

export const getVideoUrl = async (imageLocation: string) => {
  // we strip the part /public/ from the imagelocation since getUrl adds public/
  // otherwise we would get public/public
  const newImageLocation =
    imageLocation.length > 0 ? imageLocation.substring(8) : imageLocation

  try {
    return await getUrl({
      key: newImageLocation,
      options: {
        validateObjectExistence: false, // defaults to false
        expiresIn: 200, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        useAccelerateEndpoint: false,
      },
    })
  } catch (error) {
    console.error(
      '€€€€€€€€€€€€€  Error getting video from S3 with getUrl',
      error,
    )
    return ''
  }
}
