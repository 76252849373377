import { generateClient } from 'aws-amplify/api';
import { updateMain as updateMainMutation } from './graphql/mutations';
import { updateGlobalPatientData } from './updateGlobalPatientData';

/*
This function updates the indicated record in Main. Once this is accomplished,
it updates the relevant entry in globalPatientData. In this way we stay synced 
between DB and memory data.
*/

export async function updateMainTable(pk, sk, fieldName, newValue) {
  console.log(`SSSSSSSSSSSSS updateMainTable 
  with pk: ${pk} 
  with sk: ${sk} 
  with fieldName: ${fieldName} 
  with newValue:  `, newValue);

  let updateData; 
  try {
    const client = generateClient();
    updateData = {
      PK: pk,
      SK: sk,
      [fieldName]: newValue
    };
    console.log('SSSSSSSSSSSSS  update fields updateData:', updateData)

    const response = await client.graphql({
      query: updateMainMutation,
      variables: { input: updateData }
    });
    console.log('SSSSSSSSSSSSS in updateMainTable updated:', response.data.updateMain);
  //  updateGlobalPatientData(pk, sk, [fieldName], newValue, setGlobalPatientData) 
  } catch (error) {
      // Assuming 'error' is an object that contains an 'errors' array
      if (error.errors && error.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException") {
        throw new Error('mainTable update failed');
      }
      throw error; // Rethrow the original error if it's not the conditional check error
  }

}

  