// appointmentRegistrationValidation.ts

export interface ValidationResult {
  isValid: boolean;
  errors: Record<string, string>; // or any structure that represents your errors
}

export function appointmentRegistrationValidation(
  appointmentData: any
): ValidationResult {
  // Initialize an object to store any validation errors
  const errors: Record<string, string> = {};
  console.log("DDDDDDDDDDD in appointmentRegistrationValidation appointmentData:", appointmentData);

  // Validation checks:

  if (!appointmentData.appointment.date) {
    errors.appointment = "Es ist kein Datum festgelegt worden.";
  }

  // Determine if the data is valid based on the presence of errors
  const isValid = Object.keys(errors).length === 0;

  // Return the validation result
  return {
    isValid,
    errors,
  };
}
