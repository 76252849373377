/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVersionCounter2 = /* GraphQL */ `
  query GetVersionCounter2($id: ID!) {
    getVersionCounter2(id: $id) {
      PK
      LatestCounter
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVersionCounter2s = /* GraphQL */ `
  query ListVersionCounter2s(
    $filter: ModelVersionCounter2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVersionCounter2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        LatestCounter
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppLaunchCodes = /* GraphQL */ `
  query GetAppLaunchCodes($PK: ID!, $SK: String!) {
    getAppLaunchCodes(PK: $PK, SK: $SK) {
      PK
      SK
      PatientID
      PatientName
      Salutation
      ValidUntil
      Utilized
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppLaunchCodes = /* GraphQL */ `
  query ListAppLaunchCodes(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelAppLaunchCodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppLaunchCodes(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        PatientID
        PatientName
        Salutation
        ValidUntil
        Utilized
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDentiProControl = /* GraphQL */ `
  query GetDentiProControl($PK: ID!, $SK: String!) {
    getDentiProControl(PK: $PK, SK: $SK) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDentiProControls = /* GraphQL */ `
  query ListDentiProControls(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelDentiProControlFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDentiProControls(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Category
        Clinic
        DateTime
        Description
        EmailAddress
        Name
        Status
        Text
        UserID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHistDentiProControl = /* GraphQL */ `
  query GetHistDentiProControl($PK: ID!, $SK: String!) {
    getHistDentiProControl(PK: $PK, SK: $SK) {
      PK
      SK
      Category
      Clinic
      DateTime
      Description
      EmailAddress
      Name
      Status
      Text
      UserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHistDentiProControls = /* GraphQL */ `
  query ListHistDentiProControls(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelHistDentiProControlFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistDentiProControls(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Category
        Clinic
        DateTime
        Description
        EmailAddress
        Name
        Status
        Text
        UserID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMain = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMains = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        ClinicAccess
        Action
        AdditionalTreatments
        Answer
        AppVersion
        Category
        Charge
        City
        ClearingStatus
        ClearingID
        Clinic
        ClinicAddress
        Code
        CompensationDecision
        Country
        CounterAppointment
        CounterCase
        CounterChat
        CounterLog
        CounterNote
        Date
        DateOfBirth
        DateTime
        Description
        DeviceToken
        DialogueEntryPoint
        EmailAddress
        ImageLocation
        ImageType
        IncrementFactor
        InstructionID
        InsuranceName
        InsuranceCompanyNumber
        InsuranceContractNumber
        InsuranceStatus
        InsuranceType
        Intervention
        LastCase
        LastCommunication
        LastClearingDecision
        LinkToProfilePhoto
        Message
        Month
        Name
        Password
        PatientName
        Phone
        Platform
        PreferredNotificationMethodFromPatientAdHoc
        PreferredNotificationMethodFromPatientAppointment
        PreferredNotificationMethodNewCase
        Quarter
        Question
        Role
        Salutation
        Sender
        Source
        Status
        Street
        Surname
        Text
        Title
        UserID
        UserIdDefined
        UserIdLastTherapist
        UserIdScheduled
        UserIdSurgeon
        UserName
        ZipCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mainsByClinicAccessAndSK = /* GraphQL */ `
  query MainsByClinicAccessAndSK(
    $ClinicAccess: String!
    $SK: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mainsByClinicAccessAndSK(
      ClinicAccess: $ClinicAccess
      SK: $SK
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        ClinicAccess
        Action
        AdditionalTreatments
        Answer
        AppVersion
        Category
        Charge
        City
        ClearingStatus
        ClearingID
        Clinic
        ClinicAddress
        Code
        CompensationDecision
        Country
        CounterAppointment
        CounterCase
        CounterChat
        CounterLog
        CounterNote
        Date
        DateOfBirth
        DateTime
        Description
        DeviceToken
        DialogueEntryPoint
        EmailAddress
        ImageLocation
        ImageType
        IncrementFactor
        InstructionID
        InsuranceName
        InsuranceCompanyNumber
        InsuranceContractNumber
        InsuranceStatus
        InsuranceType
        Intervention
        LastCase
        LastCommunication
        LastClearingDecision
        LinkToProfilePhoto
        Message
        Month
        Name
        Password
        PatientName
        Phone
        Platform
        PreferredNotificationMethodFromPatientAdHoc
        PreferredNotificationMethodFromPatientAppointment
        PreferredNotificationMethodNewCase
        Quarter
        Question
        Role
        Salutation
        Sender
        Source
        Status
        Street
        Surname
        Text
        Title
        UserID
        UserIdDefined
        UserIdLastTherapist
        UserIdScheduled
        UserIdSurgeon
        UserName
        ZipCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHistMain = /* GraphQL */ `
  query GetHistMain($PK: ID!, $SK: String!) {
    getHistMain(PK: $PK, SK: $SK) {
      PK
      SK
      ClinicAccess
      Action
      AdditionalTreatments
      Answer
      AppVersion
      Category
      Charge
      City
      ClearingStatus
      ClearingID
      Clinic
      ClinicAddress
      Code
      CompensationDecision
      Country
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      Date
      DateOfBirth
      DateTime
      Description
      DeviceToken
      DialogueEntryPoint
      EmailAddress
      ImageLocation
      ImageType
      IncrementFactor
      InstructionID
      InsuranceName
      InsuranceCompanyNumber
      InsuranceContractNumber
      InsuranceStatus
      InsuranceType
      Intervention
      LastCase
      LastCommunication
      LastClearingDecision
      LinkToProfilePhoto
      Message
      Month
      Name
      Password
      PatientName
      Phone
      Platform
      PreferredNotificationMethodFromPatientAdHoc
      PreferredNotificationMethodFromPatientAppointment
      PreferredNotificationMethodNewCase
      Quarter
      Question
      Role
      Salutation
      Sender
      Source
      Status
      Street
      Surname
      Text
      Title
      UserID
      UserIdDefined
      UserIdLastTherapist
      UserIdScheduled
      UserIdSurgeon
      UserName
      ZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHistMains = /* GraphQL */ `
  query ListHistMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelHistMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHistMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        ClinicAccess
        Action
        AdditionalTreatments
        Answer
        AppVersion
        Category
        Charge
        City
        ClearingStatus
        ClearingID
        Clinic
        ClinicAddress
        Code
        CompensationDecision
        Country
        CounterAppointment
        CounterCase
        CounterChat
        CounterLog
        CounterNote
        Date
        DateOfBirth
        DateTime
        Description
        DeviceToken
        DialogueEntryPoint
        EmailAddress
        ImageLocation
        ImageType
        IncrementFactor
        InstructionID
        InsuranceName
        InsuranceCompanyNumber
        InsuranceContractNumber
        InsuranceStatus
        InsuranceType
        Intervention
        LastCase
        LastCommunication
        LastClearingDecision
        LinkToProfilePhoto
        Message
        Month
        Name
        Password
        PatientName
        Phone
        Platform
        PreferredNotificationMethodFromPatientAdHoc
        PreferredNotificationMethodFromPatientAppointment
        PreferredNotificationMethodNewCase
        Quarter
        Question
        Role
        Salutation
        Sender
        Source
        Status
        Street
        Surname
        Text
        Title
        UserID
        UserIdDefined
        UserIdLastTherapist
        UserIdScheduled
        UserIdSurgeon
        UserName
        ZipCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
