export function extractSortKey(type: "CASE", input: string) {
  return input.replaceAll("#", "").replace(type, "");
}

export function generateSortKey(
  type: "CASE",
  firstSortKey: string,
  subKey?: "CHAT" | "APP",
  secondSortKey?: string
) {
  if (secondSortKey && subKey) {
    return `${type}#${firstSortKey}#${subKey}##${secondSortKey}`;
  }
  if (subKey) {
    return `${type}#${firstSortKey}#${subKey}`;
  }
  return `${type}#${firstSortKey}##`;
}

export function generateSortKey2(...args: (string | undefined)[]): string {
  const validKeys = new Set(["CASE", "APP", "CHAT", "NOTES"]);
  let result = "";

  for (let i = 0; i < args.length; i += 2) {
    const key = args[i];
    const value = args[i + 1];

    if (typeof key === "string" && validKeys.has(key)) {
      result += `${key}#`;
      if (typeof value === "string") {
        result += `${value}`;
      }
      // Append a '#' only if there is another key following.
      if (i + 2 < args.length) {
        result += '#';
      }
    }
  }

  return result + "##";
}






