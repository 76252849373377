import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import React, { useState } from "react";

import { getCaseMaster,getClearingEditDetails , getPatientsDetails } from "../graphql-queries/customQueriesGraphQL";
import { Case,ChatMessage, ClearingEditSet, GraphQlResponse, GraphQlResponseList , PatientsDetails } from "../graphql-queries/types";
import convertToDateOrUseDefault from "../utils/convertToDateOrUseDefault";
import extractElementNumber from "../utils/extractElementNumber";
import makePrettyDate from "../utils/makePrettyDate";
import { generateSortKey } from "../utils/sortKeyHelpers";



export const fetchAppointmentsAndChats = async (
  partitionKey: string,
  sortKey: string
) => {
  const client = generateClient();
  const response = await client.graphql({
    query: getClearingEditDetails,
    variables: {
      limit: 1000000,
      filter: {
        and: [
          { PK: { eq: partitionKey } },
          { SK: { beginsWith: generateSortKey("CASE", sortKey, "APP") } },
        ],
      },
    },
  });
  return (response as GraphQlResponseList<ClearingEditSet>).data.listMains.items;
};


/*
The purpose and challenge of this component is to stack the data about the appointments
and chats into a hierachical format which fits immediately to the 
ClearingEdit dialogbox.  This structure is:
  appointment
    message
*/

const ClearingEditDataGet2 = async (sk: string) => {
  console.log("GGGGGGGGGGG sk: ", sk);
  const activeCase = extractElementNumber("CASE", sk)
  const activePatient: string = extractElementNumber("PAT", sk)
  console.log("GGGGGGGGGGG activeCase ", activeCase)

  const fetchPatientsDetails = async (partitionKey: string) => {
    const client = generateClient();

      const response = await client.graphql({
        query: getPatientsDetails,
        variables: {
          PK: partitionKey,
          SK: "PAT#",
        },
      }); 
      return (response as GraphQlResponse<PatientsDetails>).data.getMain;
  };

  const fetchCaseDetails = async (partitionKey: string) => {
    const client = generateClient();
    const response = await client.graphql({
      query: getCaseMaster,
      variables: {
        PK: partitionKey,
        SK: `CASE#${activeCase}##`,
      },
    }); 
    return (response as GraphQlResponse<Case>).data.getMain;
  };


  const fetchAndLogPatientDetails = async (patientKey: string) => {
    try {
      return await fetchPatientsDetails(patientKey);
    } catch (error) {
      console.error("Error fetching patient details: ", error);
    }
  };
  const fetchAndLogCaseDetails = async (patientKey: string) => {
    try {
      return await fetchCaseDetails(patientKey);
    } catch (error) {
      console.error("Error fetching case details: ", error);
    }
  };

  const patientMasterData = await fetchAndLogPatientDetails(activePatient);
  const caseMasterData = await fetchAndLogCaseDetails(activePatient);

  const sortKey = 'your_sort_key';
  const appointmentsWithChats: any[] = [];
  const appointmentsOnly: any[] = [];
 let combinedAppointments: any[] = [];

  try {
    combinedAppointments = await fetchAppointmentsAndChats(activePatient, activeCase);
    console.log("GGGGGGGGGGG combinedAppointments ", combinedAppointments);
  } catch (error) {
    console.error('GGGGGGGGGGG Error fetching combinedAppointments:', error);
  }

  console.log("GGGGGGGGGGG combinedAppointments ", combinedAppointments);

  /*
  Now we sort it. It is essential that the records are sorted in their hierarchy.
  appointment first then the underlying chats
  */
  combinedAppointments.sort((a, b) => {
    const isAParent = a.SK.endsWith("##");
    const isBParent = b.SK.endsWith("##");

    // If both are parent records or both are chat records, sort by SK
    if (isAParent === isBParent) {
      return a.SK.localeCompare(b.SK);
    }

    // If a is a parent and b is a chat, a should come first
    if (isAParent) {
      return -1;
    }

    // If b is a parent and a is a chat, b should come first
    return 1;
  });

  console.log("GGGGGGGGGGG sorted combinedAppointments ", combinedAppointments);

  /*
  From combinedAppointment we populate myAppointments, the target array.
  Here also clearingPotential is calculated based on the data comparison.
  */

  interface myAppointmentMessages {
    pk: string;
    sk: string;
    clearingStatus: string;
    clinicAccess: string;
    text: string;
    type: string;
    author: string;
    dateTime: string;
    addedToBilling: boolean;
  }

  interface myAppointments {
    pk: string;
    sk: string;
    clearingStatus: string;
    clinicAccess: string;
    lastClearingDecision: string;
    lastCommunication: string;
    clearingPotential: boolean;
    name: string;
    addedToBilling: boolean;
    date: string;
    messages: myAppointmentMessages[],
  }

  console.log("GGGGGGGGGGG combinedAppointments: ", combinedAppointments);
  const myAppointments: { [key: string]: myAppointments } = {};

  combinedAppointments.forEach((record) => {
    const appMatch = record.SK.match(/^(CASE#\d+#APP#\d+#)/)[0];
    if (appMatch) {
      // Handle the case where there is no match


    const appId: string = appMatch + "#";

    const isChatMessage = record.SK.match(/#CHAT#([0-9a-fA-F-]+)##$/) != null;
    console.log("GGGGGGGGGGG record.SK, isChatMessage ", record.SK, isChatMessage);

    const lastCommDate = convertToDateOrUseDefault(record.LastCommunication);
    const lastClearingDecisionDate = convertToDateOrUseDefault(
      record.LastClearingDecision
    );

    if (!myAppointments[appId]) {
      myAppointments[appId] = {
        pk: record.PK,
        sk: appId,
        clearingStatus: record.ClearingStatus || "nothing in record", // Use a default if not defined
        clinicAccess: record.ClinicAccess,
        lastClearingDecision: record.LastClearingDecision,
        lastCommunication: record.LastCommunication,
        clearingPotential: lastCommDate > lastClearingDecisionDate,
        name: record.Name,
        addedToBilling:
          record.ClearingStatus?.includes("cleared for appointment") || false,
        date: makePrettyDate(record.Date), // Date of appointment
        messages: [],
      };
    }
    console.log(
      "GGGGGGGGGGG Hierarchical Appointments appId, isChatMessage: ",
      appId,
      isChatMessage
    );
    if (isChatMessage) {
      console.log("GGGGGGGGGGG in chatMatch record: ", record);
      // It's a chat message, so add it to the messages array of the parent
      myAppointments[appId].messages.push({
        pk: record.PK,
        sk: record.SK,
        clearingStatus: record.ClearingStatus,
        clinicAccess: record.ClinicAccess,
        text: record.Message,
        type: record.Sender === "Patient" ? "" : "isOwn",
        author:
          record.Sender === "Patient" ? record.PatientName : record.UserName,
        dateTime: makePrettyDate(record.DateTime, "DateTime"),
        addedToBilling:
          record.ClearingStatus?.includes("cleared for chat") || false,
      });
    }
  }
  });

  // Convert the object to an array of values if needed
  const myAppointmentsArray = Object.values(myAppointments);

  console.log("GGGGGGGGGGG Hierarchical Appointments: ", myAppointmentsArray);


  console.log("GGGGGGGGGGG myAppointments: ", myAppointmentsArray);

  // Convert appointments object to array if needed
  const initialAppointmentsData = Object.values(myAppointmentsArray);
  console.log("GGGGGGGGGGG initialAppointmentsData: ", initialAppointmentsData);

  return {
    initialAppointmentsData,
    patientMasterData,
    caseMasterData
  };
};

export default ClearingEditDataGet2;
