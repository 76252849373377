import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

/* 
The components provides a first level of error message display to the user.
The error handling will be further enhanced in the course of the development.
*/


const ShowErrorModal = ({ open, handleClose, errorMessage, dialogTitle, errorInstruction }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                {errorInstruction} {errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ShowErrorModal;
