import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { dateFormat } from "../constants/date";
import { getPatientsDetails } from "../graphql-queries/customQueriesGraphQL";
import { GraphQlResponse, PatientsDetails } from "../graphql-queries/types";
import Frame from "./Frame";

export const fetchPatientsDetails = async (partitionKey: string) => {
  const client = generateClient();
  const response = await client.graphql({
    query: getPatientsDetails,
    variables: {
      PK: partitionKey,
      SK: "PAT#",
    },
  });

  return (response as GraphQlResponse<PatientsDetails>).data.getMain;
};

const PatientPanel = () => {
  const { partitionKey } = useParams();

  const {
    data: patientInfo,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["patients", partitionKey, "details"],
    queryFn: () =>
      fetchPatientsDetails(partitionKey ?? "missing partition key"),
  });

  const now = dayjs();
  const birthDate = dayjs(patientInfo?.DateOfBirth);

  const patientAge = now.diff(birthDate, "year");

  return (
    <div
      style={{
        padding: 6,
        backgroundColor: "#fff",
        borderRadius: 4,
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        fontFamily: "Arial", // Set your preferred font
      }}
    >
      <Frame isLoading={isLoading} isError={isError} entity="Details">
        {/* Patient's name */}
        <Typography
          variant="subtitle1"
          component="h2"
          style={{ fontWeight: "bold", marginBottom: 10, fontSize: "1rem" }}
        >
          {patientInfo?.PatientName}
        </Typography>
        {/* Section title */}
        <Typography
          variant="subtitle1"
          component="h2"
          style={{ fontWeight: "bold", fontSize: "0.8rem" }}
        >
          Stammdaten des Patienten
        </Typography>

          <List disablePadding>
          {/*  
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontSize: "0.8rem" }}>Alter / Geschlecht</span>
              }
              secondary={
                <span style={{ fontSize: "0.7rem" }}>{`${patientAge} Jahre / ${
                  patientInfo?.Salutation === "Frau" ? "weiblich" : "männlich"
                }`}</span>
              }
            />
          </ListItem>
       
            <Divider variant="inset" component="li" />        
            <ListItem>
            <ListItemText
              primary={<span style={{ fontSize: "0.8rem" }}>Geburtsdatum</span>}
              secondary={
                <span style={{ fontSize: "0.7rem" }}>
                  {birthDate.format(dateFormat)}
                </span>
              }
            />
          </ListItem> 
         

          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemText
              primary={
                <span style={{ fontSize: "0.8rem" }}>Telefonnummer</span>
              }
              secondary={
                <span style={{ fontSize: "0.7rem" }}>
                  {patientInfo?.Phone ?? "-"}
                </span>
              }
            />
          </ListItem>
       
          <Divider variant="inset" component="li" />
          */}  
          <ListItem>
            <ListItemText
              primary={<span style={{ fontSize: "0.8rem" }}>E-Mail</span>}
              secondary={
                <span style={{ fontSize: "0.7rem" }}>
                  {patientInfo?.EmailAddress ?? "-"}
                </span>
              }
            />
          </ListItem>
        </List>
      </Frame>
    </div>
  );
};

export default PatientPanel;
