export const getClearingList = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        PatientName
        InsuranceType
        Intervention
        ClearingStatus
      }
      nextToken
      __typename
    }
  }
`

export const getToDoList = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        PatientName
        Intervention
        Source
        DateTime
        Status
      }
      nextToken
      __typename
    }
  }
`

export const getPatients = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        PatientName
        Surname
        Phone
        Platform
        EmailAddress
        InsuranceType
        LastCommunication
        LastCase
      }
      nextToken
      __typename
    }
  }
`

export const getPatientsCases = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Intervention
        PatientName
        Date
        LastCommunication
      }
      nextToken
      __typename
    }
  }
`

export const getPatientsChatMessages = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        DateTime
        Sender
        Message
        ImageLocation
      }
      nextToken
      __typename
    }
  }
`

export const subCreateChatMessage = /* GraphQL */ `
  subscription OnCreateMain($filter: ModelSubscriptionMainFilterInput!) {
    onCreateMain(filter: $filter) {
      PK
      SK
      DateTime
      Sender
      Message
    }
  }
`

export const getPatientsAppointments = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Date
        Name
      }
      nextToken
      __typename
    }
  }
`

export const getPatientsDetails = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      PK
      SK
      ClinicAccess
      CounterAppointment
      CounterCase
      CounterChat
      CounterLog
      CounterNote
      PatientName
      Salutation
      DateOfBirth
      Phone
      EmailAddress
      InsuranceType
      DeviceToken
    }
  }
`

export const getCaseDetails = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      LastCommunication
      Intervention
    }
  }
`

export const getInstructionID = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      Name
    }
  }
`

export const getUser = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      PK
      SK
      City
      Clinic
      Country
      Description
      EmailAddress
      LinkToProfilePhoto
      UserName
      Role
      Street
      Title
      ZipCode
      Phone
    }
  }
`
export const getUsers = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Salutation
        Title
        UserName
        Role
        Description
      }
      nextToken
      __typename
    }
  }
`

export const getCaseMaster = /* GraphQL */ `
  query GetMain($PK: ID!, $SK: String!) {
    getMain(PK: $PK, SK: $SK) {
      PK
      SK
      Intervention
      PatientName
      Date
    }
  }
`

export const getCaseNames = /* GraphQL */ `
  query ListDentiProControls(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelDentiProControlFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDentiProControls(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Name
      }
      nextToken
      __typename
    }
  }
`

export const getPatientsNotes = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listMains(PK: $PK, SK: $SK, sortDirection: $sortDirection) {
      items {
        PK
        SK
        Text
        DateTime
      }
      nextToken
      __typename
    }
  }
`

export const createChatMessage = /* GraphQL */ `
  mutation CreateMain($input: CreateMainInput!) {
    createMain(input: $input) {
      PK
      SK
      ClinicAccess
      ClearingStatus
      ClearingID
      Date
      DateTime
      Message
      Sender
      UserName
      __typename
    }
  }
`

export const createClearingList = /* GraphQL */ `
  mutation CreateMain(
    $input: CreateMainInput!
    $condition: ModelMainConditionInput
  ) {
    createMain(input: $input, condition: $condition) {
      PK
      SK
      ClinicAccess
      ClearingStatus
      InsuranceType
      Intervention
      DateTime
      PatientName
      __typename
    }
  }
`

export const getClearingEditDetails = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        ClearingStatus
        ClinicAccess
        Date
        DateTime
        LastClearingDecision
        LastCommunication
        Message
        Name
        Sender
      }
      nextToken
      __typename
    }
  }
`

export const createTemplateQuery = /* GraphQL */ `
  mutation CreateMain($input: CreateMainInput!) {
    createMain(input: $input) {
      PK
      SK
      Text
      ClinicAccess
      UserIdDefined
    }
  }
`

export const updateTemplateQuery = /* GraphQL */ `
  mutation UpdateMain($input: UpdateMainInput!) {
    updateMain(input: $input) {
      PK
      SK
      Text
      ClinicAccess
      UserIdDefined
    }
  }
`

export const deleteTemplateQuery = /* GraphQL */ `
  mutation DeleteMain($input: DeleteMainInput!) {
    deleteMain(input: $input) {
      PK
      SK
      Text
      ClinicAccess
      UserIdDefined
    }
  }
`
export const getTemplates = /* GraphQL */ `
  query ListMains(
    $PK: ID
    $SK: ModelStringKeyConditionInput
    $filter: ModelMainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMains(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        Text
        UserIdDefined
        ClinicAccess
      }
      nextToken
      __typename
    }
  }
`

