import CloseIcon from '@mui/icons-material/Close'
import { DialogTitle, IconButton, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import React from 'react'
import ReactPlayer from 'react-player'

interface IVideoModalDialog {
  handleClose: () => void
  currentVideoUrl: string
  open: boolean
}

const VideoModalDialog = ({
  handleClose,
  currentVideoUrl,
  open,
}: IVideoModalDialog) => {
  const isSmallScreen = useMediaQuery('(max-height: 800px)')
  const maxHeight = isSmallScreen ? '70vh' : '90vh'

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
    >
      {/* Add padding inside the DialogTitle for the close button */}
      <DialogTitle sx={{ padding: 2, position: 'relative' }}>
        {/* Reposition the close button and adjust padding as necessary */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            padding: 0, // Adjust padding to make sure button doesn't take up too much space
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 0, margin: '0 auto' }}>
        {/* Remove padding at the top of the content to allow the video to be closer to the title */}
        <ReactPlayer
          url={currentVideoUrl}
          controls
          playing
          height={maxHeight}
          width="232px"
        />
      </DialogContent>
    </Dialog>
  )
}

export default VideoModalDialog
