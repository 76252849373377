import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import {
  Control,
  Controller,
  FieldArrayMethodProps,
  FieldArrayWithId,
  UseFieldArrayRemove,
} from "react-hook-form";

import { dentiColors } from "../constants/colors";
import { awsDateFormat, dateFormat } from "../constants/date";
import { CustomFormLabel } from "../pages/PatientRegistrationPage";

type ControlledDatePickerProps = {
  control: Control<any>;
  index: number;
  remove: UseFieldArrayRemove;
};

const ControlledDatePicker = ({
  control,
  index,
  remove,
}: ControlledDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={`appointments.${index}.date` as const}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
              padding: "5px 0",
            }}
          >
            <DatePicker
              sx={{ width: "100%", borderColor: dentiColors.baseDark }}
              minDate={dayjs()}
              format={dateFormat}
              label={`Termin ${index + 1}`}
              value={field.value !== null ? dayjs(field.value) : null}
              onChange={(value) =>
                value && field.onChange(value.format(awsDateFormat))
              }
            />
            <IconButton
              type="button"
              size="small"
              sx={{ color: dentiColors.warning, width: "60px" }}
              onClick={() => remove(index)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
          {!!error && (
            <FormHelperText error={!!error}>{error?.message}</FormHelperText>
          )}
          {field.value && dayjs(field.value).isBefore(dayjs(), 'day') && (
            <FormHelperText error>
            Der Termin darf nicht in der Vergangenheit sein!
            </FormHelperText>
)}

        </FormControl>
      )}
    />
  );
};

type FormAppointmentsListProps<T> = {
  interventionName: string;
  fields: FieldArrayWithId<
    {
      appointments?:
        | {
            date?: string | null | undefined;
            name: string;
          }[]
        | undefined;
    },
    "appointments",
    "id"
  >[];
  control: T;
  remove: UseFieldArrayRemove;
  append: (
    value:
      | {
          date?: string | null | undefined;
          name: string;
        }
      | {
          date?: string | null | undefined;
          name: string;
        }[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
};

const FormAppointmentsList = <T,>({
  interventionName,
  fields,
  control,
  append,
  remove,
}: FormAppointmentsListProps<T>) => {
  return (
    <Box
      component={FormControl}
      sx={{
        padding: "10px 20px",
        border: `solid 1px ${dentiColors.blueMedium}`,
        borderRadius: "8px",
      }}
    >
      <CustomFormLabel
        id="appointments-label"
        sx={{
          marginBottom: "15px",
        }}
      >
        Vergeben Sie einen Testplan für den Fall{" "}
        <span
          style={{
            color: dentiColors.accent,
          }}
        >
          {interventionName}
        </span>{" "}
        (optional) ?
      </CustomFormLabel>
      {fields.map((field, index) => (
        <ControlledDatePicker
          key={field.id}
          control={control as Control<any>}
          index={index}
          remove={remove}
        />
      ))}
      <Button
        startIcon={<AddIcon />}
        type="button"
        sx={{
          marginTop: "10px",
          color: dentiColors.baseDark,
          alignSelf: "start",
        }}
        onClick={() =>
          append({ date: dayjs().format(awsDateFormat), name: "Termin" })
        }
      >
        Termin hinzufügen
      </Button>
    </Box>
  );
};

export default FormAppointmentsList;
