import { generateClient } from 'aws-amplify/api'
import * as mutations from './graphql/mutations'
import { getToDoList } from './graphql-queries/customQueriesGraphQL'
import { GraphQlResponseList, ToDoListItem } from './graphql-queries/types'
import updateToDoListRecord from "./updateToDoListRecord";



/*
This function will initiate that all open todo records related to a given patient and 
user will be closed

*/

const fetchToDoList = async (beginWithFilter: string): Promise<ToDoListItem[]> => {
  const client = generateClient()
  const response = await client.graphql({
    query: getToDoList,
    variables: {
      limit: 1000000,
      filter: {
        SK: { beginsWith: beginWithFilter },
      },
    },
  }) as GraphQlResponseList<ToDoListItem>
  
  return response.data.listMains.items
}



async function closeToDoListRecordsByPatient(clinic: string, patientKey: string, userKey: string) {
  const currentTimestamp = new Date().toISOString()
  const client = generateClient()
  console.log('PPPPPPPPPPP closeToDoListRecordsByPatient/ start')
  console.log('PPPPPPPPPPP clinic, patientKey, userKey ', clinic, patientKey, userKey)

  try {
    const beginWithFilter = `TYP#ToDo#USER#${userKey}#PAT#${patientKey}#STS#Open#`  
    const toDoListItems = await fetchToDoList(beginWithFilter)
    console.log('PPPPPPPPPPP toDo List ', toDoListItems)

    
    // Perform actions with the to-do list items
    for (const item of toDoListItems) {
      console.log(`PPPPPPPPPPP oDo List Loop ${item.SK}`)
      const newSK = item.SK.replace("Open", "Closed");
      const newStatus = "Closed"
  
      updateToDoListRecord(item.PK, item.SK, newSK, newStatus)
      .then(() => {
        console.log('TTTTTTT ToDoList record updated successfully with status:', newStatus);
      })
      .catch(error => console.error('TTTTTTT Error for ToDoList record update error:', error));
    }

  } catch (error) {
    console.error('Error fetching or updating to-do list items:', error)
  }
}
export default closeToDoListRecordsByPatient

