import { SxProps, Theme } from '@mui/material/styles'

export const getPaperStyles = (sender: string): SxProps<Theme> => ({
  maxWidth: '70%',
  backgroundColor: sender !== 'Patient' ? '#e0f2f1' : '#e3ecf8',
  borderRadius: '10px',
  borderTopLeftRadius: sender !== 'Patient' ? '10px' : '0px',
  borderTopRightRadius: sender !== 'Patient' ? '0px' : '10px',
  padding: '10px',
  margin: '10px',
  marginBottom: '5px',
  marginLeft: sender !== 'Patient' ? 'auto' : undefined,
  marginRight: sender !== 'Patient' ? undefined : 'auto',
  textAlign: 'left',
})
