import './styles/index.css'

import { withAuthenticator } from '@aws-amplify/ui-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import awsExports from './aws-exports'
import reportWebVitals from './reportWebVitals'
Amplify.configure(awsExports)

const queryClient = new QueryClient()

const WrappedApp = withAuthenticator(App)

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <WrappedApp /> {/* No props needed */}
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
