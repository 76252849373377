import { generateClient } from "aws-amplify/api";
import { createMain as createMainMutation } from "./graphql/mutations";
import { generateSortKey2 } from "./utils/sortKeyHelpers";
import { updateMainTable } from "./updateMainTable";
import { postSingleAppointment } from "./postSingleAppointment";

/* 
This function gets data for a set with :
  appointments

On this level, it will execute the posting of the  appointment data 
In this way, we can use these modules independently.

As the patient's PK, we use a uuid
*/

export async function postAppointments(appointmentData) {
  console.log(`PaPaPaPaPaPa postAppointments start appointmentData: `, appointmentData);

  const myCaseNumber = appointmentData.activeCase
  var myAppointmentNumber = appointmentData.counterAppointment
  const appointmentSK = generateSortKey2("CASE", String(myCaseNumber));

  for (const appointment of appointmentData.appointments) {
    if (appointment.date) {
      console.log(`PaPaPaPaPaPa postAppointments in loop appointment: `, appointment);
      try {
        myAppointmentNumber = myAppointmentNumber + 1
        const singleAppointmentData = {
          pk: appointmentData.pk,
          activeCase: appointmentData.activeCase,
          appointmentNumber: myAppointmentNumber,
          relevantClinic: appointmentData.relevantClinic,
          appointment: appointment,
          userIdDefined: appointmentData.userIdDefined
        };
        console.log("PaPaPaPaPaPa singleAppointmentData:", singleAppointmentData);

        
        await postSingleAppointment(singleAppointmentData);
        // Handle successful submission, e.g., clearing the form, showing a message, etc.
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error("There was an error posting the appointments", error);
        throw error;
      }
    }
  }
}
