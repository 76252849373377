// getCurrentMonthAndQuarter.js

function getCurrentMonthAndQuarter() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Adding 1 because getMonth() returns 0-11
    const quarter = Math.ceil(month / 3);

    const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`;
    const formattedQuarter = `${year}-Q${quarter}`;

    return { formattedMonth, formattedQuarter };
}
export default getCurrentMonthAndQuarter;
  