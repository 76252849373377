import { generateClient } from 'aws-amplify/api'

import { createMain as createMainMutation } from './graphql/mutations'
import { updateMainTable } from './updateMainTable'
import { generateSortKey2 } from './utils/sortKeyHelpers'

/*  
This function gets data for a set with :
  notes

As the patient's PK, we use a uuid
*/

export async function postNote(notesData) {
  console.log(`PnPnPnPnPnPn postNote start notesData: `, notesData)

  var notesSK = ''
  if (notesData.noteType === 'caserelevant') {
    notesSK = generateSortKey2(
      'NOTES',
      String(notesData.counterNote),
      'CASE',
      String(notesData.counterCase),
    )
  } else {
    notesSK = generateSortKey2('NOTES', String(notesData.counterNote))
  }

  console.log('PnPnPnPnPnPn  postNote  notesSK:', notesSK)

  let insertData
  try {
    const client = generateClient()
    insertData = {
      PK: notesData.PK,
      SK: notesSK,
      ClinicAccess: notesData.clinicAccess,
      Text: notesData.notes,
      DateTime: new Date().toISOString(),
      UserIdDefined: notesData.userIdDefined,
    }
    console.log('PaPaPaPaPaPa  insert fields insertData:', insertData)

    const response = await client.graphql({
      query: createMainMutation,
      variables: { input: insertData },
    })
    console.log(
      'PaPaPaPaPaPa in createMainTable inserted:',
      response.data.createMain,
    )

    /*
We update the patient master record with the counterCase
*/

    try {
      await updateMainTable(
        notesData.PK,
        'PAT#',
        'CounterNote',
        String(notesData.counterNote),
      )
    } catch (error) {
      console.error(
        'There was an error updating the patient master record',
        error,
      )
      throw error
    }
  } catch (error) {
    console.error(
      'PaPaPaPaPaPa in createMainTable / Error inserting Note:',
      error,
    )

    // Assuming 'error' is an object that contains an 'errors' array
    if (
      error.errors &&
      error.errors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
    ) {
      throw new Error('Notes entry already exists')
    }
    throw error // Rethrow the original error if it's not the conditional check error
  }
}
