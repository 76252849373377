import AddSharpIcon from '@mui/icons-material/AddSharp'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { IInput } from '../../types/types'
import { TemplateSettingsProps } from './TemplateSettings.types'
import { DisplaiyngTemplateType } from '../ChatMessages'

import { styles } from './TemplateSettings.styles'
import BlackPin from '../../icons/BlackPin'
import GreyPin from '../../icons/GreyPin'
import DeleteIcon from '../../icons/DeleteIcon'

export function TemplateSettings(
  props: NonNullable<TemplateSettingsProps>,
): React.JSX.Element {
  const [openTemplateChanging, setOpenTemplateChanging] = useState(false)
  const [openTemplateCreation, setOpenTemplateCreation] = useState(false)
  const [createTemplateInput, setCreateTemplateInput] = useState('')
  const [changeTemplateInput, setChangeTemplateInput] = useState('')
  const [templateToUpdate, setTemplateToUpdate] = useState<
    DisplaiyngTemplateType | undefined
  >()
  const [frequent, setFrequent] = useState(false)

  const handleCreationInputChange = (event: IInput) => {
    setCreateTemplateInput(event.target.value)
  }

  const handleChangingInputChange = (event: IInput) => {
    setChangeTemplateInput(event.target.value)
  }

  const handleSaveTemplate = async (isNew: boolean) => {
    if (isNew) {
      props.createMutation(createTemplateInput, frequent ? 'HAVE' : 'NO')
      setOpenTemplateCreation(false)
    } else {
      props.updateMutation(templateToUpdate, createTemplateInput)
      setOpenTemplateChanging(false)
    }
  }

  const handleDeleteClick = async (template: DisplaiyngTemplateType) => {
    if (template.favorit) {
      props.deleteMutation(template.template, 'HAVE')
    } else props.deleteMutation(template.template, 'NO')
  }

  const handleTogglePin = async (template: DisplaiyngTemplateType) => {
    if (template.favorit) {
      await props.deleteMutation(template.favorit, 'IS')
    } else {
      props.pinFunc(template)
    }
  }

  return (
    <Dialog
      open={props.open}
      sx={{ borderRadius: '100px' }}
    >
      <DialogTitle>Template settings</DialogTitle>
      <DialogContent
        sx={{
          display:
            openTemplateChanging || openTemplateCreation ? 'none' : 'block',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ padding: '6px', paddingX: '8px' }}>
            Your list of all templates
          </Typography>
          <Button
            sx={styles.button}
            onClick={() => setOpenTemplateCreation(true)}
          >
            <AddSharpIcon sx={{ height: '16px', width: '16px' }} />
            <Typography>Create a template</Typography>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: '100%' }}>
            {props.displayingTemplates?.map((template) => {
              return (
                <Box
                  key={template.template.SK}
                  sx={{
                    display: template.display ? 'flex' : 'none',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '10px',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      color: 'black',
                      background: '#F5F7FA',
                      borderRadius: '15px',
                      cursor:
                        template.template.UserIdDefined !== props.user.PK
                          ? 'default'
                          : 'pointer',
                      display: 'flex',
                      alignContent: 'center',
                      flexWrap: 'wrap',
                      width: '511px',
                    }}
                    id={template.template.SK}
                    key={template.template.SK}
                  >
                    {/* Black Pin button start*/}
                    <IconButton
                      sx={styles.pin}
                      onClick={() => handleTogglePin(template)}
                    >
                      <SvgIcon>
                        {template.favorit ? <BlackPin /> : <GreyPin />}
                      </SvgIcon>
                    </IconButton>

                    <Typography
                      align="left"
                      sx={{
                        padding: '10px',
                        lineHeight: '21px',
                        fontSize: '14px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        gap: '7px',
                        width: '98%',
                      }}
                      id={template.template.SK}
                      onClick={() => {
                        if (template.template.UserIdDefined === props.user.PK) {
                          setTemplateToUpdate(template)
                          setChangeTemplateInput(template.template.Text)
                          setOpenTemplateChanging(true)
                        }
                      }}
                    >
                      {template.template.Text}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      handleDeleteClick(template)
                    }}
                    sx={{
                      width: '40px',
                      height: '40px',
                      display:
                        template.template.UserIdDefined === props.user.PK
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )
            })}
          </Box>
          <Button
            variant="outlined"
            sx={{
              width: '100%',
              border: '2px solid black',
              borderRadius: '8px',
              color: 'black',
              marginY: '20px',
              ':hover': {
                color: 'white',
                background: 'black',
                border: '2px solid white',
              },
            }}
            onClick={() => {
              props.openState(false)
            }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
      <DialogContent sx={{ display: openTemplateCreation ? 'block' : 'none' }}>
        <Typography sx={{ padding: '6px', paddingX: '8px' }}>
          Create a template
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter text..."
          value={createTemplateInput}
          onChange={handleCreationInputChange}
          variant="outlined"
          inputProps={{
            style: { fontSize: '14px', height: '145px', width: '508px' },
          }}
          multiline
        />
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px',
            alignContent: 'center',
            gap: '5px',
          }}
        >
          <Checkbox
            checked={frequent}
            onChange={() => {
              setFrequent(!frequent)
            }}
            sx={{ height: '22px', width: '22px' }}
          />
          <Typography
            sx={{ fontSize: '14px', lineHeight: '21px', marginTop: '3px' }}
          >
            Frequent
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '250px',
              height: '40px',
              border: '2px solid black',
              borderRadius: '8px',
              color: 'black',
              marginY: '20px',
              ':hover': {
                color: 'white',
                background: 'black',
                border: '2px solid white',
              },
            }}
            onClick={() => {
              setOpenTemplateCreation(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: '250px',
              height: '40px',
              borderRadius: '8px',
              background: 'black',
              color: 'white',
              marginY: '20px',
              ':hover': {
                color: 'black',
                background: 'white',
                border: '2px solid black',
              },
            }}
            onClick={() => handleSaveTemplate(true)}
          >
            Create template
          </Button>
        </Box>
      </DialogContent>
      <DialogContent sx={{ display: openTemplateChanging ? 'block' : 'none' }}>
        <Typography sx={{ padding: '6px', paddingX: '8px' }}>
          Change your template
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter text..."
          value={changeTemplateInput}
          onChange={handleChangingInputChange}
          variant="outlined"
          inputProps={{
            style: { fontSize: '14px', height: '145px', width: '508px' },
          }}
          multiline
        />
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px',
            alignContent: 'center',
            gap: '5px',
          }}
        >
          <Checkbox
            checked={frequent}
            onChange={() => {
              setFrequent(!frequent)
            }}
            sx={{ height: '22px', width: '22px' }}
          />
          <Typography
            sx={{ fontSize: '14px', lineHeight: '21px', marginTop: '3px' }}
          >
            Frequent
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '250px',
              height: '40px',
              border: '2px solid black',
              borderRadius: '8px',
              color: 'black',
              marginY: '20px',
              ':hover': {
                color: 'white',
                background: 'black',
                border: '2px solid white',
              },
            }}
            onClick={() => {
              setOpenTemplateChanging(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{
              width: '250px',
              height: '40px',
              borderRadius: '8px',
              background: 'black',
              color: 'white',
              marginY: '20px',
              ':hover': {
                color: 'black',
                background: 'white',
                border: '2px solid black',
              },
            }}
            onClick={() => handleSaveTemplate(false)}
          >
            Save changes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
