import EastIcon from "@mui/icons-material/East";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../App";
import Frame from "../components/Frame";
import NotesSection from "../components/NotesSection";
import PatientPanel from "../components/PatientPanel";
import { getPatientsCases } from "../graphql-queries/customQueriesGraphQL";
import { Case,GraphQlResponseList } from "../graphql-queries/types";
import makePrettyDate from "../utils/makePrettyDate";
import { extractSortKey } from "../utils/sortKeyHelpers";

/*
const columns: GridColDef<Case>[] = [
  { field: "Intervention", headerName: "Eingriff", width: 200 },
  { field: "PatientName", headerName: "Name", width: 200 },
  {
    field: "LastCommunication",
    headerName: "Eingriff stattgefunden am:",
    width: 200,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{dayjs(params.row.Date, "00:00").format(dateFormat)}</span>
      </div>
    ),
  },
];
*/

export const fetchPatientsCases = async (partitionKey: string) => {
  const client = generateClient();
  const response = await client.graphql({
    query: getPatientsCases,
    variables: {
      limit: 1000000,
      filter: {
        and: [
          { PK: { eq: partitionKey } },
          { SK: { beginsWith: "CASE" } },
          { SK: { notContains: "APP" } },
        ],
      },
    },
  });
  return (response as GraphQlResponseList<Case>).data.listMains.items;
};

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$ PatientsCasesPage $$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
const PatientsCasesPage = () => {
  const navigate = useNavigate();
  const { partitionKey } = useParams(); // This gets the dynamic part of the URL

  if (!partitionKey) {
    throw new Error("Partition Key is missing");
  }

  const { setPageTitle } = useContext(AppContext);
  const { setPatientName } = useContext(AppContext);

  const {
    data: cases,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["patients", partitionKey, "cases"],
    queryFn: () => fetchPatientsCases(partitionKey),
  });
  console.log("DDDDDDDDDDD after fetch cases ", cases);

  React.useEffect(() => {
    if (cases && cases.length > 0) {
      const patientName = cases[0].PatientName; 
      setPatientName(patientName); 
      handleTitleChange(`Patienten >> ${patientName}`); 
    }
  }, [cases]); // Add `cases` to the dependency array to re-run this effect when `cases` changes
  

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle);
  };
  

  const handleCaseButtonClick = () => {
    navigate(`/patients/${partitionKey}/cases/register-case`);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
      </Box>
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Button
          variant="outlined" // change variant to "outlined"
          onClick={() => handleCaseButtonClick()}
          sx={{
            textTransform: 'none',
            color: "black", // Text color
            borderColor: "black", // Border color
            "&:hover": {
              borderColor: "black", // Border color on hover
              backgroundColor: "white", // Background color on hover
            },
          }}
        >
          + Neuen Fall hinzufügen
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Frame entity="Fälle" isError={isError} isLoading={isLoading}>
            {cases && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "15px",
                }}
              >
                {cases?.map((item) => {
                  return (
                    <Box
                      key={item.SK}
                      sx={{
                        padding: 3,
                        border: "2px solid #f3f5f6",
                        borderRadius: "6px",
                        width: "calc(50% - 15px)",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={require("../icons/tooth.jpg")}
                          alt="Tooth"
                          style={{
                            border: "1px solid #f3f5f6",
                            borderRadius: "50%",
                            padding: "10px",
                            margin: "5px",
                          }}
                        />
                        <Typography sx={{ fontWeight: "bold" }}>
                          {item.Intervention}
                        </Typography>
                      </Box>
                      <Divider
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                      ></Divider>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography sx={{ color: "text.secondary" }}>
                            {item.PatientName}
                          </Typography>
                          <Typography sx={{ color: "text.secondary" }}>
                            Eingriff stattgefunden am:{" "}
                            {makePrettyDate(item.Date)}
                          </Typography>
                        </Box>
                        <IconButton
                          sx={{ border: "2px solid #f3f5f6" }}
                          onClick={() => {
                            navigate(`${extractSortKey("CASE", item.SK)}`);
                          }}
                        >
                          <EastIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Frame>
        </Grid>
        <Grid item xs={4}>
          <PatientPanel />
          <NotesSection activeCase={""} />
        </Grid>
      </Grid>
    </>
  );
};

export default PatientsCasesPage;
