import AddIcon from "@mui/icons-material/Add";
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  CssBaseline,
  Toolbar,
  Typography,
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import React, { ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../App";
import { pageRoutes } from "../constants/routes";
import SideNavigation from "./SideNavigation";

type AppLayoutProps = {
  children?: ReactNode;
};
const drawerWidth = 240;

const AppLayout = ({ children }: AppLayoutProps) => {
  const navigate = useNavigate();
  const { user, pageTitle } = useContext(AppContext);
  console.log("$$$$$$$$ pageTitle:", pageTitle);

  const titleSegments = pageTitle.split(" >> ");

  // Click handler for each segment
  const handleTitleClick = (index: number) => {
    // If it's the first segment, go to the patients page
    if (index === 0) {
      navigate("/patients");
    } else if (index === titleSegments.length - 1) {
      // If it's the last segment, do nothing
      return;
    } else {
      // Go back to the previous page
      navigate(-1);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ zIndex: 50, borderBottom: 1, borderColor: "divider" }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          {/* Place the pageTitle on the left side */}
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: `${drawerWidth}px`,
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {titleSegments.map((segment, index) => (
              <React.Fragment key={index}>
                {index < titleSegments.length - 1 ? (
                  // Apply the ButtonBase only if the segment is clickable
                  <ButtonBase onClick={() => handleTitleClick(index)}>
                    <Typography
                      variant="inherit"
                      sx={{ color: "primary.main", margin: "0 4px" }}
                    >
                      {segment}
                    </Typography>
                  </ButtonBase>
                ) : (
                  // Render the last segment as non-clickable
                  <Typography
                    variant="inherit"
                    sx={{ color: "text.primary", margin: "0 4px" }}
                  >
                    {segment}
                  </Typography>
                )}
                {index < titleSegments.length - 1 && (
                  // Separator with explicit spaces and primary color
                  <Typography variant="inherit" sx={{ color: "primary.main" }}>
                    {">>"}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Typography>

          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate(pageRoutes.patientRegister)}
          >
            Patienten hinzufügen
          </Button>
        </Toolbar>
      </AppBar>
      <SideNavigation />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default AppLayout;
