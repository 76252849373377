// NoteRegistrationStep.tsx
import { Box, FormControl, FormControlLabel, Radio,RadioGroup, TextField } from '@mui/material';
import React from 'react';

interface NoteRegistrationStepContentProps {
    notes: string;
    handleNotesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    noteType: string;
    handleNoteTypeChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    scopeSelection?: boolean;
}

const NoteRegistrationStepContent: React.FC<NoteRegistrationStepContentProps> = ({
    notes,
    handleNotesChange,
    noteType,
    handleNoteTypeChange,
    scopeSelection = true,
}) => {
    return (
        <Box>
            <TextField
                fullWidth
                label="Notizen hinzufügen (optional)"
                margin="normal"
                value={notes}
                onChange={handleNotesChange}
                placeholder="Notizen eingeben..."
                multiline
                rows={4}
            />
            {scopeSelection && ( // Only render this section if scopeSelection is true
                <FormControl component="fieldset" margin="normal">
                    <RadioGroup
                        row
                        aria-label="noteType"
                        name="noteType"
                        value={noteType}
                        onChange={handleNoteTypeChange}
                    >
                        <FormControlLabel
                            value="patientrelevant"
                            control={<Radio />}
                            label="Generell für den Patienten"
                        />
                        <FormControlLabel
                            value="caserelevant"
                            control={<Radio />}
                            label="Bezogen auf den Fall"
                        />
                    </RadioGroup>
                </FormControl>
            )}
        </Box>
    );

};

export default NoteRegistrationStepContent;
