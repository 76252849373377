import React from 'react'

const GreyPin = () => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8693 4.44979L9.55028 1.13135C9.46903 1.05008 9.37256 0.98561 9.26638 0.941625C9.16021 0.897639 9.04641 0.875 8.93149 0.875C8.81657 0.875 8.70277 0.897639 8.5966 0.941625C8.49042 0.98561 8.39396 1.05008 8.3127 1.13135L5.37981 4.07354C4.79684 3.89088 3.46575 3.67049 2.07669 4.79213C1.98094 4.86912 1.90248 4.96541 1.84642 5.07473C1.79035 5.18405 1.75794 5.30395 1.75128 5.42663C1.74463 5.54931 1.76388 5.67201 1.80779 5.78675C1.8517 5.9015 1.91929 6.00571 2.00614 6.0926L4.64864 8.73401L2.31567 11.0653C2.23358 11.1474 2.18746 11.2588 2.18746 11.3749C2.18746 11.491 2.23358 11.6023 2.31567 11.6844C2.39776 11.7665 2.50911 11.8126 2.6252 11.8126C2.7413 11.8126 2.85264 11.7665 2.93473 11.6844L5.26606 9.35143L7.90692 11.9923C7.98811 12.0738 8.08457 12.1385 8.1908 12.1827C8.29703 12.2269 8.41093 12.2497 8.52598 12.2499C8.54676 12.2499 8.567 12.2499 8.58778 12.2499C8.71251 12.2412 8.83392 12.2058 8.94376 12.1461C9.0536 12.0864 9.14929 12.0037 9.22434 11.9037C10.2984 10.4764 10.195 9.31588 9.94567 8.62245L12.8698 5.68737C12.951 5.60608 13.0155 5.50958 13.0594 5.40339C13.1034 5.2972 13.1259 5.18339 13.1259 5.06847C13.1258 4.95354 13.1031 4.83975 13.0591 4.7336C13.0151 4.62745 12.9506 4.53101 12.8693 4.44979ZM12.2502 5.06885L9.11825 8.2112C9.05357 8.27612 9.01091 8.35971 8.99629 8.45018C8.98167 8.54065 8.99583 8.63342 9.03676 8.71542C9.55411 9.75065 8.93833 10.8258 8.52598 11.3743L2.6252 5.47299C3.28583 4.94034 3.91801 4.79979 4.40145 4.79979C4.70821 4.7956 5.01243 4.85578 5.2945 4.97643C5.37679 5.01761 5.46997 5.0318 5.56079 5.01698C5.65161 5.00215 5.73544 4.95907 5.80036 4.89385L8.93176 1.74987L12.2502 5.06831V5.06885Z"
        fill="#010202"
      />
    </svg>
  )
}

export default GreyPin
