import React from 'react'

const DeleteIcon = () => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 4.375H3.125C2.95924 4.375 2.80027 4.44085 2.68306 4.55806C2.56585 4.67527 2.5 4.83424 2.5 5C2.5 5.16576 2.56585 5.32473 2.68306 5.44194C2.80027 5.55915 2.95924 5.625 3.125 5.625H3.75V16.875C3.75 17.2065 3.8817 17.5245 4.11612 17.7589C4.35054 17.9933 4.66848 18.125 5 18.125H15C15.3315 18.125 15.6495 17.9933 15.8839 17.7589C16.1183 17.5245 16.25 17.2065 16.25 16.875V5.625H16.875C17.0408 5.625 17.1997 5.55915 17.3169 5.44194C17.4342 5.32473 17.5 5.16576 17.5 5C17.5 4.83424 17.4342 4.67527 17.3169 4.55806C17.1997 4.44085 17.0408 4.375 16.875 4.375ZM15 16.875H5V5.625H15V16.875ZM6.25 2.5C6.25 2.33424 6.31585 2.17527 6.43306 2.05806C6.55027 1.94085 6.70924 1.875 6.875 1.875H13.125C13.2908 1.875 13.4497 1.94085 13.5669 2.05806C13.6842 2.17527 13.75 2.33424 13.75 2.5C13.75 2.66576 13.6842 2.82473 13.5669 2.94194C13.4497 3.05915 13.2908 3.125 13.125 3.125H6.875C6.70924 3.125 6.55027 3.05915 6.43306 2.94194C6.31585 2.82473 6.25 2.66576 6.25 2.5Z"
        fill="#BCBCBC"
      />
    </svg>
  )
}

export default DeleteIcon
