import AddIcon from "@mui/icons-material/Add";
import {
  Button, // Add this line to import Button
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  // ... any other imports you have
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { generateClient } from "aws-amplify/api";
import React, { useContext,useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../App";
import { getPatientsNotes } from "../graphql-queries/customQueriesGraphQL";
import { GraphQlResponseList, PatientsNotes } from "../graphql-queries/types";
import { postNote } from "../postNote";
import makePrettyDate from "../utils/makePrettyDate";
import Frame from "./Frame";
import NoteRegistrationStepContent from "./NoteRegistrationStep";
import { fetchPatientsDetails } from "./PatientPanel";

const fetchPatientsNotes = async (partitionKey: string) => {
  const client = generateClient();
  const response = await client.graphql({
    query: getPatientsNotes,
    variables: {
      PK: partitionKey,
      SK: { beginsWith: "NOTES#" },
    },
  });

  const notes = (
    response as GraphQlResponseList<PatientsNotes>
  ).data.listMains.items.map((note) => ({
    ...note,
    type: note.SK.includes("CASE#") ? "caserelevant" : "patientrelevant",
  }));

  return notes;
};

interface NotesSectionProps {
  activeCase: string;
}

const NotesSection = ({ activeCase }: NotesSectionProps) => {
  console.log(`NNNNNNNNNNN start activeCase: `, activeCase);

  const { user } = useContext(AppContext);
  const [newNote, setNewNote] = useState("");
  const [noteType, setNoteType] = useState("general");
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [refreshNotes, setRefreshNotes] = useState(0);
  const [count, setCount] = useState(1);

  const refresh = () => {
    setRefreshNotes((prev) => prev + 1);
  };

  const { partitionKey } = useParams();
  const {
    data: notes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["patient", partitionKey, "notes", refreshNotes],
    queryFn: () => fetchPatientsNotes(partitionKey ?? "missing partition key"),
  });

  const { data: patientInfo } = useQuery({
    queryKey: ["patients", partitionKey, "details"],
    queryFn: () =>
      fetchPatientsDetails(partitionKey ?? "missing partition key"),
  });

  const counterNoteValue = patientInfo?.CounterNote;

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote(event.target.value);
  };

  const handleNoteTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoteType(event.target.value);
  };

  const handleAddNoteClick = () => {
    setIsAddingNote(true);
  };

  const handleSaveNote = async () => {
    if (!newNote.trim()) {
      setIsAddingNote(false); // This should hide the input box
      setNewNote(""); // Clear the newNote state
      return; // Exit the function early
    }

    console.log(`NNNNNNNNNNN patientInfo: `, patientInfo);
    const counterNoteValue = patientInfo?.CounterNote;
    setCount(count + 1);
    const notesData = {
      PK: patientInfo?.PK,
      counterCase: activeCase,
      clinicAccess: patientInfo?.ClinicAccess,
      counterNote: String(Number(counterNoteValue) + count),
      notes: newNote,
      noteType: noteType,
      userIdDefined: user?.PK,
    };
    console.log(`NNNNNNNNNNN notesData: `, notesData);
    try {
      await postNote(notesData);
      setNewNote("");
      setIsAddingNote(false);
      refresh();
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("There was an error posting the notes data", error);
      throw error;
    }
    setNewNote("");
    setIsAddingNote(false);
  };

  console.log(`NNNNNNNNNNN before render notes: `, notes);
  const filteredNotes = (notes ?? []).filter((note) => {
    // Check if 'SK' field does not contain 'CASE' or if it contains the specific active case
    if (activeCase !== "") {
      return (
        !note.SK.includes("CASE#") || note.SK.includes(`CASE#${activeCase}##`)
      );
    }
    return notes;
  });
  console.log(`NNNNNNNNNNN before render filteredNotes: `, filteredNotes);
  return (
    <Frame isLoading={isLoading} isError={isError} entity="Notizen">
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <Typography
          variant="subtitle1"
          component="h3"
          style={{ fontWeight: "bold", fontSize: "1rem", flexGrow: 1 }}
        >
          Notizen
        </Typography>
        <IconButton
          size="small"
          onClick={handleAddNoteClick} // Attach the event handler
        >
          <AddIcon />
        </IconButton>
      </div>

      {isAddingNote && ( // Conditional rendering based on isAddingNote state
        <>
          <NoteRegistrationStepContent // Your existing component for adding a note
            notes={newNote}
            handleNotesChange={handleNotesChange}
            noteType={noteType}
            handleNoteTypeChange={handleNoteTypeChange}
            scopeSelection={activeCase !== ""}
          />
          <Button // Button to save the new note
            onClick={handleSaveNote}
            variant="contained"
            color="primary"
          >
            SPEICHERN
          </Button>
        </>
      )}

      <Paper style={{ maxHeight: 200, overflowY: "auto", padding: "0 16px" }}>
        <List disablePadding>
          {filteredNotes?.map((note, index) => (
            <ListItem
              key={index}
              style={{
                backgroundColor:
                  note.type === "patientrelevant" ? "#fffacd" : "#f0f0f0",
                margin: "10px 0",
                borderRadius: 4,
              }}
            >
              <ListItemText
                //          primary={dayjs(note.DateTime).format(dateFormat)}
                primary={makePrettyDate(note.DateTime, "DateTime")}
                secondary={note.Text}
                primaryTypographyProps={{
                  style: { fontSize: "0.8rem", whiteSpace: "pre-line" },
                }}
                secondaryTypographyProps={{ style: { fontSize: "0.9rem" } }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Frame>
  );
};

export default NotesSection;
