import styled from "@emotion/styled";
import React from "react";

import Logo from "../icons/Logo";

const LogoWrapper = styled("div")(() => ({
  maxWidth: "100px",
  maxHeight: "35px",
  display: "grid",
  placeItems: "start",
  alignItems: "start",
  gridTemplate: `
                "logo header header"
                "logo sub sub"    
                `,
}));

const LogoBanner = () => {
  return (
    <LogoWrapper>
      <Logo
        height={30}
        width={30}
        style={{ gridArea: "logo", marginRight: "5px" }}
      />
      <span style={{ fontSize: "16px", fontWeight: 600, gridArea: "header" }}>
        dentinostic
      </span>
      <span
        style={{
          fontSize: "12px",
          fontWeight: 400,
          gridArea: "sub",
          marginTop: -10,
        }}
      >
        professional
      </span>
    </LogoWrapper>
  );
};

export default LogoBanner;
