import { List, ListItem, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import React from 'react'
import { IChatMessagesList } from './ChatMessagesList.types'
import { getPaperStyles } from '../../utils/getPaperStyles'
import makePrettyDate from '../../utils/makePrettyDate'
import { ChatMessage } from '../../graphql-queries/types'
import handlePatientMessage from '../../utils/handlePatientMessage'
import TemplateSectionUnderEachChatPanel from '../TemplateSectionUnderEachChatPanel/TemplateSectionUnderEachChatPanel'
import { isToday } from '../../utils/makePrettyDate'
const ChatMessagesList = ({
  chatRef,
  messages,
  templatesTexts,
  handleVorlage,
  handleVideoClick,
}: IChatMessagesList) => {
  return (
    <List
      ref={chatRef}
      sx={{
        width: '100%',
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
      }}
    >
      {messages?.map((message) => {
        return (
          <ListItem
            key={`${message.PK}-${message.SK}`}
            alignItems="flex-start"
            sx={{
              display: 'flex',
              justifyContent:
                message.Sender !== 'Patient' ? 'flex-end' : 'flex-start',
              padding: 0,
              flexDirection: 'column',
            }}
          >
            <Paper
              elevation={1}
              sx={getPaperStyles(message.Sender)}
            >
              {handlePatientMessage(
                message.Message,
                message.ImageLocation,
                handleVideoClick,
              )}
              <BelowChatDialogBlock1 message={message} />
              <BelowChatDialogBlock2 message={message} />
              <BelowChatDialogBlock3 message={message} />
              <BelowChatDialogBlock4 message={message} />
            </Paper>

            <TemplateSectionUnderEachChatPanel
              templatesTexts={templatesTexts}
              message={message}
              handleVorlage={handleVorlage}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default ChatMessagesList

const BelowChatDialogBlock1: React.FC<{ message: ChatMessage }> = ({
  message,
}) => {
  return (
    <Typography
      variant="caption"
      sx={{
        fontSize: '13px',
        color: 'text.secondary',
        display:
          message.Sender === 'Patient' && message.ImageLocation ? '' : 'none',
      }}
    >
      Beantwortet am:{' '}
    </Typography>
  )
}

const BelowChatDialogBlock2: React.FC<{ message: ChatMessage }> = ({
  message,
}) => {
  return (
    <Typography
      variant="caption"
      sx={{
        fontSize: '13px',
        color: 'text.secondary',
        display:
          message.Sender === 'Patient' &&
          message.ImageLocation === '' &&
          !isToday(new Date(message.DateTime))
            ? ''
            : 'none',
      }}
    >
      Frage vom:{' '}
    </Typography>
  )
}
const BelowChatDialogBlock3: React.FC<{ message: ChatMessage }> = ({
  message,
}) => {
  return (
    <Typography
      variant="caption"
      sx={{
        fontSize: '13px',
        color: 'text.secondary',
        display:
          message.Sender === 'Patient' &&
          message.ImageLocation === '' &&
          isToday(new Date(message.DateTime))
            ? ''
            : 'none',
      }}
    >
      Frage von:{' '}
    </Typography>
  )
}

const BelowChatDialogBlock4: React.FC<{ message: ChatMessage }> = ({
  message,
}) => {
  return (
    <Typography
      variant="caption"
      sx={{ fontSize: '13px', color: 'text.secondary' }}
    >
      {makePrettyDate(message.DateTime, 'DateTime')}
    </Typography>
  )
}
