import {
  Typography,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Drawer,
  Box,
  Button,
  Divider,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { pageRoutes } from "../constants/routes";
import { useContext } from "react";
import { AppContext } from "../App";
import { dentiColors } from "../constants/colors";
import LogoBanner from "./LogoBanner";
import GroupIcon from "@mui/icons-material/Group";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useLocation } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

function SideNavigation() {
  const { user, setUser } = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();


  const todoIsActive = pathname.includes("toDoList");
  const patientsIsActive = pathname.includes("patients");
  const cleatingIsActive = pathname.includes("clearingList");

  return (
    <Drawer
      variant="permanent"
      sx={{
        zIndex: 100,
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          zIndex: 100,
          backgroundColor: dentiColors.blueLow,
          width: 240,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          height: "100%", // Make sure the drawer height is 100%
        },
      }}
    >
      <Box sx={{ paddingLeft: "16px", paddingTop: "10px" }}>
        <LogoBanner />
      </Box>
      <Toolbar />
      <List
        sx={{
          display: "grid",
          gap: "5px",
          padding: "0 5px",
          placeItems: "stretch",
          gridAutoRows: "50px",
          height: "100%",
        }}
      >
        <ListItemButton
          selected={todoIsActive}
          component={Link}
          to="/toDoList"
          sx={{
            borderLeft: `${
              todoIsActive ? dentiColors.accent : "transparent"
            } 5px solid`,
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <ListItemIcon>
            <ListAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="To-Do's" />
        </ListItemButton>
        <ListItemButton
          selected={patientsIsActive}
          component={Link}
          to={pageRoutes.patients}
          sx={{
            borderLeft: `${
              patientsIsActive ? dentiColors.accent : "transparent"
            } 5px solid`,
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Patienten" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/clearingList"
          selected={cleatingIsActive}
          sx={{
            borderLeft: `${
              cleatingIsActive ? dentiColors.accent : "transparent"
            } 5px solid`,
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
            maxHeight: "60px",
          }}
        >
          <ListItemIcon>
            <DescriptionOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Abrechnung" />
        </ListItemButton>
      </List>
      <Divider />
      <Box sx={{ padding: "20px" }}>
        <Typography display="block">
          {user ? `${user.UserName}` : "Loading user data..."}
        </Typography>
        <Typography display="block">Praxis: {user?.Clinic}</Typography>
      </Box>
      <Button
        startIcon={<LogoutIcon />}
        variant="contained"
        sx={{
          margin: "5px",
        }}
        onClick={async () => {
          await signOut();
          setUser(undefined);
          navigate(pageRoutes.login);
        }}
      >
        Abmelden
      </Button>
    </Drawer>
  );
}

export default SideNavigation;
