import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { ChangeHandler, RefCallBack } from "react-hook-form";

import {
  CustomFormControl,
  CustomFormLabel,
} from "../pages/PatientRegistrationPage";

export type InterventionProps = {
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  name: string;
  min?: string | number | undefined;
  max?: string | number | undefined;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  pattern?: string | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
};

type FormSelectProps<T> = {
  label: string;
  placeholder?: string;
  data: T[];
  itemLabel: keyof T;
  itemValue: keyof T;
  inputRef: RefCallBack;
  isError: boolean;
  errorMessage?: string;
  inputProps: InterventionProps;
};

const FormSelect = <T,>({
  data,
  itemLabel,
  itemValue,
  placeholder = "Bitte auswählen",
  label,
  inputRef,
  isError,
  errorMessage,
  inputProps,
}: FormSelectProps<T>) => {
  return (
    <CustomFormControl>
      <CustomFormLabel sx={{ paddingBottom: "10px" }}>{label}</CustomFormLabel>
      {data && (
        <TextField
          select
          fullWidth
          label={placeholder}
          inputRef={inputRef}
          defaultValue=""
          error={isError}
          helperText={errorMessage}
          {...inputProps}
        >
          {data.map((item) => (
            <MenuItem
              key={item[itemValue] as string}
              value={item[itemValue] as string}
            >
              {item[itemLabel] as string}
            </MenuItem>
          ))}
        </TextField>
      )}
    </CustomFormControl>
  );
};

export default FormSelect;
